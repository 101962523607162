import React from 'react';
import { string } from 'prop-types';

const CloseNewFeatureIcon = ({
  className='nle-close-modal-icon',
  title='Close New Feature Modal',
  height='22',
  width='22',
  fill='#495057'
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path 
      d="M20.5625 3.5625L13.0625 11.0625L20.5 18.5C21.125 19.0625 21.125 20 20.5 20.5625C19.9375 21.1875 19 21.1875 18.4375 20.5625L10.9375 13.125L3.5 20.5625C2.9375 21.1875 2 21.1875 1.4375 20.5625C0.8125 20 0.8125 19.0625 1.4375 18.4375L8.875 11L1.4375 3.5625C0.8125 3 0.8125 2.0625 1.4375 1.4375C2 0.875 2.9375 0.875 3.5625 1.4375L11 8.9375L18.4375 1.5C19 0.875 19.9375 0.875 20.5625 1.5C21.125 2.0625 21.125 3 20.5625 3.5625Z" 
      fill={fill}
    />
  </svg>
);

CloseNewFeatureIcon.propTypes = {
  className: string,
  title: string,
  height: string,
  width: string,
  fill: string
};

export default CloseNewFeatureIcon;
