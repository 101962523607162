/* eslint-disable no-useless-escape */

import { isEmptyObject } from '../../../lib/utils';

export const Errors = {
  401: supportNumber => ['The request could not be authorized.', 'Please refresh the page and try again. ', `If the issue persists, please contact support at ${supportNumber}`],
  404: supportNumber => ['The requested resource could not be found.', 'Please refresh the page and try again. ', `If the issue persists, please contact support at ${supportNumber}`],
  409: supportNumber => ['The request encountered a conflict with previous entitlements. ', `If the issue persists, please contact support at ${supportNumber}`],
  422: supportNumber => ['An invalid action or entitlement has been requested. ', `If the issue persists, please contact support at ${supportNumber}`]
};

const existingBrands = [
  'affinipay',
  'cpacharge',
  'lawpay',
  'medpay',
  'clientpay'
];

let BRAND = {};

const Brand = (name) => ({name});

const getBrandRegex = brand => new RegExp(`.${brand}`);

const getBrandFromHostname = () => {
  const host = window.location.hostname;

  for (let i = 0; i < existingBrands.length; i++) {
    if (getBrandRegex(existingBrands[i]).test(host)) {
      return existingBrands[i];
    }
  }
  return 'affinipay';
};

export const setBrand = () => {
  const currentBrandName = getBrandFromHostname();
  BRAND = Brand(currentBrandName);
};

export const safeGetBrand = () => {
  if (isEmptyObject(BRAND)) setBrand();

  return BRAND;
};

export const getEnvName = () => {
  const host = window.location.hostname;
  const appRegEx = new RegExp('^app\.(kegging\.|staging\.)?lawpay.com');

  if (appRegEx.test(host)) {
    const matches = appRegEx.exec(host);
    if (['kegging.', 'staging.'].indexOf(matches[1]) >= 0) return 'staging';
    return 'production';
  }

  if (new RegExp('^(www\.)?secure.staging\.').test(host)) return 'staging';
  if (new RegExp('^(www\.)?secure.').test(host)) return 'production';

  return 'localhost';
};

export const getEnvVariables = env => {
  if (env === 'staging') return staging;
  if (env === 'production') return production;
  return local;
};

export const getIdentityURLRoot = () => {
  const brand = safeGetBrand().name;
  const host = window.location.hostname;
  
  if (new RegExp('.test').test(host)) return `identity.${brand}.test:5000`;

  return 'localhost:5000';
};

export const production = {
  adminAccessTokenUrl: 'https://identity.affinipay.com/oauth/authorize?client_id=cda288386b7279082cac806a243a24fb9cf1cc7435e644794122ce2429f885f4&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default',
  enableNLE: 'https://api.affinipay.com/v2/product-entitlement-updates',
  merchantAccessTokenUrl: `https://identity.${safeGetBrand().name}.com/oauth/authorize?client_id=f8f46829e5041901b814c745e0e5d19e58a78130e09870161e2c2fe011be370d&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default`
};

export const staging = {
  adminAccessTokenUrl: 'https://identity.staging.affinipay.com/oauth/authorize?client_id=0c6d8940773edfc0e472a61ba838b4e5cc75acbc96ad8828cd724bde696cd79f&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default',
  enableNLE: 'https://api.staging.affinipay.com/v2/product-entitlement-updates',
  merchantAccessTokenUrl: `https://identity.staging.${safeGetBrand().name}.com/oauth/authorize?client_id=ac518246464048fed835a5f2d65683681fd16cd68a7146e28a01d2243b01205e&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default`
};

export const local = {
  adminAccessTokenUrl: 'http://localhost:5000/oauth/authorize?client_id=0c6d8940773edfc0e472a61ba838b4e5cc75acbc96ad8828cd724bde696cd79f&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default',
  enableNLE: 'http://localhost:4000/v2/product-entitlement-updates',
  merchantAccessTokenUrl: `http://${getIdentityURLRoot()}/oauth/authorize?client_id=ac518246464048fed835a5f2d65683681fd16cd68a7146e28a01d2243b01205e&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default`
};

const getEnv = () => getEnvVariables(getEnvName());

export default getEnv;
