import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const TimeAndExpenseBillboard = ({height=90, width=105, title='Time and Expense Entry'}) => (
  <svg width={width} height={height} viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M112 77.2461H0V77.5248H112V77.2461Z" fill="#241F20"/>
    <path d="M24.9021 77.8308L23.643 29.5661C23.6151 28.0056 24.8461 26.6958 26.413 26.668C26.4409 26.668 26.4689 26.668 26.4969 26.668H50.419C51.678 26.668 52.7692 27.4761 53.1329 28.6744L54.308 32.4921C54.6718 33.6903 55.763 34.4984 57.022 34.4984H97.1719C98.7387 34.4984 99.9978 35.7524 99.9978 37.313C99.9978 37.3408 99.9978 37.3966 99.9978 37.4244L99.3822 53.0575C99.3543 54.1722 98.6548 55.1475 97.6475 55.5655L28.8192 80.3945C27.3643 80.9797 25.7135 80.2831 25.1259 78.834C24.986 78.4996 24.9021 78.1652 24.9021 77.8308Z" fill="white"/>
    <path d="M27.7272 80.7296C26.1044 80.7296 24.7894 79.4477 24.7615 77.8315L23.5024 29.5667C23.4744 27.9226 24.7615 26.5572 26.4122 26.5293C26.4402 26.5293 26.4682 26.5293 26.4962 26.5293H50.4182C51.7332 26.5293 52.8804 27.3653 53.2721 28.6193L54.4472 32.437C54.7829 33.5795 55.8461 34.3319 57.0213 34.3319H97.1711C98.8219 34.3319 100.137 35.6695 100.137 37.2858C100.137 37.3136 100.137 37.3694 100.137 37.3972L99.5214 53.0303C99.4654 54.1728 98.7659 55.2039 97.6748 55.6498L28.8464 80.4788C28.5106 80.646 28.1189 80.7296 27.7272 80.7296ZM25.0412 77.8315C25.0692 79.3084 26.3283 80.4788 27.8112 80.4509C28.1469 80.4509 28.4547 80.3673 28.7625 80.2559L97.5908 55.4268C98.5701 55.0367 99.2136 54.0892 99.2416 53.0582L99.8571 37.4251C99.9131 35.9482 98.738 34.6942 97.2551 34.6384C97.2271 34.6384 97.1991 34.6384 97.1432 34.6384H57.0213C55.7062 34.6384 54.5591 33.8024 54.1674 32.5485L52.9923 28.7307C52.6565 27.5882 51.5933 26.8358 50.4182 26.8358H26.4682C24.9853 26.8358 23.7822 28.0341 23.7822 29.511C23.7822 29.5389 23.7822 29.5667 23.7822 29.5946L25.0412 77.8315Z" fill="#241F20"/>
    <path d="M5.84766 9.16884L18.4942 85.4673L77.0822 76.4664L64.4357 0.167969L5.84766 9.16884Z" fill="white"/>
    <path d="M18.3822 85.6337L18.3542 85.4944L5.67969 9.05661L5.81958 9.02874L64.5195 0L64.5475 0.139332L77.222 76.5771L77.0821 76.605L18.3822 85.6337ZM6.01544 9.27954L18.606 85.2993L76.9143 76.3542L64.3237 0.334397L6.01544 9.27954Z" fill="#241F20"/>
    <path d="M8.8125 10.4493L18.4932 87.1657L77.3891 80.3941L67.6803 3.67773L8.8125 10.4493Z" fill="#241F20"/>
    <path d="M11.1914 12.9575L17.6546 90.0361L76.7742 85.7168L70.3111 8.66602L11.1914 12.9575Z" fill="white"/>
    <path d="M17.5419 90.1761V90.0368L11.0508 12.8188H11.1907L70.4502 8.52734V8.66668L76.9134 85.8847H76.7735L17.5419 90.1761ZM11.3585 13.0974L17.7937 89.8975L76.6336 85.6339L70.1984 8.83387L11.3585 13.0974Z" fill="#241F20"/>
    <path d="M15.8359 17.6111L16.7592 30.7362L26.8317 30.1232L25.9084 16.998L15.8359 17.6111Z" fill="white"/>
    <path d="M16.6466 30.8762L15.6953 17.4724L26.0475 16.8594L26.9988 30.2632L16.6466 30.8762ZM15.9751 17.7232L16.8984 30.5697L26.6911 29.9845L25.7678 17.138L15.9751 17.7232Z" fill="#241F20"/>
    <path d="M17.0117 35.6404L17.935 48.7655L28.0075 48.1525L27.0842 35.0273L17.0117 35.6404Z" fill="white"/>
    <path d="M17.795 48.9055L16.8438 35.5017L27.196 34.8887L28.1473 48.2925L17.795 48.9055ZM17.1515 35.7525L18.0748 48.599L27.8675 48.0138L26.9442 35.1673L17.1515 35.7525Z" fill="#241F20"/>
    <path d="M22.6909 43.0815L19.5293 40.3506L19.6972 40.1555L22.6629 42.6635L29.294 34.0527L29.4898 34.2478L22.6909 43.0815Z" fill="#444444"/>
    <path d="M22.2712 63.2848L20.5645 37.4805H102.235L99.6052 59.1327L22.2712 63.2848Z" fill="white"/>
    <path d="M22.1305 63.4248L20.4238 37.3418H102.402L102.374 37.509L99.7443 59.2727H99.6324L22.1305 63.4248ZM20.7036 37.6205L22.3824 63.1462L99.4645 59.0219L102.067 37.6205H20.7036Z" fill="#CED4DA"/>
    <path d="M20.9558 65.7926L18.6895 39.9883H102.878L101.032 61.6405L20.9558 65.7926Z" fill="white"/>
    <path d="M20.8171 65.9326L18.5508 39.8496H103.047V39.9889L101.173 61.7805H101.061L20.8171 65.9326ZM18.8585 40.1283L21.0689 65.654L100.893 61.5297L102.74 40.1283H18.8585Z" fill="#241F20"/>
    <path d="M25.0967 44.6133H93.8412C94.7645 44.6133 95.5199 45.3657 95.5199 46.2853C95.5199 47.2049 94.7645 47.9573 93.8412 47.9573H25.0967C24.1734 47.9573 23.418 47.2049 23.418 46.2853C23.418 45.3657 24.1454 44.6133 25.0967 44.6133Z" fill="#CED4DA"/>
    <path d="M104.893 45.8687L99.7726 92.8795C99.6047 94.5236 98.1778 95.7776 96.527 95.7776H25.7121C24.0334 95.7776 22.6344 94.5236 22.4386 92.8795L18.2697 54.6467C18.0738 52.8632 19.3609 51.247 21.1515 51.0519C21.2634 51.0519 21.4033 51.024 21.5153 51.024H42.6954C44.0663 51.024 45.2974 50.1602 45.7731 48.8783L47.3958 44.3918C47.8715 43.11 49.1026 42.2461 50.4735 42.2461H101.619C103.438 42.2461 104.893 43.723 104.893 45.5065C104.921 45.6179 104.921 45.7573 104.893 45.8687Z" fill="#7277F1"/>
    <path d="M96.4985 96.0003H25.7116C23.9209 96.0003 22.438 94.6627 22.2422 92.9071L18.0733 54.6743C17.8775 52.7794 19.2484 51.0517 21.151 50.8566C21.2629 50.8566 21.4028 50.8287 21.5147 50.8287H42.6948C43.9819 50.8287 45.129 50.0206 45.5767 48.8223L47.1995 44.3358C47.7031 42.9704 49.0181 42.0508 50.473 42.0508H101.619C103.549 42.0508 105.116 43.6113 105.116 45.5341C105.116 45.6456 105.116 45.7849 105.088 45.8964L99.9679 92.9071C99.772 94.6627 98.2892 96.0003 96.4985 96.0003ZM21.5147 51.2467C19.808 51.2467 18.437 52.64 18.465 54.3399C18.465 54.4514 18.465 54.535 18.493 54.6464L22.6619 92.8792C22.8297 94.4398 24.1448 95.6102 25.7116 95.6102H96.5265C98.0933 95.6102 99.4083 94.4398 99.5762 92.8792L104.696 45.8406C104.864 44.1686 103.633 42.636 101.954 42.4688C101.842 42.4688 101.759 42.4409 101.647 42.4409H50.501C49.214 42.4409 48.0668 43.249 47.6192 44.4473L45.9964 48.9338C45.4927 50.2993 44.1777 51.2189 42.7228 51.2189L21.5147 51.2467Z" fill="#CED4DA"/>
    <path d="M21.5431 26.0556L18.3535 23.3525L18.5494 23.1296L21.4872 25.6654L28.1182 17.0547L28.342 17.2219L21.5431 26.0556Z" fill="#241F20"/>
    <path d="M94.4858 60.3301L91.3242 95.8041H72.7461L74.9285 60.3301H94.4858Z" fill="#D1D3D4"/>
    <path d="M65.962 15.0672L31.1348 17.1484L31.1515 17.4266L65.9787 15.3454L65.962 15.0672Z" fill="#241F20"/>
    <path d="M59.0813 18.6426L31.4375 20.3652L31.455 20.6433L59.0988 18.9207L59.0813 18.6426Z" fill="#241F20"/>
  </svg>
);

TimeAndExpenseBillboard.propTypes = {
  height: oneOfType([ number, string ]),
  width: oneOfType([ number, string ]),
  title: string
};

export default TimeAndExpenseBillboard;
