import React from 'react';
import { func } from 'prop-types';
import { APButton, APModal } from 'affinipay-ui-library';
import FeaturePoints from './FeaturePoints';
import headerImage from './learn-more-header-image.jpg';
import billingAndInvoicing from './billing-and-invoicing.jpg';
import timeAndExpense from './time-and-expense.jpg';
import caseAndClientManagement from './case-and-client-management.jpg';
import './style.scss';
import CloseModalIcon from '../assets/CloseModalIcon';

const LearnMoreModal = ({toggleModal}) => (
  <APModal
    className="nle-learn-more-modal"
    isActiveInitial={true}
    onClose={toggleModal}
  >
    <div className="nle-learn-more-content">
      <div className="nle-learn-more-header">
        <div className="header-text">
          <h1>What's New in LawPay</h1>
          <p className="header-tagline">New look and new features</p>
          <div className="header-description">
          You will find all the tools you know and love, as well as new features that allow your firm to get more done with LawPay. Track time, add expense entries, and monitor your firm's finances on the new customizable dashboard.
          </div>
          <APButton
            type="button"
            dataset={{ 'data-testid': 'learn-more-close-button'}}
            onClick={toggleModal}
          >
            Return to Dashboard
          </APButton>
        </div>
        <div className="header-image">
          <img src={headerImage} alt="" />
          <APButton 
            type="button"
            className="learn-more-close-icon"
            dataset={{ 'data-testid': 'learn-more-close-icon'}}
            onClick={toggleModal}
          >
            <CloseModalIcon />
          </APButton>
        </div>
      </div>
      <div className="nle-learn-more-features">
        <div className="nle-learn-more-feature-1">
          <div className="feature-point">
            <FeaturePoints
              title="Exciting new billing and invoicing features to take your firm to the next level."
              description="Access all the billing, invoicing, time and expense tracking, and payment features you need in one powerful legal billing software solution. Built just for law firms, LawPay makes it easy for clients to pay, ensures IOLTA compliance, and provides visibility into the financial health of your business."
              highlights={['Invoice creation', 'Invoicing tips & tricks']}
            />
          </div>
        </div>
        <div className="nle-learn-more-feature-2">
          <img src={billingAndInvoicing} />
        </div>
        <div className="nle-learn-more-feature-3">
          <img src={timeAndExpense} />
        </div>
        <div className="nle-learn-more-feature-4">
          <div className="feature-point">
            <FeaturePoints
              title="Easy Time & Expense Management"
              description="Capture more billable time while you work with LawPay's built-in timers. Add expense entries to client invoices for reimbursement."
              highlights={['Adding a Time Entry', 'Adding an Expense Entry']}
            />
          </div>
        </div>
        <div className="nle-learn-more-feature-5">
          <FeaturePoints
            title="Streamlined Case & Client Management"
            description="Stay more organized with updated case and client management tools. Save payment methods, schedule payments, track contact details and more -- all in LawPay."
            highlights={['Client Management', 'Case Management']}
          />
        </div>
        <div className="nle-learn-more-feature-6">
          <img src={caseAndClientManagement}/>
        </div>
      </div>
    </div>
  </APModal>
);

LearnMoreModal.propTypes = {
  toggleModal: func.isRequired
};

export default LearnMoreModal;
