import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { APButton, APFormMessageBox, APModal } from 'affinipay-ui-library';

const AdminButtons = ({ isSaving, toggleModal, handleEnableClick }) => (
  <div className="nle-enable-now-modal-actions">
    <APButton
      className="ap-secondary-button"
      type="button"
      dataset={{'data-testid': 'admin-cancel-enable-now-button'}}
      onClick={toggleModal}
    >
      Maybe Later
    </APButton>
    <APButton 
      type="button"
      dataset={{'data-testid': 'confirm-enable-now-button'}}
      disabled={isSaving}
      onClick={handleEnableClick}
    >
      Enable Now
    </APButton>
  </div>
);

const NonAdminButtons = ({ toggleModal }) => (
  <div className="nle-enable-now-modal-actions">
    <APButton
      className="ap-primary-button"
      type="button"
      dataset={{'data-testid': 'non-admin-cancel-enable-now-button'}}
      onClick={toggleModal}
    >
      Go Back
    </APButton>
  </div>
);

const EnableNowModal = ({
  errorMessages,
  isAdminOrOwner,
  isSaving,
  handleEnableClick,
  toggleModal
}) => {
  const displayEnum = {
    admin: {
      iframeURL: 'https://www.lawpay.com/update-lawpay/',
      buttonComponent: AdminButtons,
      props: { isSaving, toggleModal, handleEnableClick},
      iframeHeight: '840'
    },
    nonAdmin: {
      iframeURL: 'https://www.lawpay.com/update-lawpay/need-approval/',
      buttonComponent: NonAdminButtons,
      props: { toggleModal },
      iframeHeight: '612'
    }
  };
  
  const enableNowValues = displayEnum[isAdminOrOwner ? 'admin' : 'nonAdmin'];
  const SpecificButtons = enableNowValues.buttonComponent;

  return (
    <APModal
      className="nle-enable-now-modal"
      isActiveInitial={true}
      onClose={toggleModal}
    >
      <div className="nle-enable-now-modal-content">
        <iframe 
          src={enableNowValues.iframeURL}
          id="enableNLEDoc"
          title="Enable New LawPay Experience"
          width="624"
          height={enableNowValues.iframeHeight}
          loading="lazy"
        >
        </iframe>
        {errorMessages.length > 0 && (
          <APFormMessageBox  
            header="Error enabling New LawPay experience"
            messages={[errorMessages]}
            type="error"
            showCloseButton={false}
          />
        )}
        <SpecificButtons {...enableNowValues.props} />
      </div>
    </APModal>
  );
};

AdminButtons.propTypes = {
  isSaving: bool,
  handleEnableClick: func.isRequired,
  toggleModal: func.isRequired
};

NonAdminButtons.propTypes = {
  toggleModal: func.isRequired
};

EnableNowModal.propTypes = {
  errorMessages: arrayOf(string),
  isAdminOrOwner: bool,
  isSaving: bool,
  handleEnableClick: func.isRequired,
  toggleModal: func.isRequired
};

export default EnableNowModal;
