import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { APButton, APModal } from 'affinipay-ui-library';
import mixpanel from 'mixpanel-browser';
import './style.scss';
import CloseModalIcon from '../assets/CloseModalIcon';

const NewFeaturesModal = ({
  descriptionTitle,
  description,
  instructions,
  name,
  FeatureView,
  mixpanelPayloadValues,
  showPreviousButton,
  toggleModal,
  isLastSlide,
  handleSlideFeatureProgression,
  handleSlideFeatureRegression,
  handleWhatsNewClick
}) => {
  const handleReturnToDashboard = () => {
    mixpanel.track('Close NLE Feature Modal', { 'Current Slide': descriptionTitle, ...mixpanelPayloadValues });
    toggleModal();
  };

  return (
    <APModal
      className="nle-new-features-modal"
      isActiveInitial={true}
      onClose={toggleModal}
    >
      <div className="nle-new-features-modal-content">
        <div className="nle-new-features-view">
          <div className="nle-new-features-header">
            <h1>Updated LawPay</h1>
            <span>New look, new features, same LawPay.</span>
          </div>
          <div className="nle-new-features-display">
            <FeatureView />
          </div>
        </div>
        <div className="nle-new-features-instructions">
          <button 
            className="nle-new-features-close-icon"
            type="button"
            onClick={handleReturnToDashboard}
          >
            <CloseModalIcon />
          </button>
          <h2 className="nle-new-features-description-title">
            {descriptionTitle}
          </h2>
          <div className="nle-new-features-description">
            {description}
          </div>
          <div className="nle-new-features-list">
            <ul>
              {instructions.map((InstructionComponent, index) => (
                <li key={`${descriptionTitle.toLowerCase().replaceAll(' ', '-')}${index}`}>
                  <InstructionComponent bulletNumber={++index} />
                </li>
              ))}
            </ul>
          </div>
          <div className={`nle-instructions-actions${showPreviousButton ? ' show-previous' : ''}`}>
            {showPreviousButton && (
              <APButton
                className="ap-secondary-button"
                name={name}
                type="button"
                onClick={handleSlideFeatureRegression}
              >
                Previous
              </APButton>
            )}
            <APButton
              className="ap-secondary-button"
              type="button"
              onClick={handleReturnToDashboard}
            >
              Return to Dashboard
            </APButton>
            <APButton
              name={name}
              type="button"
              onClick={isLastSlide ? handleWhatsNewClick : handleSlideFeatureProgression}
            >
              {isLastSlide ? 'See What\'s New' : 'Next'}
            </APButton>
          </div>
        </div>
      </div>
    </APModal>
  );
};

NewFeaturesModal.propTypes = {
  descriptionTitle: string,
  description: string,
  instructions: arrayOf(func),
  name: string,
  FeatureView: func,
  showPreviousButton: bool,
  mixpanelPayloadValues: shape({
    browser: string,
    browserVersion: string,
    device: string,
    distinctId: string,
    integrations: arrayOf(number),
    os: string,
    product: string,
    sfMasterUserId: string,
    sfMasterFirmId: string,
    token: string,
    userUuid: string,
    userRoleOrType: string
  }),
  toggleModal: func.isRequired,
  isLastSlide: bool,
  handleSlideFeatureProgression: func.isRequired,
  handleSlideFeatureRegression: func.isRequired,
  handleWhatsNewClick: func.isRequired
};
export default NewFeaturesModal;
