import React from 'react'; 
import { string } from 'prop-types';

const PaperAirplane = ({
  className='ap-icon',
  fill='#005fb8',
  height='20',
  width='20',
  title='Paper Airplane Icon'
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
    viewBox="0 0 20 20" 
    fill="none"
  >
    <title>{title}</title>
    <path 
      d="M0.623146 10.1641C-0.25966 10.668 -0.17763 12.0118 0.76377 12.4024L6.2442 14.6875V18.7227C6.2442 19.4297 6.81451 20 7.52153 20C7.90043 20 8.25981 19.832 8.50199 19.5391L10.9239 16.6367L15.7637 18.6524C16.5019 18.9609 17.3574 18.4766 17.4785 17.6875L19.9785 1.43764C20.0527 0.964986 19.8457 0.488427 19.4511 0.218898C19.0566 -0.0506315 18.541 -0.0740688 18.123 0.164211L0.623146 10.1641ZM2.65829 11.1602L15.998 3.53919L7.41997 13.1251L7.46684 13.1641L2.65829 11.1602ZM15.748 16.6172L9.24027 13.9024L17.6035 4.5548L15.748 16.6172Z" 
      fill={fill}
    />
  </svg>
);

PaperAirplane.propTypes = {
  className: string,
  fill: string,
  height: string,
  width: string,
  title: string
};

export default PaperAirplane;
