export const legalEntities = {
  'data': [
    {
      'id': '8',
      'type': 'legal_entity',
      'attributes': {
        'id': 8,
        'doing_business_as': 'Adyen Inc.',
        'merchant_id': 5,
        'legal_business_name': 'Adyen business name',
        'address': {
          'city': 'Austin',
          'type': 'us',
          'state': 'tx',
          'address1': '123 Street',
          'address2': 'Unit 5',
          'zip_code': '78746'
        },
        'years_in_business': 5,
        'email': 'joeadyen@example.com',
        'url': null,
        'merchant_category_code': '8699',
        'created_at': '2022-01-13T09:35:18.000-06:00',
        'updated_at': '2022-01-13T09:35:19.000-06:00',
        'phone_number': '1(123) 123-1241',
        'business_structure': 'Sole Proprietor',
        'beneficial_owners': [
          {
            'id': 8,
            'legal_entity_id': 8,
            'aggregate_id': '2Y6H55VdcIC1L49ToFmud6',
            'given_name': 'Joe',
            'surname': 'Adyen',
            'date_of_birth': '1990-12-12',
            'address': {
              'city': 'austin',
              'type': 'us',
              'state': 'tx',
              'address1': '123 street biz',
              'address2': 'unit 5',
              'zip_code': '78746'
            },
            'phone_number': '15125555555',
            'email': 'joeadyen@example.com',
            'citizenship': 'us',
            'title': 'president',
            'years_at_residence': 5,
            'significant_ownership': true,
            'significant_management': false,
            'created_at': '2022-01-13T09:35:19.000-06:00',
            'updated_at': '2022-01-13T09:35:19.000-06:00'
          }
        ],
        tax_id: '123123123'
      }
    }
  ]
};

export const beneficialOwnerResponseFromAggregate = {
  'data': {
    'attributes': {
      'phone_number': '15125555555',
      'email': 'joeadyen@example.com',
      'identification': {
        'type': 'us_social_security',
        'number': '123-12-3123'
      },
      'citizenship': 'us',
      'significant_ownership': true,
      'surname': 'Adyen',
      'date_of_birth': '1990-12-12',
      'significant_management': false,
      'address': {
        'type': 'us',
        'address1': '123 street biz',
        'address2': 'unit 5',
        'city': 'austin',
        'state': 'tx',
        'zip_code': '78746-1234'
      },
      'title': 'president',
      'given_name': 'Joe',
      'years_at_residence': 5
    },
    'id': '2Y6H55VdcIC1L49ToFmud6'
  }
};

export const beneficialOwner = beneficialOwnerResponseFromAggregate['data']['attributes'];

export const beneficialOwnernerResponseWithPlaceholder = {
  'data': {
    'attributes': {
      'phone_number': '5555555555',
      'email': 'fakemail@fake.com',
      'identification': {
        'type': 'us_social_security',
        'number': '000000000'
      },
      'citizenship': 'us',
      'significant_ownership': true,
      'surname': 'Adyen',
      'date_of_birth': new Date(0o001, 0, 1),
      'significant_management': false,
      'address': {
        'type': 'us',
        'address1': '1234 Fake st',
        'address2': '',
        'city': 'austin',
        'state': 'tx',
        'zip_code': '00000'
      },
      'title': 'president',
      'given_name': 'Joe',
      'years_at_residence': 5
    },
    'id': '2Y6H55VdcIC1L49ToFmud6'
  }
};

export const legalEntityResponseFromAggregate = {
  'data': {
    'type': 'legal_entity',
    'id': '0zIBvaa1GZXzIoUHoFfGOy',
    'attributes': {
      'tax_id': '123123123',
      'phone_number': '11231231241',
      'email': 'joeadyen@example.com',
      'url': null,
      'business_structure': 'sole_proprietor',
      'legal_business_name': 'Adyen Inc',
      'years_in_business': 5,
      'merchant_category_code': '8699',
      'address': {
        'type': 'us',
        'address1': '123 Street',
        'address2': 'Unit 5',
        'city': 'Austin',
        'state': 'tx',
        'zip_code': '78746-1234'
      },
      'doing_business_as': 'Adyen Inc'
    },
    'relationships': {
      'beneficial_owners': {
        'data': [
          {
            'type': 'beneficial_owner',
            'id': '2Y6H55VdcIC1L49ToFmud6'
          }
        ]
      }
    },
    'meta': {
      'archived': false,
      'version': 13
    }
  }
};

export const legalEntity = legalEntityResponseFromAggregate['data']['attributes'];