import React from 'react';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import './style.scss';
import AnimatedCircleCheck from './AnimatedCircleCheck';

const keyPressCodes = {
  enterKey: 'Enter',
  spaceKey: 'Space'
};

const APAccordion = ({
  name = '',
  title = '',
  ariaLabelText= typeof title === 'string' ? title : '',
  subtitle,
  customClassName = '',
  icons,
  isOpen,
  onToggle,
  children,
  disabled=false,
  dropDownStyle=false
}) => {

  const refinedName = name ? name.trim().toLowerCase() : 'ap-accordion';
  const accordionId = `${refinedName}-id`;
  const accordionContentId = `${refinedName}-content-id`;

  const iconsArray = icons?.map((icon, i) => (
    <img src={icon.src} className={'ap-accordion-images'} alt={icon.alt} key={i} />
  ));

  const handleClick = event => {
    event.stopPropagation();
    if (!!onToggle && !disabled) {
      onToggle({ 
        name, 
        isOpen: !!isOpen,
        title,
        customClassName: customClassName
      });
    }
  };

  const onKeyDown = (e) => {
    const isEnterOrSpaceKey = e.code === keyPressCodes.enterKey || e.code === keyPressCodes.spaceKey;
    if (isEnterOrSpaceKey && !!onToggle && !disabled) {
      
      e.preventDefault();

      onToggle({
        name: name, 
        isOpen: !!isOpen,
        title,
        customClassName: customClassName
      });
    }
  };

  return (
    <div className={`ap-accordion ${name} ${customClassName} ${disabled ? 'disabled' : ''}`} data-testid={name ? `${name}-test-id` : 'ap-accordion-test-id'}>
      <div className="ap-accordion-header"
        id={accordionId}
        onClick={handleClick}
        onKeyDown={onKeyDown}
        aria-expanded={isOpen}
        aria-controls={accordionContentId}
        aria-label={ariaLabelText}
        role='button'
        tabIndex={0}
      >
        <div className="ap-accordion-container">
          {!dropDownStyle && (
            <AnimatedCircleCheck className={`ap-accordion-check ${isOpen ? ' active' : ''}`}/>
          )}
          <div className="ap-accordion-headline">
            <div className="ap-accordion-title">{title}</div>
            {subtitle &&
              <div className="ap-accordion-subtitle">{subtitle}</div>
            }
          </div>
        </div>
        {icons && (
          <div className="ap-accordion-images-container">
            {iconsArray}
          </div>
        )}
        {dropDownStyle && (
          <div className={`ap-accordion-chevron ${isOpen ? ' active' : ''}`}>
            <span></span>
            <span></span>
          </div>
        )}
      </div>
      <div className={`expanded-content${isOpen ? ' active' : ''}`}
        id={accordionContentId}
        data-testid="accordion-content"
        aria-hidden={!isOpen}
        role="region"
        aria-labelledby={accordionId}
      >
        {children}
      </div>
    </div>
  );
};

APAccordion.propTypes = {
  name: string,
  title: oneOfType([ node, string ]),
  ariaLabelText: string,
  subtitle: oneOfType([ string, node]),
  customClassName: string,
  icons: arrayOf(node),
  isOpen: bool,
  onToggle: func,
  children: arrayOf(node),
  disabled: bool,
  dropDownStyle: bool
};
export default APAccordion;
