import React from 'react';
import './style.scss';
import FeatureBar from '../FeatureBar';
import image1 from './invoicing-feature-image-1.jpg';
import image2 from './invoicing-feature-image-2.jpg';

const InvoicingFeatureView = () => (
  <div className="invoicing-feature-view-wrapper">
    <div className="invoicing-feature-bar">
      <FeatureBar highlightedFeature='invoicing' />
    </div>
    <div className="invoicing-feature-image-1">
      <img 
        src={image1} 
        alt="Invoicing Page UI View"
      />
    </div>
    <div className="invoicing-feature-image-2">
      <img 
        src={image2} 
        alt="New Invoice UI View"
      />
    </div>
    <div className="invoicing-feature-marker-1">1</div>
    <div className="invoicing-feature-marker-2">2</div>
    <div className="invoicing-feature-marker-3">3</div>
  </div>
);

export default InvoicingFeatureView;
