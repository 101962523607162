import React from 'react';
import { arrayOf, bool, func, node, string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import './style.scss';

const createCardTitle = (titleText) => titleText.map((text, index) => (
  <span key={`${text}-${index}`}>{text}</span>
));

const APCard = ({
  billboard,
  buttonIcon,
  buttonName,
  buttonTestId,
  buttonText,
  description,
  titleValues = [],
  hasBorder,
  handleButtonClick
}) => (
  <div 
    className={`apcard-wrapper${hasBorder ? ' has-border' : ''}`}
    data-testid="apcard-wrapper"
  >
    <div className="apcard-billboard">
      {billboard && billboard}
    </div>
    <div className="apcard-information">
      <div className="apcard-title">
        <div className="apcard-title-container" data-testid={`apcard-title-container-${buttonTestId}`}>
          {titleValues && createCardTitle(titleValues)}
        </div>
      </div>
      <div className="apcard-description">
        {description}
      </div>
    </div>
    <div className="apcard-action">
      <APButton 
        className="ap-table-button"
        type="button"
        onClick={handleButtonClick}
        dataset={{ 'data-testid': buttonTestId ? `${buttonTestId}-card-button` : ''}}
        name={buttonName || ''}
        value={titleValues?.join(' ')}
        ariaLabel={titleValues?.join(' ')}
      >
        {buttonIcon && buttonIcon}
        <span className="apcard-button-text">
          {buttonText}
        </span>
      </APButton>
    </div>
  </div>
);

APCard.propTypes = {
  billboard: node,
  buttonIcon: node,
  buttonName: string,
  buttonTestId: string,
  buttonText: string,
  description: string,
  titleValues: arrayOf(string),
  hasBorder: bool,
  handleButtonClick: func.isRequired
};

export default APCard;
