import React from 'react'; 
import { string } from 'prop-types';

const Calendar = ({
  className='ap-icon',
  fill='#005fb8',
  height='20',
  width='18',
  title='Calendar Icon'
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
    viewBox="0 0 18 20" 
    fill="none"
  >
    <title>{title}</title>
    <path 
      d="M6.1875 0.9375C6.1875 0.417969 5.76953 0 5.25 0C4.73047 0 4.3125 0.417969 4.3125 0.9375V2.5H2.75C1.37109 2.5 0.25 3.62109 0.25 5V5.625V7.5V17.5C0.25 18.8789 1.37109 20 2.75 20H15.25C16.6289 20 17.75 18.8789 17.75 17.5V7.5V5.625V5C17.75 3.62109 16.6289 2.5 15.25 2.5H13.6875V0.9375C13.6875 0.417969 13.2695 0 12.75 0C12.2305 0 11.8125 0.417969 11.8125 0.9375V2.5H6.1875V0.9375ZM2.125 7.5H5.25V9.6875H2.125V7.5ZM2.125 11.5625H5.25V14.0625H2.125V11.5625ZM7.125 11.5625H10.875V14.0625H7.125V11.5625ZM12.75 11.5625H15.875V14.0625H12.75V11.5625ZM15.875 9.6875H12.75V7.5H15.875V9.6875ZM15.875 15.9375V17.5C15.875 17.8438 15.5938 18.125 15.25 18.125H12.75V15.9375H15.875ZM10.875 15.9375V18.125H7.125V15.9375H10.875ZM5.25 15.9375V18.125H2.75C2.40625 18.125 2.125 17.8438 2.125 17.5V15.9375H5.25ZM10.875 9.6875H7.125V7.5H10.875V9.6875Z" 
      fill={fill}
    />
  </svg>
);

Calendar.propTypes = {
  className: string,
  fill: string,
  height: string,
  width: string,
  title: string
};
export default Calendar;
