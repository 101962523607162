import AcceptingPaymentsBillboard from './assets/billboardSVGs/AcceptingPaymentsBillboard';
import AdminSettingsBillboard from './assets/billboardSVGs/AdminSettingsBillboard';
import SavedPaymentMethodsBillboard from './assets/billboardSVGs/SavedPaymentMethodsBillboard';
import PaymentPageBillboard from './assets/billboardSVGs/PaymentPagesBillboard';
import QuickBillsBillboard from './assets/billboardSVGs/QuickBillsBillboard';
import InvoicingBillboard from './assets/billboardSVGs/InvoicingBillboard';
import LawPayNewBillboard from './assets/billboardSVGs/LawPayNewBillboard';
import ViewTimerBillboard from './assets/billboardSVGs/ViewTimerBillboard';
import TimeAndExpenseBillboard from './assets/billboardSVGs/TimeAndExpenseBillboard';

const titleMap = new Map([
  ['paymentPages', ['Payment', 'Pages']],
  ['acceptingPayments', ['Payments', 'Overview']],
  ['savedPayments',  ['Saved Payment', 'Methods']],
  ['adminSettings', ['Admin', 'Settings']],
  ['quickBills', ['Quick', 'Bills']],
  ['invoicing', ['Easy', 'Invoicing']],
  ['whatsNew', ['What\'s New', 'In LawPay']],
  ['viewTimer', ['Start', 'Timer']],
  ['timeExpense', ['Time and', 'Expense Entry']]
]);

export const allCardsArray = [
  {
    billboard: PaymentPageBillboard,
    buttonName: 'paymentPages',
    buttonTestId: 'payment-page',
    buttonText: 'View',
    description: 'See the new look and location of your payment pages',
    titleValues: titleMap.get('paymentPages')
  },
  {
    billboard: AcceptingPaymentsBillboard,
    buttonName: 'acceptingPayments',
    buttonTestId: 'accepting-payments',
    buttonText: 'View',
    description: 'Look up your transactions and scheduled payments',
    titleValues: titleMap.get('acceptingPayments')
  },
  {
    billboard: SavedPaymentMethodsBillboard,
    buttonName: 'savedPayments',
    buttonTestId: 'saved-payment-methods',
    buttonText: 'View',
    description: 'Manage clients\' saved payment methods (Card Vault)',
    titleValues: titleMap.get('savedPayments')
  },
  {
    billboard: QuickBillsBillboard,
    buttonName: 'quickBills',
    buttonTestId: 'quick-bill',
    buttonText: 'View',
    description: 'See the new look and where to find your Quick Bills',
    titleValues: titleMap.get('quickBills')
  },
  {
    billboard: InvoicingBillboard,
    buttonName: 'invoicing',
    buttonTestId: 'invoicing',
    buttonText: 'View',
    description: 'Create and manage itemized invoices with ease',
    titleValues: titleMap.get('invoicing')
  },
  {
    billboard: AdminSettingsBillboard,
    buttonName: 'adminSettings',
    buttonTestId: 'admin-settings',
    buttonText: 'View',
    description: 'Easily manage users, settings, and notifications',
    titleValues: titleMap.get('adminSettings')
  },
  {
    billboard: ViewTimerBillboard,
    buttonName: 'viewTimer',
    buttonTestId: 'view-timer',
    buttonText: 'View',
    description: 'Use up to ten timers to track time while you work',
    titleValues: titleMap.get('viewTimer')
  },
  {
    billboard: TimeAndExpenseBillboard,
    buttonName: 'timeExpense',
    buttonTestId: 'time-and-expense',
    buttonText: 'View',
    description: 'Easily add time entries and expenses to itemized invoices',
    titleValues: titleMap.get('timeExpense')
  },
  {
    billboard: LawPayNewBillboard,
    buttonName: 'whatsNew',
    buttonTestId: 'lawpay-new',
    buttonText: 'View',
    description: 'Exciting Billing features that will take your firm to the next level',
    titleValues: titleMap.get('whatsNew')
  }
];
