// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeSchedule Component shallow bi monthly new render 1`] = `
<div
  className="recurring-charge-schedule"
>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeAmount
        amount="6.00"
        handleChange={[Function]}
        isComplete={false}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <RecurringChargeFrequency
        handleChange={[Function]}
        initialRecurChargeFrequency={
          {
            "days": [
              "30",
            ],
            "intervalDelay": "1",
            "intervalUnit": "MONTH",
            "start": "2019-9-30  09:30:00",
          }
        }
        items={
          [
            {
              "text": "One Time",
              "value": "DAY",
            },
            {
              "text": "Weekly / Every X Weeks",
              "value": "WEEKLY",
            },
            {
              "text": "Twice a Month",
              "value": "BI_MONTHLY",
            },
            {
              "text": "Monthly / Every X Months",
              "value": "MONTHLY",
            },
            {
              "text": "Yearly / Every X Years",
              "value": "YEAR",
            },
          ]
        }
        selectId="recurFrequency"
      />
    </div>
    <div
      className="col-sm-6"
    >
      <RecurringChargeInterval
        biMonthly={false}
        handleChange={[Function]}
        intervalDelay="1"
        oneTime={false}
        recurFrequency="MONTHLY"
        secondChargeDate={NaN}
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
    <div
      className="col-sm-12"
    >
      <RecurringChargeDatepicker
        biMonthly={false}
        handleChange={[Function]}
        isLastDay={false}
        oneTime={false}
        recurFrequency="MONTHLY"
        recurInitial="09/30/2019  09:30:00"
        recurStart="30-Sep-2019"
        scheduleStartDate="2019-9-30"
        secondChargeDate={NaN}
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeEndDate
        handleChange={[Function]}
        maxOccurrences={null}
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <ForwardRef
    id="validation-paid-occurrences"
    name="validation_paid_occurrences"
    type="hidden"
  />
</div>
`;

exports[`RecurringChargeSchedule Component shallow daily edit render 1`] = `
<div
  className="recurring-charge-schedule"
>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeAmount
        amount="6.00"
        handleChange={[Function]}
        isComplete={false}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <RecurringChargeFrequency
        handleChange={[Function]}
        initialRecurChargeFrequency={
          {
            "intervalDelay": "1",
            "intervalUnit": "DAY",
            "start": "2019-9-30  09:30:00",
          }
        }
        items={
          [
            {
              "text": "One Time",
              "value": "DAY",
            },
            {
              "text": "Weekly / Every X Weeks",
              "value": "WEEKLY",
            },
            {
              "text": "Twice a Month",
              "value": "BI_MONTHLY",
            },
            {
              "text": "Monthly / Every X Months",
              "value": "MONTHLY",
            },
            {
              "text": "Yearly / Every X Years",
              "value": "YEAR",
            },
          ]
        }
        selectId="recurFrequency"
      />
    </div>
    <div
      className="col-sm-6"
    >
      <RecurringChargeInterval
        handleChange={[Function]}
        intervalDelay="1"
        oneTime={true}
        recurFrequency="DAY"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
    <div
      className="col-sm-12"
    >
      <RecurringChargeDatepicker
        handleChange={[Function]}
        isLastDay={false}
        oneTime={true}
        recurFrequency="DAY"
        recurInitial="09/30/2019  09:30:00"
        recurStart="30-Sep-2019"
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <Component />
    </div>
  </div>
  <ForwardRef
    id="validation-paid-occurrences"
    name="validation_paid_occurrences"
    type="hidden"
  />
</div>
`;

exports[`RecurringChargeSchedule Component shallow monthly edit render 1`] = `
<div
  className="recurring-charge-schedule"
>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeAmount
        amount="6.00"
        handleChange={[Function]}
        isComplete={false}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <RecurringChargeFrequency
        handleChange={[Function]}
        initialRecurChargeFrequency={
          {
            "intervalDelay": "1",
            "intervalUnit": "MONTH",
            "start": "2019-9-30  09:30:00",
          }
        }
        items={
          [
            {
              "text": "One Time",
              "value": "DAY",
            },
            {
              "text": "Weekly / Every X Weeks",
              "value": "WEEKLY",
            },
            {
              "text": "Twice a Month",
              "value": "BI_MONTHLY",
            },
            {
              "text": "Monthly / Every X Months",
              "value": "MONTHLY",
            },
            {
              "text": "Yearly / Every X Years",
              "value": "YEAR",
            },
          ]
        }
        selectId="recurFrequency"
      />
    </div>
    <div
      className="col-sm-6"
    >
      <RecurringChargeInterval
        handleChange={[Function]}
        intervalDelay="1"
        oneTime={false}
        recurFrequency="MONTHLY"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
    <div
      className="col-sm-12"
    >
      <RecurringChargeDatepicker
        handleChange={[Function]}
        isLastDay={false}
        oneTime={false}
        recurFrequency="MONTHLY"
        recurInitial="09/30/2019  09:30:00"
        recurStart="30-Sep-2019"
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeEndDate
        handleChange={[Function]}
        maxOccurrences={null}
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <ForwardRef
    id="validation-paid-occurrences"
    name="validation_paid_occurrences"
    type="hidden"
  />
</div>
`;

exports[`RecurringChargeSchedule Component shallow new render with recurInitial 1`] = `
<div
  className="recurring-charge-schedule"
>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeAmount
        amount={null}
        handleChange={[Function]}
        isComplete={false}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <RecurringChargeFrequency
        handleChange={[Function]}
        initialRecurChargeFrequency="MONTHLY"
        items={
          [
            {
              "text": "One Time",
              "value": "DAY",
            },
            {
              "text": "Weekly / Every X Weeks",
              "value": "WEEKLY",
            },
            {
              "text": "Twice a Month",
              "value": "BI_MONTHLY",
            },
            {
              "text": "Monthly / Every X Months",
              "value": "MONTHLY",
            },
            {
              "text": "Yearly / Every X Years",
              "value": "YEAR",
            },
          ]
        }
        selectId="recurFrequency"
      />
    </div>
    <div
      className="col-sm-6"
    >
      <RecurringChargeInterval
        handleChange={[Function]}
        intervalDelay="1"
        oneTime={false}
        recurFrequency="MONTHLY"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
    <div
      className="col-sm-12"
    >
      <RecurringChargeDatepicker
        handleChange={[Function]}
        isLastDay={false}
        oneTime={false}
        recurFrequency="MONTHLY"
        recurInitial="09/30/2019  09:30:00"
        recurStart="30-Sep-2019"
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeEndDate
        amountReaches=""
        handleChange={[Function]}
        maxOccurrences={null}
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <ForwardRef
    id="validation-paid-occurrences"
    name="validation_paid_occurrences"
    type="hidden"
  />
</div>
`;

exports[`RecurringChargeSchedule Component shallow snapshot render 1`] = `
<div
  className="recurring-charge-schedule"
>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeAmount
        amount={null}
        handleChange={[Function]}
        isComplete={false}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <RecurringChargeFrequency
        handleChange={[Function]}
        initialRecurChargeFrequency="MONTHLY"
        items={
          [
            {
              "text": "One Time",
              "value": "DAY",
            },
            {
              "text": "Weekly / Every X Weeks",
              "value": "WEEKLY",
            },
            {
              "text": "Twice a Month",
              "value": "BI_MONTHLY",
            },
            {
              "text": "Monthly / Every X Months",
              "value": "MONTHLY",
            },
            {
              "text": "Yearly / Every X Years",
              "value": "YEAR",
            },
          ]
        }
        selectId="recurFrequency"
      />
    </div>
    <div
      className="col-sm-6"
    >
      <RecurringChargeInterval
        handleChange={[Function]}
        intervalDelay="1"
        oneTime={false}
        recurFrequency="MONTHLY"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
    <div
      className="col-sm-12"
    >
      <RecurringChargeDatepicker
        handleChange={[Function]}
        isLastDay={false}
        oneTime={false}
        recurFrequency="MONTHLY"
        recurInitial="09/30/2019  09:30:00"
        recurStart="30-Sep-2019"
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeEndDate
        amountReaches=""
        handleChange={[Function]}
        maxOccurrences={null}
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <ForwardRef
    id="validation-paid-occurrences"
    name="validation_paid_occurrences"
    type="hidden"
  />
</div>
`;

exports[`RecurringChargeSchedule Component shallow weekly edit render 1`] = `
<div
  className="recurring-charge-schedule"
>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeAmount
        amount="6.00"
        handleChange={[Function]}
        isComplete={false}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <RecurringChargeFrequency
        handleChange={[Function]}
        initialRecurChargeFrequency={
          {
            "intervalDelay": "1",
            "intervalUnit": "WEEK",
            "start": "2019-9-30  09:30:00",
          }
        }
        items={
          [
            {
              "text": "One Time",
              "value": "DAY",
            },
            {
              "text": "Weekly / Every X Weeks",
              "value": "WEEKLY",
            },
            {
              "text": "Twice a Month",
              "value": "BI_MONTHLY",
            },
            {
              "text": "Monthly / Every X Months",
              "value": "MONTHLY",
            },
            {
              "text": "Yearly / Every X Years",
              "value": "YEAR",
            },
          ]
        }
        selectId="recurFrequency"
      />
    </div>
    <div
      className="col-sm-6"
    >
      <RecurringChargeInterval
        handleChange={[Function]}
        intervalDelay="1"
        oneTime={false}
        recurFrequency="WEEKLY"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
    <div
      className="col-sm-12"
    >
      <RecurringChargeDatepicker
        handleChange={[Function]}
        isLastDay={false}
        oneTime={false}
        recurFrequency="WEEKLY"
        recurInitial="09/30/2019  09:30:00"
        recurStart="30-Sep-2019"
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <div
    className="row"
  >
    <div
      className="col-md-12"
    >
      <RecurringChargeEndDate
        handleChange={[Function]}
        maxOccurrences={null}
        scheduleStartDate="2019-9-30"
        selectedDay={2019-09-30T16:30:00.000Z}
      />
    </div>
  </div>
  <ForwardRef
    id="validation-paid-occurrences"
    name="validation_paid_occurrences"
    type="hidden"
  />
</div>
`;
