import React from 'react';
import { string } from 'prop-types';
import { 
  Calendar,
  ChainLink,
  CollectPayment,
  Contact,
  CreateInvoice, 
  PaperAirplane 
} from './FeatureIcons';
import './style.scss';

const featureList = {
  acceptingPayments: {
    text: 'Collect Payment',
    Icon: CollectPayment
  },
  quickBills: {
    text: 'Quick Bill',
    Icon: PaperAirplane
  },
  paymentPages: {
    text: 'Payment Pages',
    Icon: ChainLink
  },
  invoicing: {
    text: 'Create Invoice',
    Icon: CreateInvoice
  },
  schedule: {
    text: 'Schedule Pay...',
    Icon: Calendar
  },
  contact: {
    text: 'Add Contact',
    Icon: Contact
  }

};


const FeatureBar = ({highlightedFeature}) => (
  <div className="nle-feature-bar">
    {Object.keys(featureList).map(feature => {
      const { text, Icon } = featureList[feature];
      return (
        <div 
          key={feature}
          className={`nle-feature-tab${highlightedFeature === feature ? ' active' : ''}`}
        >
          <div className="nle-feature-tab-icon">
            <Icon />
          </div>
          <span>{text}</span>
        </div>
      );
    })}
  </div>
);

FeatureBar.propTypes = {
  highlightedFeature: string
};

export default FeatureBar;
