import React from 'react';
import './style.scss';
import image1 from './payments-overview-0.jpg';
import image2 from './payments-overview-1.jpg';
import image3 from './payments-overview-2.jpg';

const AcceptingPaymentsFeatureView = () => (
  <div className="accepting-payments-feature-view-wrapper">
    <div className="payment-overview-feature-image-1">
      <img src={image1} alt="Payments Overview View of Transactions" />
      <div className="payments-overview-feature-access" data-testid="payment-overview-feature-access">1</div>
    </div>
    <div className="payment-overview-feature-image-2">
      <img src={image2} alt="Payments Overview View of Transaction Details" />
      <div className="transaction-details-access" data-testid="transaction-details-access">2</div>
      <div className="transaction-actions-access" data-testid="transaction-actions-access">3</div>
    </div>
    <div className="payment-overview-feature-image-3">
      <img src={image3} alt="Payments Overview View of Scheduled Payments" />
      <div className="scheduled-payments-access" data-testid="scheduled-payments-access">4</div>
    </div>
  </div>
);

export default AcceptingPaymentsFeatureView;
