import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import { DineroCentsFormat } from '../../../../lib/monetaryUtils.js';
import TrustIcon from '../../../../components/icons/TrustIcon';

export const formatTimestampToDate = (timestamp, includeTime) => {
  const date = timestamp instanceof Date ? timestamp : new Date(timestamp);

  if (isNaN(date)) return '';

  const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const timeOptions = { hour12: true, hour: 'numeric', minute: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
  return includeTime ? `${formattedDate} ${formattedTime}` : formattedDate;
};

/*** Cell Components ***/

export const DateCell =  ({ row: { index }, value }) => {
  return <span data-testid={`associated-invoices-date-cell-${index}`}>{formatTimestampToDate(value, true)}</span>;
};

export const AmountCell = ({ row: { index }, value: { subTotal, currency, showAmount } = { subTotal: 0, showAmount: true } }) => {
  const amountDisplayed = showAmount ? DineroCentsFormat(subTotal, currency) : '--';

  return <span className={`align-right ${!showAmount ? 'dash-align' : ''}`} data-testid={`associated-invoices-amount-cell-${index}`}>{amountDisplayed}</span>;
};

export const AccountCell = ({
  row: { index },
  value: { bankAccount, bankName, isTrustAccount } = { bankAccount: undefined, bankName: undefined, isTrustAccount: false }
}) => {
  const formattedBankAccount = bankAccount ? bankAccount.substring(6) : '';
  const fullBankValues = `${bankName ? bankName : ''}${bankName ? ' ' : ''}${formattedBankAccount}`;

  return (
    <div className='account-cell' data-testid={`associated-invoices-account-cell-${index}`}>
      {isTrustAccount ? (
        <>
          <TrustIcon size={'14'} />
          <span className="invoice-bank-name">{`Trust - ${fullBankValues}`}</span>
        </>
      ) : <span>{`${fullBankValues}`}</span>
      }
    </div>
  );
};

export const NameReferenceCell = ({ row: { index }, value: { displayName, reference } = {displayName: '', reference: ''}}) => {
  return (
    <div className='name-reference-cell' data-testid={`associated-invoices-name-cell-${index}`}>
      {displayName && <span>{displayName}</span>}
      {reference && <span>{reference}</span>}
    </div>
  );
};

export const SurchargeCell = ({ row: { index }, value: { currency, surchargeAmount } = { surcharge: 0 } }) => {
  const surchargePresent = (surchargeAmount && surchargeAmount > 0);
  const surchargeDisplayed = surchargePresent ? DineroCentsFormat(surchargeAmount, currency) : '--';

  return <div className={`align-right ${!surchargePresent ? 'dash-align' : ''}`} data-testid={`associated-invoices-surcharge-cell-${index}`}>{ surchargeDisplayed }</div>;
};

export const TotalAmountCell = ({ row: { index }, value: { currency, amount, failedTransaction } = {amount: 0, failedTransaction: false} }) => {
  const amountDisplayed = failedTransaction ? '--' : DineroCentsFormat(amount, currency);

  return (
    <div className={`align-right ${failedTransaction ? 'dash-align' : ''}`} data-testid={`associated-invoices-totalamount-cell-${index}`}>
      {amountDisplayed}
    </div>
  );
};

/*** Cell Component Prop Types ***/
const rowWithIndexProp = shape({ row: shape({ index: number }) });

DateCell.propTypes = { row: rowWithIndexProp, value: string };
AmountCell.propTypes = { row: rowWithIndexProp, value: shape({ subTotal: number, currency: string }) };
AccountCell.propTypes = { row: rowWithIndexProp, value: shape({ bankAccount: string, bankName: string, isTrustAccount: bool }) };
NameReferenceCell.propTypes = { row: rowWithIndexProp, value: shape({ displayName: string, reference: string }) };
SurchargeCell.propTypes = { row: rowWithIndexProp, value: shape({ currency: string, surchargeAmount: number })  };
TotalAmountCell.propTypes = { row: rowWithIndexProp, value: shape({ currency: string, amount: number }) };
