// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Disclaimer Component shallow snapshot render 1`] = `
<ThemeProvider
  theme={
    {
      "Button_backgroundColor_active": "transparent",
      "Button_backgroundColor_focus": "transparent",
      "Button_backgroundColor_hover": "#fff",
      "Button_backgroundColor_minimal_active": "#f9f4f1",
      "Button_backgroundColor_minimal_focus": "#f9f4f1",
      "Button_backgroundColor_minimal_hover": "#f9f4f1",
      "Button_backgroundColor_primary": "#EB6B19",
      "Button_backgroundColor_primary_active": "#9e3e00",
      "Button_backgroundColor_primary_focus": "#c95307",
      "Button_backgroundColor_primary_hover": "#c95307",
      "Button_borderColor": "#EB6B19",
      "Button_borderColor_active": "#9e3e00",
      "Button_borderColor_focus": "#c95307",
      "Button_borderColor_hover": "#c95307",
      "Button_borderRadius": 0,
      "Button_boxShadow_focus": "0 0 0 2px rampUp(0.1)(color)",
      "Button_color": "#EB6B19",
      "Button_color_minimal": "#EB6B19",
      "Button_color_primary": "#fff",
      "backgroundColor": "#ffffff",
      "backgroundColor_active": "#ebeff5",
      "backgroundColor_dangerPrimary": "#de1b1b",
      "backgroundColor_dangerPrimary_active": "#b80d0d",
      "backgroundColor_dangerPrimary_focus": "#de1b1b",
      "backgroundColor_dangerPrimary_hover": "#f55353",
      "backgroundColor_danger_active": "#fad4d4",
      "backgroundColor_danger_focus": "#faf0f0",
      "backgroundColor_danger_hover": "#faf0f0",
      "backgroundColor_disabled": "#ebeff5",
      "backgroundColor_focus": "#ffffff",
      "backgroundColor_hover": "#f5f7fa",
      "backgroundColor_successPrimary": "#2a854e",
      "backgroundColor_successPrimary_active": "#20693d",
      "backgroundColor_successPrimary_focus": "#2a854e",
      "backgroundColor_successPrimary_hover": "#3ba164",
      "backgroundColor_success_active": "#abedc5",
      "backgroundColor_success_focus": "#e1faeb",
      "backgroundColor_success_hover": "#e1faeb",
      "backgroundColor_themePrimary": "#3272d9",
      "backgroundColor_themePrimary_active": "#1d5bbf",
      "backgroundColor_themePrimary_focus": "#3272d9",
      "backgroundColor_themePrimary_hover": "#5691f0",
      "backgroundColor_theme_selected": "#f9f4f1",
      "backgroundColor_theme_selectedAction": "#e8c9b5",
      "backgroundColor_theme_selectedActive": "#accbfc",
      "backgroundColor_theme_selectedHover": "#e4aa86",
      "backgroundColor_warningPrimary": "#ad5f00",
      "backgroundColor_warningPrimary_active": "#8a4d03",
      "backgroundColor_warningPrimary_focus": "#ad5f00",
      "backgroundColor_warningPrimary_hover": "#cf7911",
      "backgroundColor_warning_active": "#fad8af",
      "backgroundColor_warning_focus": "#fcf2e6",
      "backgroundColor_warning_hover": "#fcf2e6",
      "borderColor": "#c8d1e0",
      "borderColor_danger": "#de1b1b",
      "borderColor_danger_active": "#b80d0d",
      "borderColor_danger_focus": "#b80d0d",
      "borderColor_danger_hover": "#f55353",
      "borderColor_success": "#2a854e",
      "borderColor_success_active": "#20693d",
      "borderColor_success_focus": "#20693d",
      "borderColor_success_hover": "#3ba164",
      "borderColor_theme": "#EB6B19",
      "borderColor_theme_active": "#9e3e00",
      "borderColor_theme_focus": "#c95307",
      "borderColor_theme_hover": "#c95307",
      "borderColor_warning": "#ad5f00",
      "borderColor_warning_active": "#8a4d03",
      "borderColor_warning_focus": "#8a4d03",
      "borderColor_warning_hover": "#cf7911",
      "borderRadius_1": "0.1875em",
      "boxShadow_1": "0 1px 2px 0 rgba(0,0,0,0.2), 0 2px 4px 0 rgba(0,0,0,0.2)",
      "boxShadow_2": "0 2px 4px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.2)",
      "boxShadow_3": "0 4px 8px 0 rgba(0,0,0,0.2), 0 8px 16px 0 rgba(0,0,0,0.2)",
      "boxShadow_4": "0 8px 16px 0 rgba(0,0,0,0.2), 0 20px 16px -8px rgba(0,0,0,0.2)",
      "boxShadow_5": "0 16px 24px 0 rgba(0,0,0,0.2), 0 32px 24px -16px rgba(0,0,0,0.2)",
      "boxShadow_focusInner": "#ffffff",
      "breakpoint_medium": "768px",
      "breakpoint_narrow": "512px",
      "breakpoint_wide": "1024px",
      "color": "#333840",
      "color_black": "#1d1f24",
      "color_danger": "#de1b1b",
      "color_dangerPrimary": "#ffffff",
      "color_danger_active": "#b80d0d",
      "color_danger_focus": "#de1b1b",
      "color_danger_hover": "#f55353",
      "color_disabled": "#afbacc",
      "color_gray_10": "#f5f7fa",
      "color_gray_100": "#333840",
      "color_gray_20": "#ebeff5",
      "color_gray_30": "#dde3ed",
      "color_gray_40": "#c8d1e0",
      "color_gray_50": "#afbacc",
      "color_gray_60": "#8e99ab",
      "color_gray_70": "#707a8a",
      "color_gray_80": "#58606e",
      "color_gray_90": "#434a54",
      "color_inverted": "#ffffff",
      "color_mouse": "#58606e",
      "color_readOnly": "#58606e",
      "color_required": "#de1b1b",
      "color_success": "#2a854e",
      "color_successPrimary": "#ffffff",
      "color_success_active": "#20693d",
      "color_success_focus": "#2a854e",
      "color_success_hover": "#3ba164",
      "color_theme": "#3272d9",
      "color_themePrimary": "#ffffff",
      "color_theme_10": "#f0f5fc",
      "color_theme_100": "#15233b",
      "color_theme_20": "#cfe0fc",
      "color_theme_30": "#accbfc",
      "color_theme_40": "#84b1fa",
      "color_theme_50": "#5691f0",
      "color_theme_60": "#3272d9",
      "color_theme_70": "#1d5bbf",
      "color_theme_80": "#114599",
      "color_theme_90": "#103570",
      "color_theme_active": "#1d5bbf",
      "color_theme_focus": "#3272d9",
      "color_theme_hover": "#fff",
      "color_warning": "#ad5f00",
      "color_warningPrimary": "#ffffff",
      "color_warning_active": "#8a4d03",
      "color_warning_focus": "#ad5f00",
      "color_warning_hover": "#cf7911",
      "color_white": "#ffffff",
      "direction": "ltr",
      "fontFamily": ""Open Sans"",
      "fontFamily_monospace": ""SF Mono", "Droid Sans Mono", "Source Code Pro", Monaco, Consolas, "Courier New", Courier, monospace",
      "fontFamily_system": "-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"",
      "fontSize_base": "16px",
      "fontSize_mouse": "0.6875em",
      "fontSize_prose": "1em",
      "fontSize_ui": "0.875em",
      "fontWeight_bold": 700,
      "fontWeight_extraBold": 800,
      "fontWeight_regular": 400,
      "fontWeight_semiBold": 600,
      "h1_color": "#333840",
      "h1_fontSize": "2.125em",
      "h1_fontWeight": 800,
      "h2_color": "#58606e",
      "h2_fontSize": "1.75em",
      "h2_fontWeight": 700,
      "h3_color": "#58606e",
      "h3_fontSize": "1.375em",
      "h3_fontWeight": 700,
      "h4_color": "#58606e",
      "h4_fontSize": "1.125em",
      "h4_fontWeight": 700,
      "h5_color": "#333840",
      "h5_fontSize": "0.875em",
      "h5_fontWeight": 700,
      "h6_color": "#58606e",
      "h6_fontSize": "0.875em",
      "h6_fontWeight": 400,
      "icon_color": "#58606e",
      "icon_color_danger": "#de1b1b",
      "icon_color_success": "#2a854e",
      "icon_color_theme": "#eb6b19",
      "icon_color_warning": "#ad5f00",
      "input_backgroundColor": "#ffffff",
      "input_backgroundColor_disabled": "#ebeff5",
      "input_color_placeholder": "#8e99ab",
      "lineHeight": 1.25,
      "lineHeight_heading": 1.25,
      "lineHeight_heading_small": 1.5,
      "lineHeight_prose": 1.5,
      "panel_backgroundColor": "#ffffff",
      "panel_backgroundColor_inverted": "#434a54",
      "panel_borderColor": "#ebeff5",
      "panel_borderColor_inverted": "#434a54",
      "size_jumbo": "3.25em",
      "size_large": "2.5em",
      "size_medium": "2em",
      "size_small": "1.5em",
      "space_inline_lg": "1.5em",
      "space_inline_md": "1em",
      "space_inline_sm": "0.5em",
      "space_inline_xl": "2em",
      "space_inline_xs": "0.25em",
      "space_inline_xxl": "4em",
      "space_inline_xxs": "0.125em",
      "space_inset_lg": "1.5em",
      "space_inset_md": "1em",
      "space_inset_sm": "0.5em",
      "space_stack_lg": "1.5em",
      "space_stack_md": "1em",
      "space_stack_sm": "0.5em",
      "space_stack_xl": "2em",
      "space_stack_xs": "0.25em",
      "space_stack_xxl": "4em",
      "space_stack_xxs": "0.125em",
      "well_backgroundColor": "#ebeff5",
      "well_backgroundColor_danger": "#fad4d4",
      "well_backgroundColor_success": "#abedc5",
      "well_backgroundColor_warning": "#fad8af",
      "well_borderColor_danger": "#fa8e8e",
      "well_borderColor_success": "#57c282",
      "well_borderColor_warning": "#e89c3f",
      "zIndex_100": 100,
      "zIndex_1600": 1600,
      "zIndex_200": 200,
      "zIndex_400": 400,
      "zIndex_800": 800,
    }
  }
>
  <div
    className="row"
    style={
      {
        "paddingBottom": "1em",
        "paddingTop": "1em",
      }
    }
  >
    <div
      className="col-sm-12"
    >
      <div
        className="title-bar"
      >
        Bank Disclosure
      </div>
      <h5>
        <strong>
          Merchant Services Provider Contact Information
        </strong>
      </h5>
      <div
        className="row"
      >
        <div
          className="col-md-10"
        >
          <div
            className="row"
          >
            <div
              className="col-xs-6 col-sm-2"
            >
              Name
              <br />
              Address
            </div>
            <div
              className="col-xs-6 col-sm-3"
            >
              Affinipay, LLC 
              <br />
              3700 N Capital of Texas Hwy Suite 300
              <br />
              Austin, TX 78746
            </div>
            <div
              className="col-xs-6 col-sm-3"
            >
              Website URL 
              <br />
              Phone Number
            </div>
            <div
              className="col-xs-6 col-sm-4"
            >
              <a
                href="https://www.affinipay.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.affinipay.com
              </a>
              <br />
              <a
                href="tel:855-656-4684"
              >
                (855) 656-4684
              </a>
            </div>
          </div>
        </div>
      </div>
      <h5>
        <strong>
          Member Bank Information: Wells Fargo Bank
        </strong>
      </h5>
      <p>
        The Bank’s mailing address is Wells Fargo Bank, N.A., PO Box 6079, Concord, CA 94524 and its phone number is
         
        <a
          href="tel:800-451-5817"
          rel="noopener noreferrer"
          target="_blank"
        >
          (800) 451-5817
        </a>
        .
      </p>
      <h5>
        <strong>
          Important Member Bank Responsibilities
        </strong>
      </h5>
      <ul>
        <li>
          The Bank is the only entity approved to extend acceptance of Card Organization products directly to a Merchant.
        </li>
        <li>
          The Bank must be a principal (signer) to the Merchant Agreement.
        </li>
        <li>
          The Bank is responsible for educating Merchants on pertinent Visa and MasterCard Rules with which Merchants must comply; but this information may be provided to you by Processor.
        </li>
        <li>
          The Bank is responsible for and must provide settlement funds to the Merchant.
        </li>
        <li>
          The Bank is responsible for all funds held in reserve.
        </li>
      </ul>
      <h5>
        <strong>
          Important Merchant Responsibilities
        </strong>
      </h5>
      <ul>
        <li>
          Ensure compliance with cardholder data security and storage requirements.
        </li>
        <li>
          Maintain fraud and chargebacks below Card Organization thresholds.
        </li>
        <li>
          Review and understand the terms of the Merchant Agreement.
        </li>
        <li>
          Comply with Card Organization rules.
        </li>
        <li>
          Retain a signed copy of the Disclosure Page.
        </li>
      </ul>
      <h5>
        <strong>
          Merchant Resources
        </strong>
      </h5>
      <ul>
        <li>
          You may download “Visa Regulations” from Visa’s website at:
           
          <a
            href="https://usa.visa.com/support/small-business/regulations-fees.html#3"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://usa.visa.com/support/small-business/regulations-fees.html#3
          </a>
        </li>
        <li>
          You may download “MasterCard Rules” from MasterCard’s website at:
           
          <a
            href="http://www.mastercard.com/us/merchant/support/rules.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://www.mastercard.com/us/merchant/support/rules.html
          </a>
        </li>
      </ul>
      <p>
        The responsibilities above do not replace the terms of the Merchant Agreement and are provided to ensure the Merchant understands some important obligations of each party and that the Bank is the ultimate authority should the Merchant experience any problems.
      </p>
      <strong>
        By submitting this form you consent to this electronic disclosure of the BANK DISCLOSURE FORM.
      </strong>
    </div>
  </div>
</ThemeProvider>
`;
