import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const CarouselArrowRight = ({
  height='44', 
  width='44', 
  title='Next Slide',
  className
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <circle cx="22" cy="22" r="21.5" fill="#D9D9D9" fillOpacity="0.01" stroke="#979797"/>
    <path d="M26.2188 21.2578C26.6275 21.6665 26.6275 22.3275 26.2188 22.7319L19.2615 29.6934C18.8528 30.1022 18.1919 30.1022 17.7875 29.6934C17.3831 29.2847 17.3787 28.6238 17.7875 28.2194L24.0055 22.0014L17.7831 15.779C17.3744 15.3703 17.3744 14.7093 17.7831 14.3049C18.1919 13.9005 18.8528 13.8962 19.2572 14.3049L26.2188 21.2578Z" fill="#343A40"/>
  </svg>
);

CarouselArrowRight.propTypes = {
  height: oneOfType([number, string]),
  width: oneOfType([number, string]),
  title: string,
  className: string
};

export default CarouselArrowRight;
