import React from 'react';
import './style.scss';
import image1 from './timers-feature-image-1.jpg';
import image2 from './timers-feature-image-2.jpg';
import image3 from './timers-feature-image-3.jpg';

const TimersFeatureView = () => (
  <div className="timers-feature-view-wrapper">
    <div className="timers-feature-access">
      <img src={image1} alt="Timer Feature UI Access and View" width="890" height="190" />
      <div className="start-timer-marker" data-testid="start-timer-marker">1</div>
      <div className="timer-details-marker" data-testid="timer-details-marker">2</div>
    </div>
    <div className="timers-feature-image-2">
      <img src={image2} alt="Timer Running Time View" width="890" height="179"/>
      <div className="running-timer" data-testid="running-timer">3</div>
    </div>
    <div className="timers-feature-image-3">
      <img src={image3} alt="Pause Timer View" width="887" height="242" />
      <div className="pause-timer" data-testid="pause-timer">4</div>
    </div>
  </div>
);

export default TimersFeatureView;
