import React from 'react';
import './style.scss';
import image1 from './saved-payments-feature-image-0.jpg';
import image2 from './saved-payments-feature-image-1.jpg';

const SavedPaymentsFeatureView = () => (
  <div className="saved-payments-feature-view-wrapper">
    <div className="saved-payments-feature-access-image-1">
      <img src={image1} alt="Saved Payments UI View" />
      <div className="saved-payments-feature-marker-1">1</div>
    </div>
    <div className="saved-payments-feature-access-image-2">
      <img src={image2} alt="Contact Details UI View" />
      <div className="saved-payments-feature-marker-2">2</div>
      <div className="saved-payments-feature-marker-3">3</div>
    </div>
  </div>
);

export default SavedPaymentsFeatureView;
