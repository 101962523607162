import React from 'react';
import { oneOfType, number, string } from 'prop-types';

const ChevronRight = ({
  className,
  width='6',
  height='10',
  title='Chevron Right'
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path d="M5.63575 4.53613C5.89121 4.79159 5.89121 5.20467 5.63575 5.45742L1.28748 9.8084C1.03202 10.0639 0.618931 10.0639 0.366188 9.8084C0.113445 9.55294 0.110727 9.13986 0.366188 8.88711L4.25245 5.00085L0.363471 1.11187C0.10801 0.856407 0.10801 0.443321 0.363471 0.190578C0.618931 -0.0621653 1.03202 -0.064883 1.28476 0.190578L5.63575 4.53613Z" fill="white"/>
  </svg>
);

ChevronRight.propTypes = {
  className: string,
  width: oneOfType([ number, string ]),
  height: oneOfType([ number, string ]),
  title: string
};

export default ChevronRight;
