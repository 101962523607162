import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import APCard from '.';
import AcceptingPaymentsBillboard from '../../assets/billboardSVGs/AcceptingPaymentsBillboard';

describe('APCard', () => {
  const defaultProps = {
    billboard: <AcceptingPaymentsBillboard />,
    buttonName: 'testButton',
    buttonTestId: 'test',
    buttonText: 'Click me!',
    description: 'This is a description test for APCard',
    titleValues: ['Testing', 'AP Card'],
    hasBorder: true,
    handleButtonClick: jest.fn()
  };

  it('renders without props', () => {
    render(<APCard handleButtonClick={defaultProps.handleButtonClick} />);

    expect(screen.getByTestId('apcard-wrapper')).toBeVisible();
    expect(screen.getByRole('button')).toBeVisible();
  });

  it('renders with all props', () => {
    const {container} = render(<APCard {...defaultProps } />);

    const cardButton = screen.getByTestId('test-card-button');

    expect(screen.getByTitle('Accepting Payments')).toBeInTheDocument();
    expect(screen.getByText('Testing')).toBeVisible();
    expect(screen.getByText('AP Card')).toBeVisible();
    expect(screen.getByText('This is a description test for APCard')).toBeVisible();
    expect(screen.getByText('Click me!')).toBeVisible();
    expect(cardButton).toBeVisible();
    expect(cardButton.name).toBe('testButton');
    expect(container.firstChild.className).toBe('apcard-wrapper has-border');
  });

  it('clicking the button invokes the handleButtonClick prop', () =>  {
    render(<APCard {...defaultProps } />);
    userEvent.click(screen.getByRole('button'));

    expect(defaultProps.handleButtonClick).toHaveBeenCalledTimes(1);
  });

  it('using the keyboard on the button invokes the handleButtonClick prop', () =>  {
    const onSpace = jest.fn();
    render(<APCard {...defaultProps} handleButtonClick={onSpace} />);

    userEvent.tab();
    userEvent.keyboard(' ');
    
    expect(onSpace).toHaveBeenCalledTimes(2);
  });

  it('setting hasBorder to false will remove the cards border', () =>  {
    const {container} = render(<APCard {...defaultProps } hasBorder={false} />);
    expect(container.firstChild.className).toBe('apcard-wrapper');
  });
});
