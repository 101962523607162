import jwtDecode from 'jwt-decode';
import server from '../../../lib/server';
import { rollbarLog as log } from '../../../lib/utils';
import AccessToken from '../../../lib/AccessToken';

let accessToken;

const accessTokenExp = accessToken => {
  const { exp } = jwtDecode(accessToken);
  
  return exp;
};

export const isAccessTokenExpired = ()  => {
  if (!accessToken) return true;

  const currentTime = Math.floor(Date.now() / 1000);

  return currentTime > accessTokenExp(accessToken);
};

const fetchAccessToken = async environment => {
  const response = await AccessTokenService(environment);
  const { accessToken, error } = response;

  if (error) { throw new Error(error); }
  
  return accessToken;
};


export const authHeaders = async environment => {
  if (isAccessTokenExpired(accessToken)) {
    accessToken = await fetchAccessToken(environment);
  }
    
  return {
    'Accept': 'application/vnd.api+json',
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/vnd.api+json'
  };
};

const AccessTokenService = async environment => {
  const url = environment.merchantAccessTokenUrl;
  
  try {
    const resp = await server.get({
      url,
      credentials: 'include'
    });

    const json = await resp.json();

    return AccessToken(json);
  } catch (message) {
    log('Access Tokens Service Error', message.message || message.statusText, 'warning');

    return AccessToken({ error: `${message.message || message.statusText} - uuid_base62:` });
  }
};

export default AccessTokenService;
