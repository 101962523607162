import React from 'react';
import image1 from './time-expense-image-1.jpg';
import image2 from './time-expense-image-2.jpg';
import image3 from './time-expense-image-3.jpg';
import image4 from './time-expense-image-4.jpg';
import './style.scss';

const TimeExpenseFeatureView = () => (
  <div className="time-expense-feature-view-wrapper">
    <div className="time-expense-access">
      <div className="time-expense-access-grid">
        <img src={image1} alt="Time and Expense UI Access" />
        <div className="time-expense-access-spotlight" />
        <img src={image2} alt="Time and Expense UI Access" />
        <div className="quick-action-marker" data-testid="quick-action-marker">1</div>
      </div>
    </div>
    <div className="time-expense-time-entries">
      <img src={image3} alt="Time and Expense UI Access" />
      <div className="time-entries-access-marker" data-testid="time-entries-access-marker">2</div>
    </div>
    <div className="time-expense-expenses">
      <img src={image4} alt="Time and Expense UI Access" />
      <div className="expenses-tab-marker" data-testid="expenses-tab-marker">3</div>
    </div>
  </div>
);

export default TimeExpenseFeatureView;
