import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const CarouselArrowLeft = ({
  height='44', 
  width='44', 
  title='Previous Slide',
  className
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M15.7832 22.7422C15.3745 22.3335 15.3745 21.6725 15.7832 21.2681L22.7404 14.3066C23.1492 13.8978 23.8101 13.8978 24.2145 14.3066C24.6189 14.7153 24.6232 15.3762 24.2145 15.7806L17.9965 21.9986L24.2188 28.221C24.6276 28.6297 24.6276 29.2907 24.2188 29.6951C23.8101 30.0995 23.1492 30.1038 22.7448 29.6951L15.7832 22.7422Z" fill="#343A40"/>
    <circle cx="22" cy="22" r="21.5" transform="rotate(-180 22 22)" fill="#D9D9D9" fillOpacity="0.01" stroke="#979797"/>
  </svg>
);

CarouselArrowLeft.propTypes = {
  height: oneOfType([number, string]),
  width: oneOfType([number, string]),
  title: string,
  className: string
};

export default CarouselArrowLeft;
