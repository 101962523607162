import React from 'react';
import './style.scss';
import FeatureBar from '../FeatureBar';
import image from './payment-page-view.jpg';

const PaymentPageFeatureView = () => (
  <div className="payment-page-feature-view-wrapper">
    <FeatureBar highlightedFeature='paymentPages' />
    <div className="payment-page-bullet-1">1</div>
    <div className="payment-page-feature-image">
      <img src={image} width="932" alt="Payment Page UI View" />
      <div className="payment-page-bullet-2">2</div>
      <div className="payment-page-bullet-3">3</div>
    </div>
  </div>
);

export default PaymentPageFeatureView;
