import { Errors } from './environment';
import { rollbarLog as log } from '../../../lib/utils';
import server from '../../../lib/server';
import { authHeaders } from './access-tokens';

const NewLawPayExperienceService = {
  postLawPayProEnablement: async (body, environment, supportPhone) => {
    const errorMessage = 'Error updating enabling New LawPay Experience';

    try {
      const headers = await authHeaders(environment);

      const response = await server.post({
        url: environment.enableNLE,
        headers,
        body
      });

      return response;      
    } catch(error) {
      log(errorMessage, Errors[error.status](supportPhone)[0]);
      return error;
    }
  }
};

export default NewLawPayExperienceService;
