import React from 'react';
import { string } from 'prop-types';

const ChainLink = ({
  className,
  fill='#005fb8',
  height='16',
  width='20',
  title='Payment Pages Icon'
}) => (
  <svg 
    className={className}
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height} 
    viewBox="0 0 20 16" 
    fill="none"
  >
    <title>{title}</title>
    <path 
      d="M18.6064 8.3715C20.4645 6.51334 20.4645 3.50127 18.6064 1.64311C16.8375 -0.125777 13.9974 -0.221661 12.1127 1.4282L11.9111 1.60674C11.5804 1.89439 11.5474 2.39695 11.835 2.72759C12.1227 3.05822 12.6252 3.09128 12.9559 2.80363L13.1575 2.62509C14.414 1.52739 16.3052 1.59021 17.4855 2.77057C18.7221 4.00714 18.7221 6.01408 17.4855 7.25396L13.7395 10.9967C12.5029 12.2333 10.4926 12.2333 9.25608 10.9967C8.07572 9.81636 8.0129 7.92514 9.1106 6.66874L9.266 6.49019C9.55365 6.15956 9.52058 5.6603 9.18995 5.36935C8.85932 5.07839 8.36006 5.11476 8.0691 5.44539L7.9137 5.62393C6.26715 7.50854 6.36304 10.3487 8.13192 12.1176C9.99008 13.9757 13.0022 13.9757 14.8603 12.1176L18.6064 8.3715ZM1.39362 7.63088C-0.46454 9.48904 -0.46454 12.5011 1.39362 14.356C3.16581 16.1282 6.00595 16.2207 7.89056 14.5709L8.09225 14.3923C8.42288 14.1047 8.45594 13.6021 8.16829 13.2715C7.88064 12.9408 7.37808 12.9078 7.04745 13.1954L6.84576 13.374C5.58935 14.4717 3.69813 14.4089 2.51777 13.2285C1.2812 11.9919 1.2812 9.98499 2.51777 8.74511L6.26385 5.00565C7.50041 3.76908 9.50736 3.76908 10.7472 5.00565C11.9276 6.18601 11.9904 8.07723 10.8927 9.33364L10.7142 9.53532C10.4265 9.86596 10.4596 10.3652 10.7902 10.6562C11.1208 10.9471 11.6201 10.9108 11.9111 10.5801L12.0896 10.3784C13.7395 8.49383 13.6436 5.65369 11.8747 3.8815C10.0165 2.02334 7.00446 2.02334 5.14631 3.8815L1.39362 7.63088Z" 
      fill={fill}
    />
  </svg>
);

ChainLink.propTypes = {
  className: string,
  fill: string,
  height: string,
  width: string,
  title: string
};

export default ChainLink;

