import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const ViewTimerBillboard = ({height=90, width=88, title='View Timer'}) => (
  <svg width={width} height={height} viewBox="0 0 98 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M49.1153 99.9998C76.1136 99.9998 98.0001 98.5982 98.0001 96.8691C98.0001 95.14 76.1136 93.7383 49.1153 93.7383C22.1169 93.7383 0.230469 95.14 0.230469 96.8691C0.230469 98.5982 22.1169 99.9998 49.1153 99.9998Z" fill="#D1D3D4"/>
    <path d="M96.9203 75.4179C99.1703 85.8331 91.7113 93.3966 91.7113 93.3966H39.0969C39.0969 93.3966 31.6378 85.8331 33.8879 75.4179C36.1379 65.0026 51.9808 59.299 56.7891 55.0213C61.5975 50.7436 59.9022 43.4591 52.3198 37.9105C44.7374 32.3618 34.4735 28.8281 34.7509 16.8009C34.9358 9.11345 40.9463 4.0918 40.9463 4.0918H89.8619C89.8619 4.0918 95.9031 9.08245 96.0573 16.8009C96.3347 28.8281 86.0707 32.3618 78.4883 37.9105C70.9059 43.4591 69.1799 50.7436 74.019 55.0213C78.8274 59.299 94.6702 65.0026 96.9203 75.4179Z" fill="white"/>
    <path d="M91.6812 93.5823H39.0977C39.036 93.5823 39.0052 93.5513 38.9436 93.5203C38.8819 93.4583 31.4228 85.7708 33.6729 75.3556C35.3065 67.8231 44.0601 62.6464 50.4404 58.8957C53.0295 57.3768 55.2796 56.0439 56.6358 54.835C58.3927 53.3471 59.379 51.0842 59.2557 48.7594C59.1016 45.1326 56.5125 41.1959 52.1665 38.0341C50.687 36.9492 49.0534 35.8953 47.4815 34.9034C41.0087 30.7806 34.3202 26.5029 34.5359 16.7386C34.7208 9.02014 40.7621 3.93649 40.8237 3.87449C40.8546 3.8435 40.9162 3.8125 40.947 3.8125H89.8627C89.9243 3.8125 89.9551 3.8125 89.986 3.87449C90.0476 3.93649 96.0889 9.02014 96.2738 16.7386C96.4896 26.5029 89.801 30.7806 83.3283 34.9034C81.7563 35.8953 80.1227 36.9492 78.6432 38.0341C74.2972 41.2269 71.7389 45.1326 71.554 48.7594C71.4307 51.0842 72.417 53.3161 74.1739 54.835C75.5301 56.0439 77.7802 57.3458 80.3693 58.8957C86.7496 62.6464 95.5032 67.8231 97.1368 75.3556C99.3869 85.7708 91.9278 93.4583 91.8662 93.5203C91.8045 93.5823 91.7429 93.5823 91.6812 93.5823ZM39.1901 93.1793H91.6196C92.2977 92.4354 98.8013 85.1509 96.7361 75.4485C95.1642 68.1021 86.503 62.9874 80.1535 59.2677C77.5336 57.7178 75.2835 56.3849 73.8965 55.176C72.0163 53.5951 70.9992 51.2392 71.1225 48.7904C71.3074 45.0397 73.9273 41.0099 78.3658 37.7552C79.8761 36.6392 81.5097 35.6163 83.0817 34.5934C89.462 30.5327 96.058 26.3169 95.8423 16.8006C95.6882 9.67109 90.3867 4.83543 89.7702 4.30847H41.0087C40.423 4.83543 35.0907 9.67109 34.9366 16.8006C34.7208 26.3479 41.3169 30.5327 47.6972 34.5934C49.2692 35.5853 50.9028 36.6392 52.4131 37.7552C56.8516 41.0099 59.5023 45.0397 59.6564 48.7904C59.7797 51.2392 58.7626 53.5951 56.8824 55.176C55.4954 56.4159 53.2453 57.7488 50.6254 59.2677C44.3067 62.9874 35.6455 68.1021 34.0427 75.4485C32.0084 85.1509 38.512 92.4354 39.1901 93.1793Z" fill="#231F20"/>
    <path d="M49.8556 32.331C51.4892 33.3539 53.1537 34.4389 54.7565 35.6168C58.8559 38.6236 61.3217 42.0953 62.832 46.373C64.3115 50.5578 63.9108 54.9905 64.8047 59.3302C64.7738 59.8571 65.1745 60.2911 65.6677 60.3221C66.0067 60.3531 66.315 60.1671 66.4999 59.8881C66.7465 59.5781 66.8698 59.2062 66.9006 58.8032C67.4554 55.3314 67.4554 52.0767 67.6712 48.5429C67.9178 44.0482 71 39.3365 76.0549 35.6168C77.6577 34.4389 79.3222 33.3849 80.9558 32.331C84.6237 30.0062 98.9562 19.6839 89.432 16.6151C87.3977 15.9641 85.1168 16.1191 82.9592 16.3361L48.8693 19.2809C46.8967 19.4669 44.8932 19.6219 43.0746 20.3348C41.2561 21.0478 39.6841 22.3187 39.3759 23.9306C38.7595 27.0304 47.1741 30.6261 49.8556 32.331Z" fill="#7277F1"/>
    <path d="M91.7431 74.5202C89.9554 73.0323 87.3663 72.2263 84.7772 72.3193C81.2942 72.4433 77.9037 73.5902 74.4207 73.4352C70.2905 73.2492 66.376 71.1724 62.2458 71.6064C57.4066 72.0713 53.8928 75.7911 49.2078 76.876C46.7111 77.4649 43.9987 77.279 41.5637 78.0229C38.6972 78.9218 37.4335 81.3707 37.7725 83.7575C37.865 84.5015 39.8993 90.3601 41.0397 90.3601H89.7704C92.2671 90.3601 94.1473 79.6038 93.9007 78.0849C93.6541 76.659 92.8527 75.4191 91.7431 74.5202Z" fill="#7277F1"/>
    <path d="M40.0536 0.21875H90.7569C91.8049 0.21875 92.6679 1.08669 92.6679 2.14062C92.6679 3.19454 91.8049 4.06248 90.7569 4.06248H40.0536C39.0056 4.06248 38.1426 3.19454 38.1426 2.14062C38.1426 1.08669 39.0056 0.21875 40.0536 0.21875Z" fill="#231F20"/>
    <path d="M90.7558 4.24749H40.0525C38.8812 4.27848 37.8949 3.37955 37.8641 2.20163C37.8332 1.02371 38.7271 0.0317758 39.8984 0.000777985C39.96 0.000777985 39.9908 0.000777985 40.0525 0.000777985H90.7558C91.9271 -0.0302199 92.9134 0.86872 92.9442 2.04664C92.9751 3.22456 92.0812 4.21649 90.9099 4.24749C90.8483 4.27848 90.7866 4.27848 90.7558 4.24749ZM40.0525 0.403751C39.097 0.403751 38.3264 1.1787 38.3264 2.13963C38.3264 3.10056 39.097 3.87551 40.0525 3.87551H90.7558C91.7113 3.87551 92.4819 3.10056 92.4819 2.13963C92.4819 1.1787 91.7113 0.403751 90.7558 0.403751H40.0525Z" fill="#231F20"/>
    <path d="M39.0673 93.3984H91.7124C92.7604 93.3984 93.6234 94.2664 93.6234 95.3203C93.6234 96.3742 92.7604 97.2422 91.7124 97.2422H39.0673C38.0193 97.2422 37.1562 96.3742 37.1562 95.3203C37.1562 94.2354 38.0193 93.3984 39.0673 93.3984Z" fill="#231F20"/>
    <path d="M91.7113 97.4272H39.0661C37.8949 97.4582 36.9085 96.5592 36.8777 95.3813C36.8469 94.2034 37.7408 93.2115 38.912 93.1805C38.9737 93.1805 39.0045 93.1805 39.0661 93.1805H91.7113C92.8826 93.1495 93.8689 94.0484 93.8997 95.2263C93.9306 96.4042 93.0367 97.3962 91.8654 97.4272C91.8346 97.4272 91.773 97.4272 91.7113 97.4272ZM39.0661 93.5834C38.1106 93.5834 37.3401 94.3584 37.3401 95.3193C37.3401 96.2803 38.1106 97.0552 39.0661 97.0552H91.7113C92.6668 97.0552 93.4374 96.2803 93.4374 95.3193C93.4374 94.3584 92.6668 93.5834 91.7113 93.5834H39.0661Z" fill="#231F20"/>
    <path d="M64.6178 61.8418C63.1691 61.8418 63.1691 66.6775 64.6178 66.6775C66.0664 66.6775 66.0972 61.8418 64.6178 61.8418Z" fill="#7277F1"/>
    <path d="M67.1775 66.707C66.5919 66.707 66.5919 68.4119 67.1775 68.4119C67.7631 68.4119 67.7631 66.707 67.1775 66.707Z" fill="#7277F1"/>
    <path d="M63.5703 27.8054C56.8818 27.7434 50.2241 26.7205 43.8129 24.7986C43.7205 24.7676 43.6588 24.6436 43.6897 24.5506C43.7205 24.4576 43.8438 24.3956 43.9362 24.4266C44.2136 24.5196 72.139 33.633 87.1189 19.56C87.2113 19.467 87.3346 19.467 87.3963 19.56C87.4887 19.653 87.4887 19.777 87.3963 19.839C80.8002 26.0385 71.7383 27.8054 63.5703 27.8054Z" fill="#231F20"/>
    <path d="M57.2823 82.5178C52.0116 82.5178 46.8026 81.7428 41.7477 80.255C41.6244 80.224 41.5936 80.1 41.6244 80.007C41.6552 79.914 41.7785 79.852 41.871 79.883C42.0251 79.945 57.2207 84.9356 70.9368 79.883L87.2112 73.8694C87.3037 73.8384 87.4269 73.9004 87.4886 73.9934C87.5194 74.0864 87.4578 74.2104 87.3653 74.2724L71.0909 80.255C66.4059 81.9288 61.6283 82.5178 57.2823 82.5178Z" fill="#231F20"/>
    <path d="M77.5949 74.891H77.5333C72.8482 73.7131 64.6494 71.7602 64.5877 71.7292C64.4645 71.6982 64.4028 71.6052 64.4336 71.4812C64.4645 71.3573 64.5877 71.2953 64.6802 71.3263C64.7727 71.3573 72.9407 73.3101 77.6258 74.488C77.749 74.519 77.8107 74.643 77.7799 74.736C77.749 74.829 77.6566 74.891 77.5949 74.891Z" fill="#231F20"/>
    <path d="M16.5054 88.2519L10.8031 94.9475C10.8031 94.9475 -0.200558 88.7169 0.200137 87.477C0.600832 86.2371 7.7517 88.0969 7.7517 88.0969L11.2963 83.4473" fill="white"/>
    <path d="M10.8337 95.1952C10.8029 95.1952 10.7721 95.1952 10.7412 95.1642C7.38156 93.2733 -0.385762 88.7166 0.0149336 87.4457C0.415629 86.1748 5.84042 87.4147 7.72061 87.8797L11.1728 83.354C11.2344 83.261 11.3885 83.23 11.481 83.292C11.5734 83.354 11.6043 83.509 11.5426 83.602L11.5118 83.633L7.96719 88.2827C7.90555 88.3447 7.81308 88.3757 7.72061 88.3757C4.36094 87.5077 0.631389 86.9808 0.41563 87.6007C0.323162 88.3137 5.68631 91.8164 10.7721 94.6992L16.351 88.1277C16.4434 88.0347 16.5667 88.0347 16.6592 88.0967C16.7517 88.1897 16.7517 88.3137 16.69 88.4067L10.9878 95.1022C10.957 95.1642 10.8953 95.1952 10.8337 95.1952Z" fill="#231F20"/>
    <path d="M34.8447 24.5516C34.2282 23.9937 33.4885 23.4977 32.6871 23.3737C31.9782 23.2807 31.6083 23.5597 31.0227 23.8387C30.7761 23.9317 30.7761 24.3036 30.5295 24.3966C30.2829 24.4896 29.7898 24.2416 29.4815 24.2416C27.9404 24.2106 26.4609 24.7376 25.4746 25.9775C24.8273 26.7835 24.4574 27.8064 24.0568 28.7673C22.3615 33.045 19.8032 37.3227 15.6113 39.1826C19.4025 41.9104 24.0259 43.6153 28.6493 43.3363C30.9302 43.2123 33.1186 42.5614 35.1221 41.5075C36.6941 40.6395 39.5298 38.8726 39.838 36.9198C40.1154 35.0599 39.2215 32.2391 38.6667 30.5032C37.927 28.2094 36.6324 26.1635 34.8447 24.5516Z" fill="#444444"/>
    <path d="M27.6944 43.5834C23.5949 43.5834 19.2798 42.0955 15.4577 39.3367C15.3653 39.2747 15.3345 39.1197 15.4269 39.0267C15.4577 38.9957 15.4886 38.9647 15.5194 38.9647C19.0024 37.4458 21.7148 34.067 23.8415 28.6734L23.934 28.4254C24.2422 27.4955 24.7046 26.5965 25.2902 25.8216C26.2457 24.6127 27.756 23.9617 29.4821 23.9927C29.6362 23.9927 29.7903 24.0237 29.9444 24.0857C30.0985 24.1477 30.2526 24.1787 30.4376 24.1477C30.4992 24.1167 30.5301 24.0547 30.5917 23.9617C30.6533 23.8067 30.7766 23.6827 30.9307 23.5897C31.054 23.5277 31.1465 23.4967 31.239 23.4347C31.6705 23.1558 32.1945 23.0318 32.7185 23.0938C33.674 23.2178 34.4754 23.8687 34.9993 24.3027C36.7871 25.9456 38.1124 28.0224 38.8522 30.3473C39.4995 32.2691 40.3009 35.0279 40.0543 36.8568C39.7769 38.7787 37.2802 40.4836 35.2459 41.5995C33.2116 42.6844 30.9924 43.3354 28.6807 43.4594C28.3416 43.5834 28.0026 43.5834 27.6944 43.5834ZM16.0434 39.2127C19.9887 41.9715 24.458 43.3664 28.6499 43.1184C30.8691 42.9944 33.0575 42.3744 35.0302 41.3205C37.8042 39.8016 39.4378 38.2517 39.6228 36.8878C39.8693 35.1519 39.0679 32.4551 38.4515 30.5953C37.7426 28.3324 36.448 26.3175 34.7219 24.7367C34.2288 24.3027 33.5199 23.7447 32.6568 23.6207C32.2253 23.5587 31.7938 23.6827 31.4239 23.8997C31.3314 23.9617 31.2081 23.9927 31.0849 24.0547C31.0232 24.1167 30.9616 24.1787 30.9616 24.2407C30.8999 24.4267 30.7458 24.5507 30.5609 24.6127C30.3143 24.6747 30.0369 24.6437 29.8211 24.5507C29.6978 24.5197 29.5745 24.4887 29.4513 24.4887C27.8485 24.4577 26.4923 25.0466 25.6292 26.1316C25.0744 26.8755 24.6429 27.7125 24.3655 28.6114L24.273 28.8594C22.1463 34.191 19.4647 37.5698 16.0434 39.2127Z" fill="#231F20"/>
    <path d="M27.0464 38.9965C27.7861 38.1906 25.1045 38.8105 24.8271 44.0182C24.6422 47.9859 25.9059 54.4645 25.9059 54.4645L17.4297 61.594L22.6695 63.1129C22.6695 63.1129 32.2246 56.1383 32.2246 55.6114C32.2246 55.0844 27.0464 38.9965 27.0464 38.9965Z" fill="#CED4DA"/>
    <path d="M22.6705 63.2665H22.6088L17.369 61.7476C17.3073 61.7166 17.2457 61.6546 17.2148 61.5926C17.2148 61.5306 17.2148 61.4376 17.2765 61.4066L25.6603 54.3701C25.4753 53.4711 24.4274 47.6435 24.6123 44.0168C24.7972 40.2661 26.2767 38.6852 26.9548 38.5302C27.0781 38.4682 27.2322 38.5302 27.3247 38.6542C27.3863 38.7782 27.3555 38.9641 27.263 39.0571C28.2186 41.9709 32.4104 55.083 32.4104 55.61C32.4104 56.137 27.1706 60.0427 22.7938 63.2665C22.7629 63.2665 22.7013 63.2665 22.6705 63.2665ZM17.8621 61.4686L22.6088 62.8325C26.0918 60.2907 31.5782 56.168 31.9789 55.548C31.8248 54.7731 28.835 45.3187 26.8007 39.0571C26.2767 39.4601 25.1363 40.793 24.9822 44.0478C24.7972 47.9535 26.061 54.3701 26.061 54.4321C26.061 54.4941 26.061 54.5871 25.9993 54.6181L17.8621 61.4686Z" fill="#231F20"/>
    <path d="M41.9342 34.6867C41.626 34.4387 27.8483 35.3067 25.5057 39.9254C23.1632 44.544 31.6703 58.4931 31.6703 58.4931L49.0851 53.2544C49.0543 53.2234 50.318 41.2583 41.9342 34.6867Z" fill="#7277F1"/>
    <path d="M35.1221 35.4623L36.0776 38.5311C36.0776 38.5311 36.1393 40.05 33.9509 40.236C31.7624 40.4219 31.0227 39.12 31.0227 39.12L29.4199 33.3544C29.4199 33.3544 32.0399 30.5956 30.5295 27.4648C31.8549 28.7358 33.8276 29.0767 35.492 28.2708C35.492 28.2708 36.4783 31.1846 36.7557 32.8275C37.0331 34.4704 35.1221 35.4623 35.1221 35.4623Z" fill="#CED4DA"/>
    <path d="M33.551 40.4534C31.5784 40.4534 30.8386 39.2445 30.8078 39.1825C30.8078 39.1515 30.8078 39.1515 30.777 39.1205L29.1742 33.3549C29.1434 33.2929 29.1742 33.1999 29.2358 33.1379C29.2667 33.1069 31.7325 30.4721 30.3146 27.5273C30.2838 27.4343 30.3146 27.3103 30.3763 27.2483C30.4688 27.1863 30.592 27.1863 30.6537 27.2793C31.9174 28.4882 33.7976 28.7982 35.3387 28.0232C35.4004 27.9922 35.462 27.9922 35.5237 28.0232C35.5853 28.0542 35.6161 28.0852 35.647 28.1472C35.6778 28.2712 36.6641 31.092 36.9107 32.7659C37.1573 34.2848 35.8319 35.2457 35.3387 35.5557L36.2634 38.4385C36.2634 38.4695 36.2634 38.4695 36.2634 38.5005C36.2634 38.5315 36.2942 40.2364 33.9209 40.4224C33.8284 40.4534 33.7051 40.4534 33.551 40.4534ZM31.2393 38.9965C31.3626 39.1515 32.0715 40.1434 33.9517 39.9884C35.7703 39.8334 35.8627 38.7485 35.8936 38.5625L34.938 35.5247C34.9072 35.4317 34.9689 35.3077 35.0613 35.2457C35.0922 35.2457 36.7874 34.3158 36.5717 32.8279C36.2634 31.371 35.8627 29.9451 35.4004 28.5192C33.9825 29.1392 32.3489 28.9842 31.0852 28.0852C31.825 30.6581 30.1297 32.8589 29.729 33.3239L31.2393 38.9965Z" fill="#231F20"/>
    <path d="M30.3146 32.8577C30.1605 32.6098 29.9139 32.3928 29.6365 32.2998C29.3591 32.2068 29.0817 32.1758 28.8043 32.1758C28.6501 32.1758 28.496 32.1758 28.3419 32.2378C28.2495 32.2998 28.157 32.3618 28.0953 32.4238C27.9104 32.6098 27.8179 32.8577 27.7871 33.1057C27.7871 33.3227 27.8488 33.5087 27.9412 33.6947C28.0337 33.9117 28.157 34.0976 28.3111 34.2526C28.9275 34.9656 30.0063 35.0276 30.6844 34.4076" fill="#CED4DA"/>
    <path d="M29.5734 35.0585H29.4501C28.9261 35.0275 28.4637 34.7795 28.1247 34.4076C27.9706 34.2216 27.8473 34.0046 27.724 33.7876C27.6007 33.5706 27.5391 33.3226 27.5391 33.1057C27.5699 32.7957 27.6932 32.5167 27.9089 32.2687C28.0014 32.1757 28.1247 32.0827 28.248 32.0207C28.4329 31.9587 28.6179 31.9277 28.8028 31.9277C29.111 31.9277 29.4192 31.9587 29.6967 32.0517C30.0357 32.1757 30.3131 32.3927 30.498 32.7027C30.5597 32.7957 30.5289 32.9507 30.4056 33.0127C30.3131 33.0747 30.159 33.0437 30.0973 32.9507C29.9741 32.7337 29.7583 32.5787 29.5117 32.4857C29.2651 32.3927 29.0186 32.3617 28.772 32.3617C28.6487 32.3617 28.5254 32.3617 28.4329 32.3927C28.3713 32.4237 28.3096 32.4857 28.248 32.5477C28.0939 32.6717 28.0014 32.8887 27.9706 33.0747C27.9706 33.2296 28.0322 33.4156 28.1247 33.5706C28.2172 33.7566 28.3096 33.9426 28.4637 34.0976C28.7103 34.3766 29.0802 34.5626 29.4809 34.5936C29.8816 34.6245 30.2515 34.4696 30.5597 34.2216C30.6522 34.1286 30.8063 34.1286 30.8679 34.2216C30.9296 34.3146 30.9604 34.4696 30.8679 34.5316C30.498 34.9035 30.0357 35.0585 29.5734 35.0585Z" fill="#231F20"/>
    <path d="M33.3965 30.5645C33.3965 30.5645 35.0301 31.7424 34.876 32.1143C34.7219 32.4863 33.9821 32.5483 33.9821 32.5483" fill="#D1D3D4"/>
    <path d="M33.9515 32.7958C33.8282 32.7958 33.7357 32.7028 33.7357 32.5788C33.7357 32.4548 33.8282 32.3308 33.9207 32.3308C34.1981 32.2998 34.568 32.1758 34.6604 32.0208C34.6296 31.8659 33.9823 31.2769 33.2734 30.7499C33.1809 30.6879 33.1501 30.5329 33.2118 30.44C33.2734 30.347 33.4275 30.316 33.52 30.378C34.6912 31.2459 35.2152 31.8349 35.0611 32.2068C34.8762 32.6718 34.1364 32.7648 33.9515 32.7958Z" fill="#231F20"/>
    <path d="M32.3777 30.1931C32.5065 30.1098 32.514 29.8905 32.3943 29.7032C32.2746 29.516 32.0732 29.4318 31.9443 29.5152C31.8154 29.5985 31.808 29.8178 31.9276 30.005C32.0473 30.1922 32.2488 30.2764 32.3777 30.1931Z" fill="#231F20"/>
    <path d="M34.1682 29.9657C34.2974 29.883 34.3059 29.6637 34.1871 29.476C34.0683 29.2882 33.8672 29.2031 33.7379 29.2858C33.6087 29.3685 33.6002 29.5878 33.719 29.7755C33.8378 29.9633 34.0389 30.0484 34.1682 29.9657Z" fill="#231F20"/>
    <path d="M35.3679 32.9199C35.3679 32.9199 33.1795 35.2448 32.0391 32.9199H35.3679Z" fill="#D1D3D4"/>
    <path d="M33.4262 34.1595C33.3338 34.1595 33.2721 34.1595 33.1796 34.1285C32.6248 34.0355 32.1625 33.6635 31.8543 32.9815C31.7926 32.8575 31.8543 32.7336 31.9776 32.6716C32.07 32.6406 32.1933 32.6716 32.255 32.7646C32.5015 33.2915 32.8406 33.6015 33.2413 33.6635C34.166 33.8185 35.1831 32.7336 35.1831 32.7026C35.2756 32.6096 35.3989 32.6096 35.4913 32.7026C35.5838 32.7956 35.5838 32.9195 35.4913 33.0125C35.4913 33.1365 34.4742 34.1595 33.4262 34.1595Z" fill="#231F20"/>
    <path d="M35.1221 35.4629C35.1221 35.4629 33.5193 35.9279 32.6562 35.7109C32.6562 35.7109 33.7967 37.3538 35.6769 37.2918L35.1221 35.4629Z" fill="#231F20"/>
    <path d="M35.5851 37.5383C33.6741 37.5383 32.5337 35.9264 32.472 35.8644C32.4104 35.8024 32.4104 35.6785 32.472 35.6165C32.5337 35.5545 32.6261 35.4925 32.7186 35.5235C33.52 35.7094 35.0303 35.2755 35.0611 35.2755C35.1844 35.2445 35.3077 35.3065 35.3385 35.4305L35.8933 37.2903C35.9242 37.3523 35.8933 37.4453 35.8625 37.4763C35.8317 37.5383 35.77 37.5693 35.6776 37.5693L35.5851 37.5383ZM33.2118 35.9884C33.7974 36.5774 34.5371 36.9804 35.3694 37.0734L34.9687 35.7404C34.383 35.8954 33.7974 35.9574 33.2118 35.9884Z" fill="#231F20"/>
    <path d="M49.0532 53.2539C49.0532 53.2539 49.0532 62.0883 48.036 65.808C45.1387 76.3783 23.9018 93.2721 23.9018 93.2721L19.2168 88.5915L32.1315 69.8688L16.5044 88.2815L11.3262 83.5388L31.6692 58.5235L49.0532 53.2539Z" fill="#231F20"/>
    <path d="M23.9325 93.4907C23.8708 93.4907 23.8092 93.4597 23.7784 93.4287L19.0625 88.748C18.97 88.686 18.97 88.562 19.0317 88.469L29.3881 73.4351L16.6891 88.407C16.5967 88.5 16.4734 88.5 16.3809 88.438L11.2027 83.6954C11.1102 83.6024 11.1102 83.4784 11.1719 83.3854L31.5149 58.3701C31.5457 58.3391 31.5765 58.3081 31.6381 58.3081L48.9913 53.0695C49.053 53.0385 49.1454 53.0695 49.1763 53.1005C49.2379 53.1315 49.2687 53.1935 49.2687 53.2865C49.2687 53.6584 49.2687 62.1828 48.2516 65.9026C47.0495 70.3353 42.5802 76.3179 35.0286 83.6954C29.4189 89.182 24.0866 93.4287 24.0249 93.4597C24.0249 93.4597 23.9633 93.4907 23.9325 93.4907ZM19.5248 88.562L23.9633 92.9637C25.9051 91.4138 45.1385 75.6669 47.8509 65.7476C48.7448 62.4618 48.8372 55.1463 48.868 53.5344L31.7923 58.6801L11.6342 83.4784L16.5042 87.9421L31.9772 69.7153C32.0388 69.6223 32.193 69.5913 32.2854 69.6843C32.3779 69.7463 32.4087 69.9013 32.3162 69.9943L19.5248 88.562Z" fill="#231F20"/>
    <path d="M19.2181 88.5918L15.8585 92.3425C15.8585 92.3425 10.3412 90.4827 10.5569 91.5366C10.7727 92.5905 17.9852 98.4801 18.3551 98.6041C18.725 98.7281 23.9032 93.2725 23.9032 93.2725" fill="white"/>
    <path d="M18.3551 98.8212C18.3243 98.8212 18.3243 98.8212 18.2935 98.8212C17.8619 98.6662 10.6186 92.7456 10.3412 91.5987C10.3104 91.4437 10.3412 91.2887 10.4645 91.1647C11.0809 90.6068 14.5331 91.6607 15.766 92.0947L19.0332 88.4369C19.1257 88.3439 19.249 88.3439 19.3414 88.4369C19.4339 88.5299 19.4339 88.6539 19.3414 88.7469L16.0126 92.4977C15.9509 92.5596 15.8585 92.5906 15.766 92.5596C13.331 91.7227 11.0193 91.2577 10.7727 91.4747C10.9885 92.3737 17.5845 97.8293 18.3859 98.3252C18.9099 98.0153 21.499 95.4424 23.7491 93.0866C23.8415 92.9936 23.9648 92.9936 24.0573 93.0866C24.1498 93.1796 24.1498 93.3036 24.0573 93.3966C19.3723 98.3562 18.6017 98.8212 18.3551 98.8212Z" fill="#231F20"/>
    <path d="M24.4568 90.328C24.3952 90.328 24.3336 90.297 24.3027 90.266C24.2411 90.173 24.2411 90.08 24.3336 89.987C24.5185 89.832 41.81 74.984 45.2622 66.5836C48.6835 58.2762 46.865 54.9594 46.865 54.9284C46.8033 54.8354 46.8341 54.7114 46.9266 54.6494C47.0191 54.5874 47.1424 54.6184 47.204 54.7114C47.2965 54.8354 49.115 58.1832 45.632 66.7076C42.1491 75.17 24.7651 90.111 24.611 90.266C24.5493 90.328 24.5185 90.328 24.4568 90.328Z" fill="white"/>
    <path d="M33.3961 62.7084C33.3344 62.7084 33.3036 62.6774 33.2728 62.6464C33.2112 62.5534 33.2112 62.4294 33.2728 62.3674L37.6188 57.5937C37.6804 57.5007 37.8037 57.5007 37.8962 57.5627C37.927 57.5937 37.9578 57.6557 37.9578 57.7177V61.2825C37.9578 61.4065 37.8962 61.4995 37.7729 61.4995C37.6496 61.4995 37.5572 61.4375 37.5572 61.3135C37.5572 61.2825 37.5572 61.2825 37.5572 61.2515V58.2137L33.5502 62.6154C33.5194 62.6774 33.4577 62.7084 33.3961 62.7084Z" fill="white"/>
    <path d="M29.081 65.0657L34.8757 58.4941H20.5123L12.8066 66.1506L29.081 65.0657Z" fill="white"/>
    <path d="M12.8076 66.3675C12.6843 66.3675 12.5918 66.2745 12.5918 66.1505C12.5918 66.0886 12.6226 66.0576 12.6534 65.9956L20.3591 58.3081C20.3899 58.2771 20.4516 58.2461 20.5132 58.2461H34.8766C34.9999 58.2461 35.0924 58.3391 35.0924 58.4631C35.0924 58.5251 35.0616 58.5561 35.0307 58.5871L29.2361 65.1896C29.2052 65.2206 29.1436 65.2516 29.1128 65.2516L12.8076 66.3675ZM20.6057 58.6801L13.3315 65.9026L28.9895 64.8796L34.4143 58.6801H20.6057Z" fill="#231F20"/>
    <path d="M39.8078 36.9195C39.8078 36.9195 43.3524 51.1786 42.3661 51.6435C41.3797 52.1085 29.1431 56.0452 28.465 56.3242C27.7869 56.6032 26.1225 57.8121 25.8451 58.463C25.5677 59.114 25.2595 61.6868 25.8451 61.5318C26.4307 61.3769 27.8794 61.0359 28.3417 60.7879C28.8041 60.5399 29.4514 59.176 29.4514 59.176C29.4514 59.176 29.3897 60.5089 30.0062 60.2299C30.6226 59.9509 31.7322 58.463 31.7322 58.463C31.7322 58.463 47.1744 57.9361 48.6847 54.8053C50.195 51.6745 49.3628 44.948 46.8662 39.8023C44.3696 34.6567 40.0544 33.7578 39.8078 36.9195Z" fill="#CED4DA"/>
    <path d="M25.7828 61.7795C25.6595 61.7795 25.567 61.7175 25.5054 61.6555C25.0739 61.1596 25.3821 58.9587 25.6595 58.4318C25.9986 57.7188 27.663 56.5099 28.4027 56.1689C28.711 56.0449 31.1768 55.208 34.0433 54.278C37.6804 53.0691 41.7798 51.7362 42.3038 51.4882C42.7353 50.9613 41.2866 43.7078 39.6222 37.0122C39.6222 36.9812 39.6222 36.9812 39.6222 36.9502C39.6838 35.8653 40.2386 35.1524 41.1325 34.9664C42.7353 34.6564 45.2936 36.2063 47.0196 39.802C49.5471 45.0097 50.3485 51.8292 48.8382 54.991C47.3587 58.0908 33.2419 58.7107 31.7932 58.7417C31.5466 59.0827 30.6528 60.2296 30.0672 60.4776C29.913 60.5706 29.7281 60.5396 29.574 60.4466C29.4199 60.3226 29.3274 60.1366 29.2966 59.9197C29.05 60.3536 28.7418 60.8186 28.4336 60.9736C27.8171 61.2216 27.1698 61.4385 26.5225 61.5625L25.9369 61.7175C25.8444 61.7485 25.8136 61.7795 25.7828 61.7795ZM40.0229 36.9192C40.1154 37.2912 40.9476 40.67 41.6565 44.0797C43.2285 51.4882 42.7045 51.7052 42.4579 51.8292C41.9031 52.0772 37.7728 53.4411 34.1358 54.65C31.3925 55.549 28.8342 56.3859 28.526 56.5409C27.8171 56.8509 26.2451 58.0288 25.9986 58.5867C25.7212 59.2067 25.567 61.1596 25.7828 61.3766C25.9677 61.3146 26.1527 61.2526 26.3684 61.1906C26.9849 61.0356 27.6013 60.8496 28.2178 60.6326C28.4952 60.4776 28.9884 59.6407 29.2349 59.1137C29.2658 59.0207 29.3891 58.9897 29.4815 58.9897C29.574 59.0207 29.6356 59.1137 29.6356 59.2067C29.6048 59.5477 29.6665 59.9816 29.7898 60.0746C29.8206 60.0746 29.8514 60.0746 29.8822 60.0746C30.3446 59.8577 31.2076 58.7727 31.5158 58.3698C31.5466 58.3078 31.6083 58.2768 31.6699 58.2768C31.7008 58.2768 35.5844 58.1218 39.5605 57.6258C46.1566 56.7889 48.0368 55.5799 48.4375 54.743C49.917 51.6742 49.0848 45.0407 46.619 39.926C45.0162 36.5783 42.612 35.0594 41.1942 35.3383C40.5161 35.4623 40.0845 36.0203 40.0229 36.9192Z" fill="#231F20"/>
    <path d="M29.0813 65.0648C28.5265 64.2589 21.3756 52.3867 21.3756 52.3867L4.51562 54.0606L12.7761 66.1498L29.0813 65.0648Z" fill="white"/>
    <path d="M12.8054 66.3669C12.7438 66.3669 12.6821 66.3359 12.6205 66.2739L4.36 54.1848C4.32917 54.1228 4.29835 54.0608 4.32917 53.9678C4.36 53.9058 4.42164 53.8438 4.48329 53.8438L21.3433 52.1699C21.4358 52.1699 21.4974 52.2009 21.5282 52.2629C21.5899 52.3869 28.6791 64.1661 29.2339 64.941C29.2647 65.003 29.2956 65.096 29.2647 65.158C29.2339 65.22 29.1723 65.282 29.0798 65.282L12.8054 66.3669ZM4.91481 54.2468L12.9287 65.964L28.7408 64.91C27.5387 63.0192 22.0214 53.8128 21.2817 52.6349L4.91481 54.2468Z" fill="#231F20"/>
    <path d="M18.6626 59.95C18.416 59.392 18.0153 58.927 17.5222 58.555C17.2139 58.3071 16.8441 58.1521 16.4742 58.1211C16.0735 58.0901 15.7036 58.2451 15.457 58.555C15.3029 58.834 15.2413 59.144 15.3029 59.423C15.3646 60.1359 15.7344 60.7869 16.3201 61.2209C17.368 61.9338 19.4332 61.5618 18.6626 59.95Z" fill="white"/>
    <path d="M17.5216 61.7798C17.0592 61.7798 16.6277 61.6558 16.2578 61.4078C15.6106 60.9428 15.2099 60.2299 15.1482 59.4549C15.0866 59.114 15.179 58.742 15.3332 58.432C15.6106 58.06 16.0729 57.843 16.5352 57.905C16.9668 57.967 17.3675 58.122 17.7065 58.37C18.2305 58.742 18.6312 59.238 18.9086 59.8269C19.3093 60.6949 18.9702 61.1908 18.7237 61.3768C18.323 61.6558 17.9223 61.7798 17.5216 61.7798ZM16.4119 58.308C16.1345 58.308 15.8263 58.432 15.6722 58.68C15.5489 58.897 15.4873 59.176 15.5489 59.4239C15.6106 60.0749 15.9496 60.6949 16.4736 61.0668C17.0284 61.4388 17.9839 61.4698 18.4154 61.0978C18.6928 60.8498 18.7237 60.5089 18.5079 60.0129C18.2613 59.4859 17.8914 59.052 17.4599 58.711C17.1825 58.494 16.8743 58.339 16.5044 58.308H16.4119Z" fill="#231F20"/>
    <path d="M16.5352 59.2676C16.566 59.2676 17.09 60.1045 17.09 60.1045L17.4598 59.2676" fill="white"/>
    <path d="M17.0913 60.3532C17.0297 60.3532 16.968 60.3222 16.9064 60.2602C16.7214 59.9502 16.4749 59.5473 16.4132 59.4543C16.3824 59.4233 16.3516 59.3613 16.3516 59.2993C16.3516 59.1753 16.444 59.0823 16.5673 59.0823C16.6906 59.0823 16.7214 59.0823 17.0605 59.6712L17.2454 59.2063C17.2762 59.1133 17.3995 59.0513 17.5228 59.0823C17.6153 59.1133 17.6769 59.2373 17.6461 59.3613L17.2762 60.1982C17.2454 60.2912 17.1838 60.3222 17.0913 60.3532Z" fill="#231F20"/>
  </svg>
);

ViewTimerBillboard.propTypes = {
  height: oneOfType([ number, string ]),
  width: oneOfType([ number, string ]),
  title: string
};

export default ViewTimerBillboard;
