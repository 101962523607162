import React, { useState } from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import APAccordion from '.';

const imagesArray = [{ src: 'test', alt: 'alt' }, { src: 'test', alt: 'alt2' }, { src: 'test', alt: 'alt3' }];

const TestComponent = () => {
  const [open, setOpen] = useState(false);
  const onToggle = () => setOpen(!open);
  return(
    <APAccordion
      title='Example Title'
      subtitle={<span>Example subtitle</span>}
      images={imagesArray}
      customClassName='custom-class'
      isOpen={open}
      onToggle={onToggle}>
      <div>test item 1</div>
      <div>test item 2</div>
    </APAccordion>  
  );
};

describe('APAccordion Component', () => {
  it('renders with no props', () => {
    render(<APAccordion />);
    expect(screen.getByTestId('ap-accordion-test-id')).toBeVisible();
  });

  it('renders with all props', () => {
    render(<TestComponent/>);
    expect(screen.getByText('Example Title')).toBeVisible();
    expect(screen.getByText('Example subtitle')).toBeVisible();
    expect(screen.getByTestId('ap-accordion-test-id')).toHaveClass('custom-class');

    userEvent.click(screen.getByText('Example Title'));
    expect(screen.getByText('test item 1')).toBeVisible();
    expect(screen.getByText('test item 2')).toBeVisible();
  });

  it('renders open when the prop isOpen is passed with true', () => {
    render(
      <APAccordion
        title='Example Title'
        subtitle={<span>Example subtitle</span>}
        images={imagesArray}
        isOpen={true}>
        <div>test item 1</div>
      </APAccordion>
    );

    expect(screen.getByText('Example Title')).toBeVisible();
    expect(screen.getByText('Example subtitle')).toBeVisible();
    expect(screen.getByTestId('accordion-content')).toHaveClass('active');
    expect(screen.getByText('test item 1')).toBeVisible();
  });

  it('should invoke toggle method when clicked and not disabled', () => {
    const f = jest.fn();
    render(
      <APAccordion title='Example Title' onToggle={f}>
        <div>test item 1</div>
        <div>test item 2</div>
      </APAccordion>
    );

    expect(screen.getByText('Example Title')).toBeVisible();
    expect(screen.getByTestId('accordion-content')).not.toHaveClass('active');

    userEvent.click(screen.getByText('Example Title'));
    expect(f).toBeCalled();
  });

  it('should not invoke toggle method when clicked and disabled', () => {
    const f = jest.fn();
    render(
      <APAccordion title='Example Title' onToggle={f} disabled={true}>
        <div>test item 1</div>
        <div>test item 2</div>
      </APAccordion>
    );

    expect(screen.getByText('Example Title')).toBeVisible();
    expect(screen.getByTestId('accordion-content')).not.toHaveClass('active');

    userEvent.click(screen.getByText('Example Title'));
    expect(f).not.toBeCalled();
  });

  it('render all nested accordions', () => {
    render(
      <APAccordion title='Example Title' isOpen={true}>
        <APAccordion title="Test SubAccordion">
          <div>Item 1</div>
          <div>Item 2</div>
        </APAccordion>
      </APAccordion>
    );

    expect(screen.getByText('Example Title')).toBeVisible();
    expect(screen.getAllByTestId('accordion-content')[0]).toHaveClass('active');
    expect(screen.getByText('Test SubAccordion')).toBeVisible();
    expect(screen.getAllByTestId('accordion-content')[1]).not.toHaveClass('active');
  });
});