export const PCICompliancesMock = {
  'data': [
    {
      'id': '1SSce73VGelOnTsAkUiDyj',
      'type': 'pci_compliance',
      'attributes': {
        'certificate_url': 'https://www.example.com',
        'name': 'Acme Inc.',
        'brand': 'affinipay',
        'next_due_date': '2022-11-20T21:27:12-06:00',
        'status': 'compliant',
        'previous_test_date': null,
        'exempted_at': null,
        'contact_email': 'owner@example.com',
        'mid': '31636510280918'
      }
    }
  ],
  'links': {
    'self': 'http://api-gateway:80/v2/pci-compliances?page[number]=1&page[size]=25',
    'first': 'http://api-gateway:80/v2/pci-compliances?page[number]=1&page[size]=25',
    'last': 'http://api-gateway:80/v2/pci-compliances?page[number]=1&page[size]=25'
  },
  'meta': {
    'page': 0,
    'size': 25,
    'total': 1
  },
  'jsonapi': {
    'version': '1.0'
  }
};