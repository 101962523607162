import React, { useEffect, useState } from 'react';
import { string, number, arrayOf, shape, bool, object } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import EditBeneficialOwner from './EditBeneficialOwner';
import EditLegalEntity from './EditLegalEntity';
import CreateBeneficialOwner from './CreateBeneficialOwner';
import DeleteBeneficialOwnerButton from './DeleteBeneficialOwnerButton';

const LegalEntity = ({
  legalEntity,
  merchantId,
  canEditBeneficialOwners,
  canEditLegalEntities,
  updateHistory
}) => {
  const [currentLegalEntity, setCurrentLegalEntity] = useState(legalEntity);
  const [editedBeneficialOwnerId, setEditedBeneficialOwnerId] = useState();
  const [editedLegalEntityId, setEditedLegalEntityId] = useState();
  const [isCreateOwnerView, setIsCreateOwnerView] = useState(false);
  const [reviewOwnerChanged, setReviewOwnerChanges] = useState(false);

  const {
    id: legalEntityId, legal_business_name, doing_business_as, business_structure, merchant_category_code,  years_in_business,
    address,
    business_address,
    phone_number, email, url, beneficial_owners, tax_id
  } = currentLegalEntity?.attributes || {};

  useEffect(() => {
    const elements = document.getElementsByClassName('hide-self-service');
    const selfServiceEls = document.getElementsByClassName('show-self-service');

    if(reviewOwnerChanged && elements.length) {
      elements[0].className = 'show-self-service';
    } else if(selfServiceEls.length && (isCreateOwnerView || editedBeneficialOwnerId || editedLegalEntityId)) {
      selfServiceEls[0].className = 'hide-self-service';
      setReviewOwnerChanges(false);
    }
  }, [reviewOwnerChanged, isCreateOwnerView, editedBeneficialOwnerId, editedLegalEntityId]);

  return (
    <>
      {isCreateOwnerView && !editedBeneficialOwnerId && !editedLegalEntityId &&
        <CreateBeneficialOwner
          merchantId={merchantId}
          setCurrentLegalEntity={setCurrentLegalEntity}
          setIsCreateOwnerView={setIsCreateOwnerView}
        />
      }
      {editedBeneficialOwnerId && !editedLegalEntityId && !isCreateOwnerView &&
        <EditBeneficialOwner
          merchantId={merchantId}
          beneficialOwnerId={editedBeneficialOwnerId}
          setEditedBeneficialOwnerId={setEditedBeneficialOwnerId}
          setCurrentLegalEntity={setCurrentLegalEntity}
        />
      }
      {editedLegalEntityId && !editedBeneficialOwnerId && !isCreateOwnerView &&
        <EditLegalEntity
          merchantId={merchantId}
          legalEntityId={editedLegalEntityId}
          setEditedLegalEntityId={setEditedLegalEntityId}
          setCurrentLegalEntity={setCurrentLegalEntity}
        />
      }
      {!editedLegalEntityId && !editedBeneficialOwnerId && !isCreateOwnerView &&

        <div>
          <div id={`le-${legalEntityId}`} className='legal-entity'>
            <div className='row'>
              <div className='col-sm-12'>
                <h3 className='legal-entity-name' id={`le-${legalEntityId}-legal-busines-name`}>
                  {legal_business_name}
                  <div className='button-container'>
                    {canEditLegalEntities &&
                      <APButton onClick={() => setEditedLegalEntityId(legalEntityId)}>
                        Edit Legal Entity
                      </APButton>
                    }
                    {(updateHistory && updateHistory.status === 'submitted') && 
                      <APButton 
                        className={`review-owner-changes-btn ${reviewOwnerChanged ? 'ap-secondary-button in-review': 'ap-primary-button review'}`}
                        onClick={() => setReviewOwnerChanges(true)}
                      >
                        {reviewOwnerChanged ? 'In Review' : 'Review'}
                      </APButton>
                    }
                  </div>
                </h3>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-6'>
                <p id={`le-${legalEntityId}-dba`}>Doing business as: {doing_business_as}</p>
                <p id={`le-${legalEntityId}-business-structure`}>{business_structure}</p>
                <p id={`le-${legalEntityId}-tax-id`}>Tax ID: {tax_id?.slice(-4)}</p>
                <p id={`le-${legalEntityId}-mcc`}>MCC: {merchant_category_code}</p>
                <p id={`le-${legalEntityId}-years-in-business`}>Years in business: {years_in_business}</p>
              </div>
              <div className='col-sm-6'>
                { currentLegalEntity.attributes.address && (
                  <>
                    <h4>Registered Address</h4>
                    <p id={`le-${legalEntityId}-address1`}>{address.address1}</p>
                    <p id={`le-${legalEntityId}-address2`}>{address.address2}</p>
                    <p id={`le-${legalEntityId}-city`}>{address.city}, {address.state?.toUpperCase()} {address.zip_code}</p>
                  </>
                )}
                <h4>Business Information</h4>
                { currentLegalEntity.attributes.business_address && (
                  <>
                    <p id={`le-${legalEntityId}-business-address1`}>{business_address.address1}</p>
                    <p id={`le-${legalEntityId}-business-address2`}>{business_address.address2}</p>
                    <p id={`le-${legalEntityId}-business-city`}>{business_address.city}, {business_address.state?.toUpperCase()} {business_address.zip_code}</p>
                  </>
                )}
                <p id={`le-${legalEntityId}-phone-number`}>{phone_number}</p>
                <p id={`le-${legalEntityId}-email`}>{email}</p>
                <p id={`le-${legalEntityId}-url`}>{url}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-6'>
                <h4 className='beneficial-owner-title'>
                  <span>Beneficial Owners</span>
                  {canEditBeneficialOwners &&
                    <APButton onClick={() => setIsCreateOwnerView(true)}>Create</APButton>
                  }
                </h4>
                {beneficial_owners?.map((be) => {
                  const { id: beneficialOwnerId, given_name, surname } = be || {};
                  return (
                    <div key={beneficialOwnerId} id={`bo-${beneficialOwnerId}`} className='beneficial-owner-row'>
                      <span id={`bo-${beneficialOwnerId}-name`} className='beneficial-owner-name'>
                        {given_name} {surname}
                      </span>
                      <span>
                        {canEditBeneficialOwners &&
                          <APButton onClick={() => setEditedBeneficialOwnerId(beneficialOwnerId)}>Edit</APButton>
                        }
                        {canEditBeneficialOwners && beneficial_owners.length > 1 &&
                          <DeleteBeneficialOwnerButton
                            merchantId={merchantId}
                            beneficialOwnerId={be.id}
                            beneficialOwnerName={be.given_name}
                            beneficialOwnerSurname={be.surname}
                            setCurrentLegalEntity={setCurrentLegalEntity}
                          />
                        }
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          
        </div>
      }
    </>
  );
};

export default LegalEntity;

LegalEntity.propTypes = {
  legalEntity: shape({
    id: string,
    type: string,
    attributes: shape({
      id: number,
      merchant_id: number,
      phone_number: string,
      url: string,
      email: string,
      legal_business_name: string,
      doing_business_as: string,
      merchant_category_code: string,
      years_in_business: number,
      business_structure: string,
      address: shape({
        address1: string,
        address2: string,
        city: string,
        zip_code: string
      }),
      business_address: shape({
        address1: string,
        address2: string,
        city: string,
        zip_code: string
      }),
      beneficial_owners: arrayOf(shape({
        id: number,
        given_name: string,
        surname: string
      }))
    })
  }),
  merchantId: number,
  canEditBeneficialOwners: bool,
  canEditLegalEntities: bool,
  updateHistory: object
};
