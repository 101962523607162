import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const AcceptingPaymentsBillboard = ({
  height=87,
  width=88,
  title='Accepting Payments'
}) => (
  <svg width={width} height={height} viewBox="0 0 88 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M81.6096 31.8381H6.33451L0.994258 2.98071C0.770416 1.79479 1.69776 0.665342 3.0728 0.467689C3.20071 0.439453 3.32862 0.439453 3.45653 0.439453H84.5515C85.9266 0.439453 87.0458 1.42772 87.0458 2.64188C87.0458 2.75482 87.0458 2.86777 87.0138 2.98071L81.6096 31.8381Z" fill="#231F20"/>
    <path d="M81.6089 32.0362H6.33381C6.23787 32.0362 6.14194 31.9797 6.10996 31.8667L0.769717 3.00934C0.513896 1.71047 1.53718 0.468077 3.00814 0.270423C3.16803 0.242187 3.29594 0.242188 3.45583 0.242188H84.5508C86.0538 0.242188 87.2689 1.31516 87.2689 2.64227C87.2689 2.78345 87.2689 2.89639 87.2369 3.03757L81.8967 31.895C81.8008 31.9797 81.7368 32.0362 81.6089 32.0362ZM6.52567 31.6409H81.417L86.7573 2.95286C86.9492 1.85165 86.1177 0.835148 84.9026 0.665731C84.7747 0.637495 84.6468 0.637493 84.5189 0.637493H3.42385C2.17673 0.637493 1.15345 1.51282 1.15345 2.61403C1.15345 2.72697 1.15345 2.83992 1.18542 2.95286L6.52567 31.6409Z" fill="#231F20"/>
    <path d="M80.2338 72.7246H7.70878C6.10991 72.7246 4.79883 71.5669 4.79883 70.1551C4.79883 70.1269 4.79883 70.0986 4.79883 70.0704L6.36572 32.0645H81.5769L83.1438 70.0704C83.2077 71.4822 81.9606 72.6964 80.3617 72.7528C80.2977 72.7246 80.2658 72.7246 80.2338 72.7246Z" fill="white"/>
    <path d="M80.2343 72.8944H7.70924C5.98246 72.8944 4.60742 71.6802 4.60742 70.1555C4.60742 70.1273 4.60742 70.0708 4.60742 70.0426L6.17432 32.0366C6.17432 31.9519 6.27025 31.8672 6.36618 31.8672H81.5773C81.6733 31.8672 81.7692 31.9519 81.7692 32.0366L83.3361 70.0426C83.4 71.5673 82.057 72.8379 80.3622 72.8944C80.3302 72.8944 80.2663 72.8944 80.2343 72.8944ZM6.55805 32.2343L4.99115 70.0708C4.9272 71.3979 6.11037 72.4991 7.61331 72.5273C7.64529 72.5273 7.67727 72.5273 7.70924 72.5273H80.2343C81.7372 72.5273 82.9524 71.4544 82.9524 70.1273C82.9524 70.099 82.9524 70.0708 82.9524 70.0426L81.3855 32.206L6.55805 32.2343Z" fill="#231F20"/>
    <path d="M76.332 29.6356H11.6095C10.7141 29.6356 9.94667 29.0709 9.78678 28.2803L5.18202 4.95717C4.99015 4.05361 5.69366 3.20653 6.68496 3.06535C6.78089 3.03711 6.9088 3.03711 7.00474 3.03711H80.9688C81.992 3.03711 82.8234 3.77125 82.8234 4.67481C82.8234 4.75952 82.8234 4.87246 82.7915 4.95717L78.1867 28.2803C78.0268 29.0709 77.2594 29.6356 76.332 29.6356Z" fill="white"/>
    <path d="M76.3339 29.8054H11.6114C10.6201 29.8054 9.75672 29.1842 9.56485 28.3089L4.96009 4.9858C4.76823 3.99753 5.50371 3.06573 6.62292 2.86808C6.75083 2.83984 6.84677 2.83984 6.97468 2.83984H80.9387C82.0899 2.83984 82.9853 3.65869 82.9853 4.6752C82.9853 4.78814 82.9853 4.87285 82.9533 4.9858L78.3485 28.3089C78.2206 29.1842 77.3572 29.8054 76.3339 29.8054ZM7.00665 3.20692C6.11128 3.20692 5.34382 3.85635 5.34382 4.6752C5.34382 4.75991 5.34382 4.84462 5.3758 4.92932L9.98056 28.2524C10.1085 28.9583 10.812 29.4666 11.6114 29.4666H76.3659C77.1653 29.4666 77.8689 28.9583 77.9968 28.2524L82.6015 4.92932C82.7614 4.13871 82.1538 3.37633 81.2585 3.23515C81.1625 3.20691 81.0666 3.20692 80.9707 3.20692H7.00665Z" fill="#231F20"/>
    <path d="M55.6755 68.4322H32.2679C30.8289 68.4322 29.6777 67.4157 29.6777 66.145L30.1254 56.7989C30.1254 55.5565 31.2446 54.54 32.6516 54.5117H55.3237C56.7307 54.54 57.8499 55.5565 57.8499 56.7989L58.2976 66.145C58.2656 67.4157 57.1145 68.4322 55.6755 68.4322ZM32.6197 54.907C31.4685 54.9353 30.5411 55.7823 30.5731 56.7989L30.1254 66.145C30.1254 67.1898 31.0847 68.0369 32.2679 68.0369H55.6755C56.8586 68.0369 57.818 67.1898 57.818 66.145L57.3703 56.7989C57.4022 55.7823 56.4749 54.9353 55.2917 54.907H32.6197Z" fill="#D1D3D4"/>
    <path d="M75.4044 50.8979H12.5366C11.4493 50.8979 10.5859 50.1355 10.5859 49.1755V49.1472L10.9057 36.6386C10.9377 35.7068 11.8011 34.9727 12.8563 34.9727H75.1166C76.1718 34.9727 77.0352 35.7068 77.0672 36.6386L77.387 49.1472C77.419 50.1073 76.5556 50.8696 75.5003 50.8979C75.4683 50.8979 75.4364 50.8979 75.4044 50.8979ZM12.8244 35.368C12.0249 35.368 11.3534 35.9327 11.3214 36.6668L11.0016 49.1755C10.9697 49.9096 11.6412 50.5026 12.4726 50.5308H12.5046H75.4044C76.2358 50.5308 76.9073 49.9379 76.9073 49.2037V49.1755L76.5876 36.6668C76.5556 35.9609 75.916 35.3962 75.0846 35.368H12.8244Z" fill="#D1D3D4"/>
    <path d="M18.5488 6.99023H22.546C24.1769 6.99023 25.4879 7.78085 25.7118 9.13619C25.9356 10.4915 24.9443 11.1974 23.3455 11.1974H21.2989L21.6826 13.0045H19.8919L18.5488 6.99023ZM22.9617 9.89857C23.5373 9.89857 23.953 9.64444 23.8571 9.10795C23.7612 8.57146 23.2815 8.34557 22.6739 8.34557H20.7233L21.0431 9.89857H22.9617Z" fill="#D1D3D4"/>
    <path d="M31.2125 12.1584H27.7909L27.5031 13.0054H25.6484L27.8869 6.96289H29.6456L33.6428 13.0054H31.7881L31.2125 12.1584ZM30.4131 10.8877L29.0061 8.6853H28.9741L28.2066 10.8877H30.4131Z" fill="#D1D3D4"/>
    <path d="M34.9211 10.7457L31.4355 6.99023H33.642L35.7525 9.44678H35.7845L37.4473 6.99023H39.6538L36.8078 10.7457L36.9996 13.0045H35.2089L34.9211 10.7457Z" fill="#D1D3D4"/>
    <path d="M40.1348 6.99023H42.2133L44.8035 11.028H44.8355L47.3617 6.99023H49.4402L49.2484 13.0045H47.4576L47.5216 9.44678L45.1872 13.0045H44.5157L42.1494 9.44678H42.1174L42.2133 13.0045H40.4226L40.1348 6.99023Z" fill="#D1D3D4"/>
    <path d="M50.75 6.99023H56.5059L56.378 8.37381H52.5727L52.5087 9.41855H55.9303L55.8344 10.6609H52.4448L52.3808 11.7339H56.0902L55.9623 13.0045H50.4941L50.75 6.99023Z" fill="#D1D3D4"/>
    <path d="M57.5626 6.99023H59.5453L62.2633 10.8868H62.2953L62.8709 6.99023H64.7576L63.7983 13.0328H61.8796L59.2255 9.24913H59.1935L58.7458 13.0045H56.9551L57.5626 6.99023Z" fill="#D1D3D4"/>
    <path d="M67.9234 8.37381H65.3652L65.5891 6.99023H72.6881L72.3683 8.37381H69.7781L68.8188 13.0045H67.0281L67.9234 8.37381Z" fill="#D1D3D4"/>
    <path d="M69.3943 18.2293H18.55C18.4221 18.2293 18.3262 18.1446 18.3262 18.0316C18.3262 17.9187 18.4221 17.834 18.55 17.834H69.3623C69.4902 17.834 69.5861 17.9187 69.5861 18.0316C69.6181 18.1446 69.5222 18.2293 69.3943 18.2293Z" fill="#231F20"/>
    <path d="M58.041 81.2229L56.1224 67.6696C56.1224 67.6696 57.6893 56.2904 56.1224 50.389C54.5555 44.4876 47.5844 39.2922 45.25 38.1909C44.9942 38.078 43.843 38.558 43.6192 38.6427C42.6279 38.9533 41.6685 39.3486 40.7732 39.8286C38.8865 40.7887 36.9998 41.9181 35.8806 43.6123C34.9533 44.9959 34.6974 46.6618 34.7294 48.2713C32.5869 48.4972 31.4677 50.9819 32.3631 52.5914C33.4503 54.5115 37.7993 60.2717 37.6394 60.9493C37.4795 61.627 37.8952 67.1331 41.5726 71.3685L41.2528 82.663L58.041 81.2229Z" fill="white"/>
    <path d="M41.2837 82.8612C41.2198 82.8612 41.1878 82.833 41.1238 82.8048C41.0599 82.7765 41.0599 82.72 41.0599 82.6636L41.3797 71.4256C37.7342 67.1901 37.2546 61.7123 37.4464 60.8935C37.4784 60.4981 35.6557 57.8157 34.4086 56.0368C33.4812 54.6815 32.5858 53.4109 32.1701 52.6767C31.6585 51.6884 31.7224 50.559 32.33 49.599C32.7457 48.8366 33.5771 48.2719 34.5045 48.1024C34.4725 46.2953 34.8562 44.7706 35.6877 43.5282C36.8069 41.8058 38.7575 40.6763 40.6442 39.6881C41.5715 39.208 42.5308 38.7845 43.5541 38.4739C43.6181 38.4457 43.682 38.4174 43.8099 38.3892C44.6733 38.0786 45.121 37.9374 45.3449 38.0504C47.935 39.2645 54.7782 44.5447 56.3132 50.3896C57.8161 56.1215 56.4091 67.1054 56.3132 67.6984L58.2318 81.2235C58.2318 81.28 58.2318 81.3365 58.1998 81.3647C58.1679 81.3929 58.1039 81.4212 58.0399 81.4494L41.2837 82.8612ZM45.153 38.3892C44.7693 38.4739 44.3855 38.5868 44.0018 38.7563C43.8739 38.8127 43.746 38.841 43.714 38.8692C42.7227 39.1798 41.7954 39.5751 40.9 40.0551C39.0453 41.0152 37.1586 42.1164 36.1034 43.7541C35.3039 44.9682 34.9202 46.493 34.9841 48.3001C34.9841 48.413 34.9202 48.4978 34.7923 48.4978C33.8969 48.6107 33.1294 49.1189 32.7457 49.8249C32.2021 50.6719 32.1701 51.6602 32.5858 52.5355C33.0015 53.2414 33.8649 54.5403 34.7923 55.8674C36.9668 59.0299 37.99 60.5546 37.8621 61.0064C37.7022 61.6841 38.1819 67.1336 41.7634 71.2561C41.7954 71.2844 41.8273 71.3409 41.7954 71.3691L41.4756 82.4377L57.7521 81.0259L55.8655 67.6984C55.8655 67.6701 55.8655 67.6701 55.8655 67.6419C55.8655 67.529 57.4004 56.2345 55.8655 50.4461C54.3945 44.7706 47.7112 39.5751 45.153 38.3892Z" fill="#231F20"/>
    <path d="M28.6221 18.1992L21.3632 41.7765C20.8196 43.4989 21.9708 45.2495 23.9214 45.7295L47.361 51.3485C49.3116 51.8286 51.2942 50.8121 51.8378 49.1179C51.8378 49.1179 51.8378 49.1179 51.8378 49.0896L61.3671 18.1992H28.6221Z" fill="white"/>
    <path d="M61.334 18.2L51.8047 49.0904C51.389 50.5022 49.918 51.4623 48.2872 51.4623C47.9674 51.4623 47.6476 51.434 47.3278 51.3493L23.9203 45.7303C21.9697 45.2785 20.8185 43.4996 21.3621 41.7772L28.621 18.2H61.334ZM61.334 17.8047H28.621C28.4291 17.8047 28.2373 17.9176 28.1733 18.087L20.9464 41.6925C20.3708 43.6126 21.6499 45.5891 23.8244 46.1256L47.2639 51.7446C49.4384 52.2811 51.6768 51.1234 52.2844 49.2034L61.7817 18.3412C61.8137 18.2847 61.8137 18.2565 61.8137 18.2C61.8137 17.9741 61.6218 17.8047 61.334 17.8047Z" fill="#231F20"/>
    <path d="M44.9939 18.2001L44.4822 19.866C44.0985 21.1084 44.9299 22.3791 46.3369 22.7179L52.2528 24.1297C53.6598 24.4685 55.0988 23.7344 55.4825 22.492L56.6337 18.7931C56.6977 18.5954 56.7296 18.3978 56.6977 18.1719L44.9939 18.2001Z" fill="#1A73E8"/>
    <path d="M49.1498 37.5992C50.0132 37.7968 50.5248 38.5874 50.301 39.3498L48.0945 46.4653C47.8707 47.2277 46.9753 47.6795 46.1119 47.4818C45.2485 47.2842 44.7369 46.4936 44.9607 45.7312L47.1672 38.6157C47.391 37.8251 48.2864 37.3733 49.1498 37.5992Z" fill="#D1D3D4"/>
    <path d="M34.6328 18.1992C34.7287 18.3404 34.8886 18.4251 35.0805 18.4816L36.7433 18.8769C37.191 18.9898 37.6387 18.7639 37.7346 18.3686L37.7986 18.1992H34.6328Z" fill="#D1D3D4"/>
    <path d="M34.6656 19.9217L36.3284 20.317C36.7761 20.4299 37.0319 20.8252 36.904 21.1923L35.2412 26.5572C35.1133 26.9525 34.6656 27.1784 34.2499 27.0654L32.587 26.6701C32.1393 26.5572 31.8835 26.1619 32.0114 25.7948L33.6743 20.4299C33.7702 20.0346 34.2179 19.8087 34.6656 19.9217Z" fill="#D1D3D4"/>
    <path d="M32.1382 28.0837L33.8011 28.479C34.2488 28.592 34.5046 28.9873 34.3767 29.3543L32.7138 34.7192C32.5859 35.1145 32.1382 35.3404 31.7225 35.2275L30.0597 34.8322C29.612 34.7192 29.3562 34.3239 29.4841 33.9568L31.1469 28.592C31.2748 28.2249 31.7225 27.9708 32.1382 28.0837Z" fill="#D1D3D4"/>
    <path d="M29.6128 36.2713L31.2757 36.6666C31.7234 36.7795 31.9792 37.1748 31.8513 37.5419L30.1884 42.9068C30.0605 43.3021 29.6128 43.528 29.1971 43.415L27.5343 43.0197C27.0866 42.9068 26.8308 42.5115 26.9587 42.1444L28.6215 36.7795C28.7494 36.3842 29.1971 36.1583 29.6128 36.2713Z" fill="#D1D3D4"/>
    <path d="M40.6134 65.6363C40.6134 65.6363 40.6134 61.5138 41.2209 56.4595C41.2209 56.4595 36.4243 45.0803 37.9592 42.8779C39.4941 40.6755 42.1483 42.059 43.5553 44.1203C44.9623 46.1815 49.599 55.923 49.599 55.923" fill="white"/>
    <path d="M40.6123 65.835C40.4844 65.835 40.3885 65.7503 40.3885 65.6373C40.3885 65.6091 40.3885 61.4866 40.996 56.4888C40.6123 55.6135 36.2314 44.9967 37.7663 42.7942C38.3419 42.0036 39.0774 41.5801 39.9408 41.5801H39.9728C41.2519 41.5801 42.7228 42.5401 43.7461 44.0366C45.1531 46.0696 49.63 55.4441 49.8218 55.8394C49.8538 55.9523 49.7899 56.0653 49.6939 56.0935C49.598 56.1217 49.4701 56.0935 49.4061 55.9806C49.3741 55.8958 44.7374 46.2391 43.3624 44.206C42.435 42.8507 41.1239 41.9754 39.9728 41.9471H39.9408C39.2373 41.9471 38.6297 42.286 38.15 42.9637C36.9349 44.6861 40.1007 53.2134 41.4117 56.3759C41.4117 56.4041 41.4117 56.4323 41.4117 56.4606C40.8042 61.4584 40.8042 65.5809 40.8042 65.6373C40.8361 65.722 40.7402 65.8068 40.6123 65.835Z" fill="#231F20"/>
    <path d="M39.0458 57.8726C38.9499 57.8726 38.886 57.8444 38.854 57.7597C38.7261 57.562 35.912 53.2137 35.0806 51.576C34.569 50.5877 34.4091 49.4865 34.6649 48.4417C34.6969 48.3288 34.8248 48.2723 34.9527 48.3288C35.0487 48.357 35.1126 48.47 35.1126 48.5547C34.8888 49.5429 35.0487 50.5312 35.5283 51.4348C36.3278 53.0442 39.2377 57.5338 39.2697 57.5903C39.3336 57.675 39.3017 57.8162 39.2057 57.8726C39.2057 57.8726 39.2057 57.8726 39.1738 57.8726C39.1098 57.8726 39.0778 57.8726 39.0458 57.8726Z" fill="#231F20"/>
    <path d="M38.1823 60.0752C37.8625 60.0752 37.5747 59.9905 37.3189 59.8493C36.9671 59.6517 36.6793 59.3976 36.4235 59.1434C36.3276 59.0587 36.3276 58.9458 36.4235 58.8611C36.5194 58.7764 36.6474 58.7764 36.7433 58.8611C36.9671 59.1152 37.2549 59.3129 37.5427 59.5105C37.7985 59.6799 38.1503 59.7082 38.4701 59.6517C38.8538 59.4823 39.1096 59.1152 39.1096 58.7481C39.1096 58.3246 38.9817 57.9011 38.7899 57.5057C38.7579 57.3928 38.7898 57.2799 38.9178 57.2516C39.0137 57.2234 39.1416 57.2516 39.1736 57.3363C39.3654 57.6187 39.7172 57.8163 40.1009 57.8446C40.2928 57.8446 40.4847 57.7599 40.6126 57.6469C40.7085 57.5622 40.7725 57.4493 40.7725 57.3363C40.7725 57.2234 40.8684 57.1387 40.9963 57.1387C41.1242 57.1387 41.2201 57.2234 41.2201 57.3363C41.2201 57.5622 41.1242 57.7881 40.9323 57.9575C40.7085 58.1552 40.4207 58.2681 40.1009 58.2964C39.8771 58.2964 39.6532 58.2399 39.4614 58.1552C39.5253 58.3811 39.5573 58.6352 39.5573 58.8611C39.5573 59.3976 39.2056 59.9058 38.63 60.1317C38.4701 60.047 38.3422 60.0752 38.1823 60.0752Z" fill="#231F20"/>
    <path d="M60.534 86.5598H38.6934L39.8446 75.8301H58.9032L60.534 86.5598Z" fill="#231F20"/>
    <path d="M60.534 86.7579H38.6613C38.5974 86.7579 38.5334 86.7296 38.5015 86.7014C38.4695 86.6732 38.4375 86.6167 38.4375 86.5602L39.5887 75.8022C39.5887 75.6893 39.6846 75.6328 39.8125 75.6328H58.8392C58.9671 75.6328 59.031 75.7175 59.063 75.8022L60.7258 86.5602C60.7258 86.6167 60.7258 86.6732 60.6619 86.7297C60.6939 86.7297 60.6299 86.7579 60.534 86.7579ZM38.9172 86.3626H60.2782L58.6793 75.9999H40.0364L38.9172 86.3626Z" fill="#231F20"/>
  </svg>
);

AcceptingPaymentsBillboard.propTypes = {
  height: oneOfType([ number, string ]),
  width: oneOfType([ number, string ]),
  title: string
};

export default AcceptingPaymentsBillboard;
