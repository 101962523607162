export const titles = [
  {text: 'President', value: 'president'},
  {text: 'V.President', value: 'vice_president'},
  {text: 'Owner', value: 'owner'},
  {text: 'Partner', value: 'partner'},
  {text: 'Manager', value: 'manager'},
  {text: 'Treasurer', value: 'treasurer'},
  {text: 'Secretary', value: 'secretary'},
  {text: 'Exec.Director', value: 'executive_director'}
];

export const identificationTypes = [
  {text: 'Social Security', value: 'us_social_security'},
  {text: 'Drivers License', value: 'us_driver_license'},
  {text: 'Passport', value: 'passport'},
  {text: 'Visa', value: 'visa'}
];

export const businessStructures = [
  {value: 'sole_proprietor', text: 'Sole Proprietor'},
  {value: 'association_estate_trust', text: 'Assoc / Estate / Trust'},
  {value: 'government', text: 'Government'},
  {value: 'private_corporation', text: 'Private Corporation'},
  {value: 'public_corporation', text: 'Public Corporation'},
  {value: 'partnership', text: 'Partnership'},
  {value: 'tax_exempt', text: 'Tax Exempt'},
  {value: 'single_member_llc', text: 'Single Member LLC'},
  {value: 'multiple_member_llc', text: 'Multiple Member LLC'},
  {value: 'civic_association', text: 'Civic Association'},
  {value: 'limited_partnership', text: 'Limited Partnership'},
  {value: 'political_organization', text: 'Political Organization'}
];

export const defaultBeneficialOwnerFormState = {
  phone_number: '',
  email: '',
  identification: {},
  citizenship: '',
  significant_ownership: false,
  surname: '',
  date_of_birth: null,
  significant_management: false,
  address: {
    type: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: ''
  },
  title: '',
  given_name: '',
  years_at_residence: ''
};

export const beneficialOwnerPlaceholderData = {
  address1: '1234 Fake st',
  address2: '',
  zip_code: '00000',
  phone_number: '5555555555',
  date_of_birth: new Date(0o001, 0, 1),
  email: 'fakemail@fake.com',
  social_security_number: '000000000'
};