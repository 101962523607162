import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import mixpanel from 'mixpanel-browser';
import { dataFormatter } from '../../lib/server';
import { allCardsArray } from './bannerCards';
import NewFeaturesModal from './NewFeaturesModal';
import EnableNowModal from './EnableNowModal';
import LearnMoreModal from './LearnMoreModal';
import './style.scss';
import APAccordion from './NLEComponents/APAccordion';
import APCarousel from './NLEComponents/APCarousel';
import APCard from './NLEComponents/APCard';
import ChevronRight from './assets/ChevronRight';
import { newFeaturesModalBodies } from './NewFeaturesModal/cardModalDetails';
import NewLawPayExperienceService from './NewLawPayExperienceService';
import getEnv, { Errors } from './NewLawPayExperienceService/environment';
import { rollbarLog } from '../../lib/utils';

const whatsNewURL = 'https://affinipay-1.wistia.com/medias/v4osko6a7z';
const paymentCards = ['paymentPages', 'acceptingPayments', 'savedPayments', 'adminSettings', 'whatsNew'];
const invoicingCards = ['paymentPages', 'acceptingPayments', 'savedPayments', 'quickBills', 'invoicing', 'adminSettings', 'whatsNew'];

const responsiveCarouselBreakpoints = [
  {
    breakpoint: 1450,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1100,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 980,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 725,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  
  {
    breakpoint: 528,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true
    }
  }
];

const CardToDisplay = ({
  card,
  style,
  className='card-slide',
  handleClick
}) => {
  const {billboard: Billboard, buttonName, buttonTestId, buttonText, description, titleValues} = card;
  return (
    <div key={card.buttonName} className={className} style={style}>
      <APCard
        billboard={<Billboard />}
        buttonName={buttonName}
        buttonTestId={buttonTestId}
        buttonText={buttonText}
        description={description}
        titleValues={titleValues}
        handleButtonClick={handleClick}
      />
    </div>
  );
};

const AccordionTitleWithButton = ({handleClick}) => (
  <div className="nle-banner-title-wrapper">
    <h2 className="nle-banner-title-text" />
    <APButton
      dataset={{ 'data-testid': 'nle-banner-header-enable-button'}}
      className="ap-primary-button nle-banner-title-action" 
      type="button" 
      onClick={handleClick}
    >
      <ChevronRight /> Enable Now
    </APButton>
  </div>
);

const filterModalBodiesByBanner = bannerType => {
  const newFeatureModalBodyNames = Object.keys(newFeaturesModalBodies);
  let bodiesToDisplay = [];

  if (bannerType === 'invoicing') bodiesToDisplay = invoicingCards;
  if (bannerType === 'payments') bodiesToDisplay = paymentCards;

  const bodyFilter = bodyName => bodiesToDisplay.indexOf(bodyName) >= 0;

  const output = newFeatureModalBodyNames.filter(bodyFilter);
  return output;
};

const initialFeatureModalValue = {
  description: '',
  instructions: [],
  FeatureView: <span></span>
};

const generateEnableNowBody = (mid, hasVTEntitlement) => {
  const updates = [
    {
      entitlement: 'lawpay_plus',
      action: 'add'
    }
  ];
  
  if (hasVTEntitlement) {
    updates.push({
      entitlement: 'vt',
      action: 'remove'
    });
  }

  return ({
    type: 'product_entitlement_updates',
    merchant_id: mid,
    updates
  });
};

const buildMixPanelDetails = ({
  browser, 
  browserVersion, 
  device, 
  distinctId, 
  integrations, 
  os, 
  product,
  userRoleOrType,
  userUuid,
  sfMasterFirmId,
  sfMasterUserId
}) => ({
  '$browser': browser,
  '$browser_version': browserVersion,
  '$device': device,
  'distinct_id': distinctId,
  'Integrations': integrations,
  '$os': os,
  'Product': product,
  'User Role or Type': userRoleOrType,
  'User UUID': userUuid,
  'SF Master Firm ID': sfMasterFirmId,
  'SF Master User ID': sfMasterUserId
});

const NewLawPayExperienceBanner = ({
  bannerType,
  hasVtEntitlement,
  isAdminOrOwner,
  merchantId,
  mixpanelValues,
  supportPhone,
  userEmail,
  userName
}) => {
  useEffect(() => {
    if(!mixpanelValues.token) {
      rollbarLog('Mix Panel', 'Missing Mix Panel Token', 'warning');
    }
  
    mixpanel.init(mixpanelValues.token || '71eab42e0fefe991af600ce348e72f97');

    mixpanel.identify(merchantId);
    mixpanel.people.set({
      '$email': userEmail,
      '$name': userName,
      banner: bannerType 
    });
  }, []);

  const [showAccordionContent, setShowAccordionContent] = useState(true);
  const [showEnableNowModal, setShowEnableNowModal] = useState(false);
  const [showNewFeaturesModal, setShowNewFeaturesModal] = useState(false);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [enableNowErrorMessage, setEnableNowErrorMessage] = useState([]);
  const [newFeaturesModalValues, setNewFeaturesModalValues] = useState(initialFeatureModalValue);
  const [isSaving, setIsSaving] = useState(false);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [slideFeatureCards, setSlidFeatureCards] = useState({ 
    cards: filterModalBodiesByBanner(bannerType),
    currentSlideIndex: 0,
    nextSlideIndex: 1
  });
  const mixpanelPayloadValues = buildMixPanelDetails(mixpanelValues);
  
  const filterCardsByBanner = bannerType => {
    let cardsToDisplay =[];
    
    if (bannerType === 'invoicing') cardsToDisplay = invoicingCards;
    if (bannerType === 'payments') cardsToDisplay = paymentCards;

    const cardFilter = card => cardsToDisplay.indexOf(card.buttonName) >= 0;

    return allCardsArray.filter(cardFilter);
  };

  const cardButtonClick = ({currentTarget: { name, value }}) => {
    mixpanel.track('NLE Banner Card Click', { 
      'Feature Type': value, ...mixpanelPayloadValues });

    if (name === 'whatsNew') {
      window.open(whatsNewURL, '_blank');
      return;
    }

    const currentSlideIndex = slideFeatureCards.cards.indexOf(name);
    const nextSlideIndex = currentSlideIndex + 1;
    
    setShowPreviousButton(currentSlideIndex !== 0);
    setSlidFeatureCards(prev => ({ ...prev, currentSlideIndex, nextSlideIndex }));
    setNewFeaturesModalValues(newFeaturesModalBodies[name]);
    setShowNewFeaturesModal(true);
  };
  
  const onEnableNowClick = event => {
    event.stopPropagation();
    toggleEnableNowModal();  
  };

  const toggleEnableNowModal = () => {
    mixpanel.track(`NLE Enablement Modal ${!showEnableNowModal ? 'Opened' : 'Closed'}`, {...mixpanelPayloadValues});

    setShowEnableNowModal(!showEnableNowModal);
  };
  
  const toggleAccordion = () => setShowAccordionContent(!showAccordionContent);
  
  const handleEnableNowClick = async () => {    
    setIsSaving(true);
    const environment = getEnv();
    const enableNowPayload = dataFormatter.serialize({ stuff: generateEnableNowBody(merchantId, hasVtEntitlement)});
    const response = await NewLawPayExperienceService.postLawPayProEnablement(enableNowPayload, environment, supportPhone);
    setIsSaving(false);

    if (response.status !== 200) {
      setEnableNowErrorMessage(Errors[response.status](supportPhone));
    } else {
      mixpanel.track('NLE Pro Enablement Confirmed', {...mixpanelPayloadValues});
      window.location.reload();
    }
  };

  const handleSlideFeatureProgression = () => {
    const { cards, currentSlideIndex, nextSlideIndex} = slideFeatureCards;
    const nextFeature = cards[nextSlideIndex];
    const currentFeature = cards[currentSlideIndex];
    const currentSlideName = newFeaturesModalBodies[currentFeature].descriptionTitle;
    const nextSlideName = newFeaturesModalBodies[nextFeature].descriptionTitle;
    
    if (currentSlideIndex === 0) setShowPreviousButton(true);

    mixpanel.track('NLE Feature Next Button Clicked', { 
      'Current Slide': currentSlideName,
      'Next Slide': nextSlideName,
      ...mixpanelPayloadValues
    });

    setSlidFeatureCards(prev => ({
      ...prev, 
      currentSlideIndex: prev.currentSlideIndex + 1, 
      nextSlideIndex: prev.nextSlideIndex + 1
    }));

    setNewFeaturesModalValues(newFeaturesModalBodies[nextFeature]);
  };

  const handleSlideFeatureRegression = () => {
    const { cards, currentSlideIndex } = slideFeatureCards;
    const previousFeature = cards[currentSlideIndex - 1];

    if ((currentSlideIndex - 1) === 0) setShowPreviousButton(false);
    const currentFeature = cards[currentSlideIndex];
    const currentSlideName = newFeaturesModalBodies[currentFeature].descriptionTitle;
    const previousSlideName = newFeaturesModalBodies[previousFeature].descriptionTitle;
    
    mixpanel.track('NLE Feature Previous Button Clicked', { 
      'Current Slide': currentSlideName,
      'Previous Slide': previousSlideName,
      ...mixpanelPayloadValues
    });

    setSlidFeatureCards(prev => ({
      ...prev,
      currentSlideIndex: prev.currentSlideIndex - 1,
      nextSlideIndex: prev.nextSlideIndex -1
    }));

    setNewFeaturesModalValues(newFeaturesModalBodies[previousFeature]);
  };

  const handleWhatsNewClick = () => {
    mixpanel.track('NLE Feature Slide See What\'s New Button Clicked', { ...mixpanelPayloadValues });

    window.open(whatsNewURL, '_blank');
    
    return;
  };

  const toggleNewFeaturesModal = () => setShowNewFeaturesModal(!showNewFeaturesModal);
  
  const lastSlideCheck = (cards, nextSlideIndex) => cards.length - nextSlideIndex === 0;

  const toggleLearnMoreClick = () => {
    mixpanel.track(`NLE Learn Modal ${!showLearnMoreModal ? 'Opened' : 'Closed'}`, { ...mixpanelPayloadValues });
    setShowLearnMoreModal(!showLearnMoreModal);
  };
  
  return (
    <>
      <div className="nle-banner-wrapper">
        <APAccordion 
          name="nle-banner-accordion" 
          title={<AccordionTitleWithButton handleClick={onEnableNowClick} />}
          onToggle={toggleAccordion} 
          isOpen={showAccordionContent}
          dropDownStyle={true}
        >
          <div className="nle-banner-description" data-testid="nle-banner-description">
            <span>The LawPay you love with added invoicing, time entries, expense tracking functionality. <span className="nle-banner-description-bold">Same Payments, More Features</span> to revolutionize your practice.</span>
          </div>
          <APCarousel
            sliderShading="#f0f8ff"
            slidesToShow={5}
            responsiveValues={responsiveCarouselBreakpoints}
          >
            {filterCardsByBanner(bannerType).map(card => (
              <CardToDisplay
                key={card.buttonName}
                card={card}
                style={{ width: 113 }}
                handleClick={cardButtonClick}
              />)
            )}
          </APCarousel>
          <div className="nle-banner-learn-more">
            <APButton 
              type='button'
              className="ap-ghost-button"
              onClick={toggleLearnMoreClick}
              dataset={{'data-testid': 'nle-learn-more-button'}}
            >
                Learn More
            </APButton>
          </div>
        </APAccordion>
      </div>
      {showEnableNowModal && (
        <EnableNowModal 
          errorMessages={enableNowErrorMessage}
          isAdminOrOwner={isAdminOrOwner}
          isSaving={isSaving}
          handleEnableClick={handleEnableNowClick}
          toggleModal={toggleEnableNowModal}
        />
      )}
      {showNewFeaturesModal && (
        <NewFeaturesModal 
          {...newFeaturesModalValues}
          showPreviousButton={showPreviousButton}
          toggleModal={toggleNewFeaturesModal}
          handleSlideFeatureProgression={handleSlideFeatureProgression}
          handleSlideFeatureRegression={handleSlideFeatureRegression}
          handleWhatsNewClick={handleWhatsNewClick}
          isLastSlide={lastSlideCheck(slideFeatureCards.cards, slideFeatureCards.nextSlideIndex)}
          mixpanelPayloadValues={mixpanelPayloadValues}
        />
      )}
      {showLearnMoreModal && (
        <LearnMoreModal 
          toggleModal={toggleLearnMoreClick}
        />
      )}
    </>
  );
};

CardToDisplay.propTypes = {
  card: shape({
    billboard: func,
    buttonName: string,
    buttonTestId: string,
    buttonText: string,
    description: string,
    titleValues: arrayOf(string)
  }),
  style: object,
  className: string,
  handleClick: func.isRequired
};

AccordionTitleWithButton.propTypes = {
  handleClick: func.isRequired
};

NewLawPayExperienceBanner.propTypes = {
  hasVtEntitlement: bool,
  isAdminOrOwner: bool, 
  bannerType: string,
  merchantId: string,
  merchantName: string,
  mixpanelValues: shape({
    browser: string,
    browserVersion: string,
    device: string,
    distinctId: string,
    integrations: arrayOf(number),
    os: string,
    product: string,
    sfMasterUserId: string,
    sfMasterFirmId: string,
    token: string,
    userUuid: string,
    userRoleOrType: string
  }),
  supportPhone: string,
  userEmail: string,
  userName: string
};

export default NewLawPayExperienceBanner;