/* eslint-disable no-undef */
import React from 'react';
import {array} from 'prop-types';
import RecentTransactionRow from './recent_transaction_row';
import NoTransactionsRow from './no_transaction_row';

const RecentTransactionsTable = ({ transactions }) => {
  return (
    <div className="row clearfix bottom-spacer-20">
      <div className="col-sm-8">
        <h3 className="dashboard-title recent-transactions-title">Recent Transactions</h3>
      </div>
      <div className="col-sm-4">
        <a href="/transactions" className="ap-btn--secondary pull-right">All Transactions</a>
      </div>

      <div className="col-xs-12">
        <div className="table-responsive">
          <table className="table table-striped table-condensed transactions">
            <thead>
              <tr>
                <th>Date</th>
                <th>Payor Name</th>
                <th>Payor Email</th>
                <th className="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                transactions.length > 0 ? (
                  transactions.map((t, idx) => <RecentTransactionRow transaction={t} key={idx}/>)
                ) : (
                  <NoTransactionsRow key={0} />
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default RecentTransactionsTable;

RecentTransactionsTable.propTypes = {
  transactions: array
};
