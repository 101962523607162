import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const EnableNowBillboard = ({
  className='enable-now-billboard-svg',
  height = 207,
  width = 288,
  title = 'Enable Now Billboard'
}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px" 
    viewBox="150 100 200 310"
    className={className}
    height={height}
    width={width}
  >
    <title>{title}</title>
    <g>
      <path fill="#FFFFFF" d="M174.1,124.3h111.7v70.8c0,2.1-1.7,3.8-3.8,3.8H177.9c-2.1,0-3.8-1.7-3.8-3.8l0,0L174.1,124.3L174.1,124.3z" />
      <path fill="231F20" d="M280.5,199.5h-101c-3.3,0-6-2.7-6-6v-69.3c0-0.4,0.3-0.6,0.6-0.6h111.7c0.4,0,0.6,0.3,0.6,0.6l0,0v69.3
      C286.4,196.8,283.8,199.5,280.5,199.5z M174.8,124.9v68.6c0,2.6,2.1,4.7,4.7,4.7h101c2.6,0,4.7-2.1,4.7-4.7v-68.6H174.8z" />
      <path fill="#FFFFFF" d="M177.9,114.4H282c2.1,0,3.8,1.7,3.8,3.8v6.1H174.1v-6.1C174.1,116.1,175.8,114.4,177.9,114.4L177.9,114.4z" />
      <path fill="231F20" d="M285.8,124.9H174.1c-0.4,0-0.6-0.3-0.6-0.6v-4.6c0-3.3,2.7-6,6-6h101c3.3,0,6,2.7,6,6v4.6
      C286.5,124.6,286.2,124.9,285.8,124.9L285.8,124.9z M174.8,123.6h110.4v-4c0-2.6-2.1-4.7-4.7-4.7h-101c-2.6,0-4.7,2.1-4.7,4.7
      V123.6z" />
      <circle fill="#68E1FD" cx="201.6" cy="144.8" r="7.2" />
      <path fill="#68E1FD" d="M188.5,166.6c0-7.2,5.9-13.1,13.1-13.1s13.1,5.9,13.1,13.1H188.5z" />
      <ellipse fill="#68E1FD" transform="matrix(0.4642 -0.8857 0.8857 0.4642 10.1494 306.3883)" cx="258.3" cy="144.8" rx="7.2" ry="7.2" />
      <path fill="#68E1FD" d="M245.2,166.6c0-7.2,5.9-13.1,13.1-13.1c7.2,0,13.1,5.9,13.1,13.1H245.2z" />
      <path fill="231F20" d="M242.2,145.4h-16.8c-0.4,0-0.6-0.3-0.6-0.6c0-0.4,0.3-0.6,0.6-0.6l0,0h15.3l-3.3-3.5c-0.2-0.3-0.2-0.7,0.1-0.9
      c0.2-0.2,0.6-0.2,0.8,0l4.4,4.6c0.2,0.2,0.2,0.5,0.1,0.7C242.7,145.3,242.5,145.4,242.2,145.4L242.2,145.4z" />
      <path fill="231F20" d="M238,149.5c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.2-0.3-0.6,0-0.9l0,0l4.2-4.1c0.3-0.2,0.7-0.2,0.9,0.1
      c0.2,0.2,0.2,0.6,0,0.8l-4.2,4.1C238.3,149.5,238.1,149.5,238,149.5L238,149.5z" />
      <path fill="231F20" d="M234.3,150.8h-16.8c-0.3,0-0.5-0.2-0.6-0.4s-0.1-0.5,0.1-0.7l4.4-4.6c0.2-0.3,0.6-0.3,0.9-0.1s0.3,0.6,0.1,0.9
      l-0.1,0.1l-3.3,3.5h15.3c0.4,0,0.6,0.3,0.6,0.6C234.9,150.6,234.6,150.8,234.3,150.8L234.3,150.8z" />
      <path fill="231F20" d="M221.7,154.9c-0.2,0-0.3-0.1-0.4-0.2l-4.2-4.1c-0.2-0.3-0.2-0.7,0-0.9s0.6-0.3,0.9,0l0,0l4.2,4.1
      c0.2,0.2,0.3,0.6,0,0.9l0,0C222,154.9,221.8,154.9,221.7,154.9L221.7,154.9z" />
      <path fill="#D1D3D4" d="M263.2,175h-66.6c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7l0,0h66.6c0.4,0,0.7,0.3,0.7,0.7S263.6,175,263.2,175
      C263.3,175,263.3,175,263.2,175z" />
      <path fill="#D1D3D4" d="M263.2,179.8h-66.6c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h66.6c0.4,0,0.7,0.3,0.7,0.7
      S263.6,179.8,263.2,179.8C263.3,179.8,263.3,179.8,263.2,179.8z" />
      <path fill="#D1D3D4" d="M256.4,184.5h-59.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7l0,0h59.7c0.4,0,0.7,0.3,0.7,0.7
      S256.8,184.5,256.4,184.5L256.4,184.5L256.4,184.5z" />
      <circle fill="#68E1FD" cx="182" cy="119.3" r="1.7" />
      <circle fill="#D1D3D4" cx="188" cy="119.3" r="1.7" />
      <circle fill="#D1D3D4" cx="194" cy="119.3" r="1.7" />
      <path fill="#FFFFFF" d="M303.1,117.9h144.1V290c0,2.8-2.2,5-5,5H308.1c-2.8,0-5-2.2-5-5V117.9z" />
      <path fill="231F20" d="M440.2,295.6H310.1c-4.3,0-7.7-3.4-7.7-7.7v-170c0-0.4,0.3-0.7,0.7-0.7l0,0h144.1c0.4,0,0.7,0.3,0.7,0.7l0,0
      V288C447.9,292.2,444.4,295.6,440.2,295.6L440.2,295.6z M303.8,118.6V288c0,3.5,2.8,6.3,6.3,6.3h130.1c3.5,0,6.3-2.8,6.3-6.3V118.6
      H303.8z" />
      <path fill="231F20" d="M417.8,280.9h-85.4c-3.8,0-6.8-3.1-6.8-6.8v-4c0-3.8,3.1-6.8,6.8-6.8h85.4c3.8,0,6.8,3.1,6.8,6.8v4
      C424.6,277.9,421.6,280.9,417.8,280.9z M332.5,264.6c-3,0-5.4,2.4-5.4,5.4v4c0,3,2.4,5.4,5.4,5.4h85.4c3,0,5.4-2.4,5.4-5.4v-4
      c0-3-2.4-5.4-5.4-5.4H332.5z" />
      <rect fill="#68E1FD" x="323.7" y="164.5" width="11.4" height="82.3" />
      <rect fill="#68E1FD" x="344.8" y="183.8" width="11.4" height="63.1" />
      <rect fill="#68E1FD" x="365.8" y="171.2" width="11.4" height="75.6" />
      <rect fill="#68E1FD" x="386.9" y="194" width="11.4" height="52.8" />
      <rect fill="#68E1FD" x="408" y="171.2" width="11.4" height="75.6" />
      <path fill="231F20" d="M419.2,132.8h-19.3c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h19.3c0.4,0,0.7,0.3,0.7,0.7
      C419.9,132.5,419.5,132.8,419.2,132.8L419.2,132.8z" />
      <path fill="231F20" d="M412.4,140h-12.6c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7l0,0h12.6c0.4,0,0.7,0.3,0.7,0.7S412.8,140,412.4,140
      L412.4,140z" />
      <path fill="#D1D3D4" d="M394,172.1c-6.1,0-11.4-6.8-15.7-12.3c-2.8-3.6-5.4-6.9-7.2-6.9l0,0c-2,0.1-4.7,1.6-7.6,3.3
      c-3.9,2.3-8.4,4.9-12.6,4.6c-7.9-0.4-22.6-17.8-23.2-18.5c-0.2-0.3-0.1-0.7,0.2-0.9c0.2-0.1,0.5-0.1,0.8,0.1
      c0.1,0.2,15,17.6,22.3,18c3.9,0.2,8.1-2.3,11.9-4.5c3-1.8,5.9-3.4,8.3-3.5s5,3.2,8.3,7.4c4.2,5.4,9.4,12,15,11.8
      c4.9-0.2,7.2-4.5,9.6-9c2.4-4.3,4.8-8.8,9.7-9.7c0.4-0.1,0.7,0.2,0.8,0.5c0.1,0.4-0.2,0.7-0.5,0.8c-4.3,0.8-6.5,4.8-8.8,9
      c-2.5,4.6-5.1,9.4-10.8,9.6h-0.4L394,172.1z" />
      <path fill="#D1D3D4" d="M429.2,247.5H314.4c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h114.8c0.4,0,0.7,0.3,0.7,0.7
      S429.6,247.5,429.2,247.5L429.2,247.5z" />
      <path fill="#D1D3D4" d="M328.4,144.4c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6l0,0C331,143.2,329.8,144.4,328.4,144.4z
      M328.4,140.5c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2l0,0C329.7,141.1,329.1,140.5,328.4,140.5z" />
      <path fill="#D1D3D4" d="M413.9,155.3c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6l0,0
      C416.4,154.1,415.3,155.3,413.9,155.3z M413.9,151.5c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2l0,0
      C415.1,152,414.5,151.5,413.9,151.5L413.9,151.5z" />
      <path fill="#FFFFFF" d="M308.1,103.4h134.1c2.8,0,5,2.2,5,5v9.5H303.1v-9.5C303.1,105.6,305.3,103.4,308.1,103.4z" />
      <path fill="231F20" d="M447.2,118.6H303.1c-0.4,0-0.7-0.3-0.7-0.7l0,0v-7.5c0-4.3,3.4-7.7,7.7-7.7h130.1c4.3,0,7.7,3.4,7.7,7.7v7.5
      C447.9,118.3,447.6,118.6,447.2,118.6L447.2,118.6L447.2,118.6z M303.8,117.2h142.7v-6.8c0-3.5-2.8-6.3-6.3-6.3H310.1
      c-3.5,0-6.3,2.8-6.3,6.3L303.8,117.2L303.8,117.2z" />
      <path fill="#FFFFFF" d="M56.5,189.9h99.4c2,0,3.7,1.6,3.7,3.7v123.8c0,2-1.6,3.7-3.7,3.7H56.5c-2,0-3.7-1.6-3.7-3.7V193.6
      C52.8,191.6,54.5,189.9,56.5,189.9z" />
      <path fill="231F20" d="M154.4,321.7H58c-3.2,0-5.8-2.6-5.8-5.8V195.1c0-3.2,2.6-5.8,5.8-5.8h96.4c3.2,0,5.8,2.6,5.8,5.8v120.8
      C160.3,319.1,157.6,321.7,154.4,321.7L154.4,321.7z M58,190.6c-2.5,0-4.4,2-4.4,4.4l0,0v120.8c0,2.5,2,4.4,4.4,4.4l0,0h96.4
      c2.5,0,4.4-2,4.4-4.4l0,0V195.1c0-2.5-2-4.4-4.4-4.4l0,0H58V190.6z" />
      <path fill="#D1D3D4" d="M132.6,293.3H75.1c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h57.5c0.4,0,0.7,0.3,0.7,0.7
      S133,293.3,132.6,293.3z" />
      <path fill="#D1D3D4" d="M104.8,302.7H75.1c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h29.7c0.4,0,0.7,0.3,0.7,0.7
      C105.6,302.4,105.2,302.7,104.8,302.7C104.9,302.7,104.9,302.7,104.8,302.7z" />
      <path fill="#68E1FD" d="M78.7,219.4l25.1,25.1c0.3,0.3,0.6,0.4,1,0.4c12.6,0,25.2,0,37.8,0h0.6c-0.7,20.5-17.9,36.5-38.4,35.8
      S68.3,262.8,69,242.3c0.3-8.6,3.6-16.8,9.2-23.2L78.7,219.4L78.7,219.4z" />
      <path fill="#D1D3D4" d="M107.5,206.5v35.8c0.1,0,0.2,0,0.4,0l34.7-0.1c0.8,0,0.8,0,0.7-0.8c-0.9-13.9-9.4-26.1-22.1-31.8
      c-3.8-1.6-7.9-2.7-12-3L107.5,206.5z" />
      <path fill="#D1D3D4" d="M104.8,241.8v-35.3c-4.2,0.2-8.3,1.1-12.2,2.6c-4.7,1.8-8.9,4.6-12.5,8.1L104.8,241.8z" />
      <path fill="#FFFFFF" d="M379.6,383.1h-50.8c-3,0-5.4-2.4-5.4-5.4v-56.1c0-3,2.4-5.4,5.4-5.4h44.9l11.3,11v50.5
      C384.9,380.7,382.5,383.1,379.6,383.1L379.6,383.1z" />
      <path fill="231F20" d="M379.6,383.8h-50.8c-3.3,0-6.1-2.7-6.1-6.1v-56.1c0-3.3,2.7-6.1,6.1-6.1h44.9c0.2,0,0.4,0.1,0.5,0.2l11.3,11
      c0.1,0.1,0.2,0.3,0.2,0.5v50.5C385.6,381.1,382.9,383.8,379.6,383.8L379.6,383.8z M328.8,317c-2.6,0-4.7,2.1-4.7,4.7l0,0v56.1
      c0,2.6,2.1,4.7,4.7,4.7l0,0h50.8c2.6,0,4.7-2.1,4.7-4.7l0,0v-50.2l-11-10.6H328.8z" />
      <circle fill="#68E1FD" cx="311.8" cy="110.8" r="2.4" />
      <circle fill="#D1D3D4" cx="320" cy="110.8" r="2.4" />
      <circle fill="#D1D3D4" cx="328.1" cy="110.8" r="2.4" />
      <path fill="#D1D3D4" d="M373.2,147.7c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.3-0.7-0.1-0.9l6-7.2c0.1-0.2,0.3-0.2,0.5-0.2h15.1
      c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7l0,0h-14.8l-5.9,6.9C373.6,147.6,373.4,147.7,373.2,147.7L373.2,147.7z" />
      <polygon fill="#68E1FD" points="373.6,316.3 373.6,327.6 384.9,327.3 	">
      </polygon>
      <path fill="231F20" d="M373.6,328.4c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-11.4c0-0.4,0.3-0.7,0.7-0.7c0.2,0,0.4,0.1,0.5,0.2
      l11.3,11c0.2,0.2,0.3,0.5,0.2,0.8c-0.1,0.3-0.3,0.4-0.6,0.5L373.6,328.4L373.6,328.4z M374.3,317.9v9l9-0.3L374.3,317.9z" />
      <path fill="#68E1FD" d="M113.3,345.9h16.6c1.5,0,2.7,1.2,2.7,2.7v16.6c0,1.5-1.2,2.7-2.7,2.7h-16.6c-1.5,0-2.7-1.2-2.7-2.7v-16.6
      C110.6,347.1,111.8,345.9,113.3,345.9z" />
      <path fill="#FFFFFF" d="M109.1,338.8H126c1.4,0,2.6,1.1,2.6,2.6v16.9c0,1.4-1.1,2.6-2.6,2.6h-16.9c-1.4,0-2.6-1.1-2.6-2.6v-16.9
      C106.5,339.9,107.7,338.8,109.1,338.8z" />
      <path fill="231F20" d="M125,361.5h-14.9c-2.4,0-4.3-1.9-4.3-4.3v-14.9c0-2.4,1.9-4.3,4.3-4.3H125c2.4,0,4.3,1.9,4.3,4.3v14.9
      C129.3,359.6,127.4,361.5,125,361.5L125,361.5z M110.1,339.5c-1.6,0-2.9,1.3-2.9,2.9l0,0v14.9c0,1.6,1.3,2.9,2.9,2.9l0,0H125
      c1.6,0,2.9-1.3,2.9-2.9l0,0v-14.9c0-1.6-1.3-2.9-2.9-2.9l0,0H110.1z" />
      <path fill="#68E1FD" d="M351.1,370.6v-3.3c-1.2-0.3-2.4-0.6-3.5-1c-2.2-0.7-4.1-2.1-5.4-4c-1.1-1.7-1.6-3.6-1.5-5.6h6.5
      c0.1,2.3,1.7,4.3,4,4.8v-8.8c-1.3-0.4-2.6-0.7-3.9-1.1c-1.4-0.4-2.7-1.1-3.8-2.1c-1.1-1.1-1.8-2.5-2.1-4.1
      c-0.4-1.9-0.2-3.8,0.4-5.6c1-2.6,3-4.7,5.7-5.6c1.1-0.3,2.2-0.6,3.3-0.8l0.4-0.1v-4.4h6v0.4c0,1.2,0,2.5,0,3.8
      c0,0.3,0.1,0.4,0.4,0.5c2.1,0.4,4,1.3,5.6,2.6c1.7,1.4,2.9,3.4,3.2,5.6c0.1,0.6,0.1,1.2,0.2,1.8H360c0-1.8-1.1-3.5-2.8-4.2v8.2
      l0.7,0.2c1.8,0.4,3.5,0.9,5.1,1.6c2.5,1.1,4.2,3.5,4.5,6.2c0.4,2.5-0.2,5.1-1.7,7.1c-1.2,1.7-2.9,2.9-4.9,3.6
      c-1.2,0.4-2.5,0.7-3.8,1.1v3.2L351.1,370.6L351.1,370.6z M357.2,354v7.6c1-0.1,1.9-0.6,2.7-1.2c1.4-1.1,1.9-4-0.1-5.3
      C358.9,354.7,358.1,354.3,357.2,354z M351.1,346.2v-7.4c-0.8,0.3-1.6,0.7-2.3,1.2c-1.7,1.1-1.9,4.2,0.2,5.3
      C349.7,345.6,350.4,345.9,351.1,346.2L351.1,346.2z" />
      <rect fill="#D1D3D4" x="86.7" y="133.2" width="61.7" height="36.7">
      </rect>
    </g>
    <g id="Character-2">
      <path fill="#FFFFFF" d="M216,222.8c-8.2-2.5-12.6-12.1-14.8-19.8c-2.5-8.7-5.4-15.9-1.9-24.9c0.8-2.1,2.1-4.3,4.2-4.9
      c0.5-0.1,1-0.3,1.5-0.5c0.4-0.4,0.6-0.8,0.8-1.3c5-11.8,19.2-12.1,29.8-10.5c6.2,1,14.2,3.8,16.1,10.1c3.5,11.7,0.6,24.4-5.9,34.4
      C239.8,214.9,228.3,226.5,216,222.8L216,222.8z" />
      <path fill="231F20" d="M220.7,224.2c-1.7,0-3.3-0.2-4.9-0.7l0,0c-8.6-2.6-13.1-12.4-15.3-20.3c-0.3-1-0.6-2-0.9-2.9
      c-2.2-7.6-4.2-14.2-1-22.5c1.1-3,2.7-4.7,4.7-5.3l0.4-0.1c0.3,0,0.6-0.1,0.8-0.3c0.2-0.3,0.4-0.6,0.5-0.9
      c5.2-12.3,19.5-12.7,30.6-11c4.2,0.7,14.4,3,16.6,10.6c3.4,11.1,1.2,23.9-6,35C240.4,215.2,230.9,224.2,220.7,224.2L220.7,224.2z
      M226.9,160.8c-8.4,0-16.8,2.4-20.4,10.8c-0.2,0.6-0.5,1.2-1,1.6c-0.4,0.3-0.9,0.5-1.4,0.6c-0.1,0-0.2,0-0.4,0.1
      c-1.5,0.4-2.8,1.9-3.8,4.4c-3,7.8-1.1,14.2,1,21.6l0.9,2.9c2.1,7.5,6.3,16.9,14.3,19.3l0,0c11.2,3.4,22.4-6.6,29.1-17.1
      c6.9-10.8,9.1-23.1,5.8-33.8c-2-6.8-11.5-9-15.5-9.6C232.7,161.1,229.8,160.9,226.9,160.8z" />
      <path fill="#FFFFFF" d="M253.4,199.2c0,0,8.9,11.1,7.5,13.6s-6.9,1.4-6.9,1.4" />
      <path fill="231F20" d="M256.6,215.2c-0.9,0-1.9-0.1-2.8-0.3c-0.4-0.1-0.6-0.5-0.5-0.8l0,0c0.1-0.4,0.4-0.6,0.8-0.6
      c1.4,0.3,5.2,0.6,6.1-1.1c0.7-1.4-3.3-7.7-7.4-12.9c-0.3-0.3-0.3-0.7,0-1s0.7-0.3,1,0l0.1,0.1c2.1,2.7,9,11.6,7.5,14.4
      C260.6,214.8,258.5,215.2,256.6,215.2L256.6,215.2z" />
      <path fill="#FFFFFF" d="M250.4,183.8c5.3,3.6,4.7,12.3,5.1,17.8c0.7,7.8,1.1,15.7,1.4,23.5c0.2,5-12.6,6.2-15,5l3.8,19.4l-27.9-7.9
      l-1.2-22.7c0,0,8.5-0.7,12.6-11.2s2.5-26,2.5-26S245.2,180.3,250.4,183.8L250.4,183.8z" />
      <path fill="231F20" d="M245.6,250.1c-0.1,0-0.1,0-0.2,0l-27.9-7.9c-0.3-0.1-0.5-0.3-0.5-0.6l-1.2-22.7c0-0.4,0.3-0.7,0.6-0.7
      s8.1-0.8,12-10.8c4-10.3,2.4-25.6,2.4-25.7c0-0.2,0-0.4,0.1-0.5s0.3-0.2,0.5-0.2c0.6,0,13.9-1.3,19.2,2.3l0,0
      c4.7,3.2,5,9.9,5.2,15.3c0,1.1,0.1,2.1,0.2,3c0.6,7.8,1.1,15.7,1.4,23.5c0,1-0.4,2.5-2.4,3.8c-3.3,2-9.4,2.7-12.4,2.2l3.6,18.3
      c0.1,0.4-0.2,0.7-0.5,0.8C245.7,250.1,245.7,250.1,245.6,250.1z M218.4,241l26.3,7.4l-3.6-18.3c-0.1-0.3,0.1-0.5,0.3-0.7
      s0.5-0.2,0.8-0.1c1.6,0.8,8.8,0.4,12.2-1.8c1.2-0.8,1.8-1.6,1.8-2.5c-0.3-7.8-0.8-15.7-1.4-23.5c-0.1-0.9-0.1-2-0.2-3.1
      c-0.2-5.1-0.5-11.4-4.6-14.2l0,0c-4.3-2.9-14.8-2.3-17.6-2.1c0.3,3.2,1.1,16.2-2.6,25.7c-3.6,9.2-10.3,11.2-12.5,11.6L218.4,241
      L218.4,241z" />
      <path fill="#FFFFFF" d="M256.2,219.3c0,0-7.7,0-9.5-10.4" />
      <path fill="231F20" d="M256.2,220c-0.1,0-8.3-0.1-10.2-10.9c-0.1-0.4,0.2-0.7,0.6-0.8c0.4-0.1,0.7,0.2,0.8,0.6
      c1.7,9.6,8.5,9.8,8.8,9.8c0.4,0,0.7,0.3,0.7,0.7S256.6,220,256.2,220L256.2,220L256.2,220z" />
      <path fill="#FFFFFF" d="M238.9,205.5c-0.2-1.9-1.5-3.6-2.7-5.2c-0.9-1.2-1.9-2.4-3.1-3.3c-1.2-0.9-2.8-1.3-4.3-0.9
      c-1.3,0.5-2.3,1.4-2.8,2.7s-0.7,2.6-0.6,4c0.3,6,3.8,11.4,9.1,14.1c-0.2-0.1-0.3-0.3-0.5-0.4" />
      <path fill="231F20" d="M234.4,217.7c-0.1,0-0.2,0-0.3-0.1c-5.6-2.9-9.2-8.5-9.5-14.8c-0.1-1.5,0.1-2.9,0.7-4.3
      c0.6-1.5,1.8-2.6,3.3-3.1c1.7-0.4,3.5-0.1,4.9,1c1.3,1,2.4,2.1,3.3,3.4c1.1,1.5,2.6,3.3,2.8,5.5c0,0.4-0.3,0.7-0.7,0.7
      s-0.7-0.2-0.7-0.6c-0.2-1.8-1.4-3.4-2.5-4.8c-0.8-1.2-1.8-2.2-3-3.1c-1.1-0.8-2.4-1.1-3.7-0.8c-1.1,0.4-2,1.2-2.4,2.3
      c-0.5,1.2-0.7,2.4-0.6,3.7c0.3,5.4,3.2,10.3,7.8,13.1c0.2,0,0.4,0,0.6,0.2l0.5,0.4c0.3,0.3,0.3,0.7,0.1,1
      C234.8,217.6,234.6,217.7,234.4,217.7L234.4,217.7z" />
      <path fill="231F20" d="M241.9,230.1c0,0-6.8-1.5-10.4-5.7c0,0,1.2,10.4,11.7,12.9L241.9,230.1L241.9,230.1z" />
      <path fill="231F20" d="M243.2,237.9c-0.1,0-0.1,0-0.2,0c-10.9-2.6-12.3-13.4-12.3-13.5c0-0.3,0.1-0.6,0.4-0.7c0.3-0.1,0.6,0,0.8,0.2
      c3.3,4,9.9,5.5,9.9,5.5c0.3,0.1,0.5,0.3,0.5,0.6l1.4,7.1c0,0.2,0,0.5-0.2,0.6C243.6,237.8,243.4,237.9,243.2,237.9L243.2,237.9z
      M232.8,226.5c1.4,4.6,5,8.3,9.6,9.7l-1.1-5.6C238.2,229.9,235.3,228.5,232.8,226.5L232.8,226.5z" />
      <ellipse fill="231F20" transform="matrix(7.706902e-02 -0.997 0.997 7.706902e-02 35.7835 427.0072)" cx="248.5" cy="194.2" rx="2.8" ry="1.1"/>
      <path fill="#FFFFFF" d="M175.6,287.9l-14.4,25.2l-37.4-38.4c0,0,3-16.3-1-15c-2.5,0.9-4.8,2.6-6.4,4.7c0,0-3.3-22.5-12-15
      s-4.7,20.3,1.9,32.7c4.9,9.2,48.6,76.5,60.7,73.8s40.9-63.8,40.9-63.8L175.6,287.9L175.6,287.9z" />
      <path fill="231F20" d="M166.4,356.8c-13.8,0-56.2-66-60.6-74.3c-5.5-10.4-11.7-25.1-1.7-33.6c1.8-1.6,3.6-2,5.3-1.4
      c4.6,1.7,6.9,11.5,7.6,15.3c1.6-1.7,3.5-3,5.7-3.8c0.6-0.2,1.3-0.1,1.8,0.3c2.3,2,0.7,12.3,0.2,15.1l36.5,37.5l13.9-24.4
      c0.1-0.2,0.4-0.4,0.7-0.4l32.3,4.2c0.2,0,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0,0.6c-1.1,2.5-29,61.5-41.3,64.2
      C166.9,356.8,166.7,356.8,166.4,356.8L166.4,356.8z M107.8,248.6c-1.1,0.1-2.2,0.6-2.9,1.4c-7,5.9-6.3,16.1,2.1,31.9
      c5.3,10,48.7,76,59.9,73.5c10.9-2.4,36.4-55.1,40-62.6l-30.9-4.1l-14.1,24.8c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0-0.4,0-0.6-0.2
      l-37.4-38.4c-0.2-0.2-0.2-0.4-0.2-0.6c1-5.2,1.7-13,0.3-14.2c-0.1,0-0.2-0.1-0.4,0c-2.4,0.9-4.5,2.4-6,4.5
      c-0.2,0.2-0.5,0.4-0.7,0.3c-0.3-0.1-0.5-0.3-0.5-0.6c-0.6-3.9-2.9-14.2-7-15.8C108.4,248.8,108.1,248.7,107.8,248.6L107.8,248.6z" />
      <path fill="#FFFFFF" d="M285.1,276.6c0,0,29-7.5,39.8-13.9c11.9-7,34.3-51.2,41.6-62.2c3.9-6.1,6.9-12.7,8.7-19.8
      c0.9-3.1,1.2-5.4-0.3-8.4c-0.3-0.6-5.5-6.2-5.7-5.6c1.3-3.9,2.9-7.7,4.3-11.6c0.4-1.1,0.7-2.4-0.1-3.2c-0.8-0.6-1.8-0.6-2.5,0
      s-1.3,1.3-1.7,2.1l-11.9,20.7c0.1-2.5-0.4-5.1-1.5-7.4c-0.2-0.5-0.5-0.9-0.9-1.1c-1.3-0.7-2.6,1-3,2.5c-2.1,7.4-0.6,16.4-5.1,23
      c-3.5,5-34.3,41.9-34.3,41.9l-27.2,7.1" />
      <path fill="231F20" d="M285.1,277.3c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.8,0.5-0.9c0.3-0.1,29-7.5,39.6-13.8
      c8.6-5.1,23.2-30.7,32.9-47.7c3.5-6.1,6.5-11.4,8.4-14.3c3.9-6,6.8-12.6,8.6-19.6c0.8-2.9,1.2-5.1-0.3-8c-1.5-1.9-3.2-3.6-5-5.2
      c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.6-0.5-0.4-0.9l0,0l0,0c0.9-2.5,1.8-5,2.7-7.4c0.5-1.4,1.1-2.8,1.6-4.2c0.5-1.2,0.5-2.1,0-2.5
      c-0.5-0.3-1.2-0.3-1.7,0.1c-0.6,0.5-1.1,1.2-1.5,1.9L357.6,175c-0.2,0.3-0.5,0.4-0.8,0.3c-0.3-0.1-0.5-0.4-0.5-0.7
      c0.1-2.4-0.4-4.8-1.4-7.1c-0.1-0.3-0.3-0.6-0.6-0.8c-0.2-0.1-0.4-0.1-0.5,0c-0.7,0.5-1.2,1.2-1.4,2.1c-0.7,2.8-1.1,5.8-1.3,8.7
      c-0.5,5.1-1,10.3-3.9,14.5c-3.5,5-33.1,40.5-34.4,42c-0.1,0.1-0.2,0.2-0.4,0.2l-27.2,7.1c-0.4,0.1-0.7-0.1-0.8-0.5l0,0
      c-0.1-0.4,0.1-0.8,0.5-0.9l27-7c2.3-2.8,30.9-37,34.1-41.7c2.8-4,3.2-9,3.7-13.9c0.2-3,0.7-6,1.4-8.9c0.3-1.3,1.1-2.4,2.2-3
      c0.6-0.2,1.2-0.2,1.7,0.1s1,0.9,1.2,1.5c0.8,1.7,1.3,3.4,1.5,5.2l10.7-18.6c0.5-0.9,1.1-1.7,1.9-2.4c1-0.8,2.4-0.7,3.4,0.1
      c0.9,0.8,1,2.2,0.3,4c-0.5,1.4-1,2.8-1.6,4.2c-0.8,2.2-1.7,4.4-2.5,6.7c2,1.7,3.8,3.6,5.4,5.6c1.7,3.3,1.2,5.8,0.4,9
      c-1.8,7.1-4.8,13.9-8.8,20c-1.9,2.9-4.9,8.1-8.4,14.2c-10.3,18.1-24.5,42.9-33.4,48.2C314.3,269.6,286.3,276.8,285.1,277.3
      c-0.1-0.1-0.1-0.1-0.2-0.1L285.1,277.3z" />
      <path fill="231F20" d="M292,397.3l-9-41.2l-90.4,24.3c0,0,0.2,6.8,0.4,16.9L292,397.3L292,397.3z" />
      <path fill="#68E1FD" d="M310.2,230.8c0,0-102.8-2.2-115.8,16.2c-10.2,15.3-18.7,31.7-25.4,48.8l18.5,17.3c-0.1,24.4,1.2,48.9,3.7,73.1
      c31.9,5.6,97.8-19.9,97.3-20.1l-8.4-85.4l39.3-12.1L310.2,230.8L310.2,230.8z" />
      <path fill="231F20" d="M186.3,306.7L186.3,306.7c-0.4,0-0.7-0.3-0.7-0.7l0,0c0.1-1.5,1.2-38,6.2-50.7c0.1-0.4,0.5-0.5,0.9-0.4
      s0.5,0.5,0.4,0.9l0,0c-4.9,12.5-6.1,49.9-6.1,50.3C187,306.4,186.7,306.7,186.3,306.7z" />
      <path fill="231F20" d="M266,285.1c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0.2-0.6,0.5-0.7l29.1-8.3c0.4-0.1,0.7,0.2,0.8,0.6
      c0.1,0.3-0.1,0.7-0.4,0.8l-29,8.2C266.1,285,266.1,285,266,285.1L266,285.1z" />
    </g>
  </svg>  
);

EnableNowBillboard.propTypes = {
  className: string,
  height: oneOfType([ number, string ]),
  width: oneOfType([ number, string ]),
  title: string
};

export default EnableNowBillboard;
