import React from 'react';
import './style.scss';
import image1 from './quick-bill-feature-image-1.jpg';
import image2 from './quick-bill-feature-image-2.jpg';
import FeatureBar from '../FeatureBar';

const QuickBillFeatureView = () => (
  <div className="quick-bill-feature-view-wrapper">
    <div className="quick-bill-feature-bar">
      <FeatureBar highlightedFeature='quickBills' />
    </div>
    <div className="quick-bill-feature-image-1">
      <img 
        src={image1} 
        alt="Quick Bill Page UI View"
      />
    </div>
    <div className="quick-bill-feature-image-2">
      <img 
        src={image2} 
        alt="Quick Bill Page Contact UI View"
      />
    </div>
    <div className="quick-bills-feature-marker-1">1</div>
    <div className="quick-bills-feature-marker-2">2</div>
    <div className="quick-bills-feature-marker-3">3</div>
  </div>
);

export default QuickBillFeatureView;
