import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import APCarousel, { buildWrapperClass, responsiveFilter} from './';

const numberArray = [1, 2, 3, 4, 5, 6];
const responsiveValues = [
  {
    breakpoint: 1450,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1100,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 780,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true
    }
  }
];

describe('responsiveFilter', () => {
  it('returns the target value for a specific screen size', async () => {
    jest.spyOn(document.body, 'getBoundingClientRect').mockImplementation(() => ({
      x: 0,
      y: 0,
      width: 550,
      height: 1000,
      top: 0,
      right: 550,
      bottom: 1000,
      left: 0
    }));

    expect(responsiveValues.find(responsiveFilter)).toStrictEqual(expect.objectContaining({
      breakpoint: 600,
      settings: { slidesToShow: 1, slidesToScroll: 1, centerMode: true }
    }));

    jest.restoreAllMocks();
  });
});

describe('buildWrapperClass', () => {
  it('returns the base class if "shouldRemoveButtons" is true', () => {
    expect(buildWrapperClass(true, 0, 4, 1, responsiveValues)).toBe('nlecarousel-slider');
    expect(buildWrapperClass(true, 2, 4, 1, responsiveValues)).toBe('nlecarousel-slider');
  });
  
  it('returns a class of "apcarousel-wrapper first-slide-active" when "shouldRemoveButtons" is false and activeSlide is 0', () => {
    expect(buildWrapperClass(false, 0, 4, 1, responsiveValues)).toBe('nlecarousel-slider first-slide-active');
  });
  
  it('returns a class of "apcarousel-wrapper last-slide-active" when "shouldRemoveButtons" is false and activeSlide is the last slide', () => {
    expect(buildWrapperClass(false, 4, 4, 3, responsiveValues)).toBe('nlecarousel-slider last-slide-active');
  });

  it('returns a class "apcarousel-wrapper no-buttons" if "shouldRemoveButtons" is false and amount of slides equals shown slides', () => {
    expect(buildWrapperClass(true, 0, 4, 4, responsiveValues)).toBe('nlecarousel-slider no-buttons');
    expect(buildWrapperClass(true, 1, 4, 4, responsiveValues)).toBe('nlecarousel-slider no-buttons');
    expect(buildWrapperClass(true, 3, 4, 4, responsiveValues)).toBe('nlecarousel-slider no-buttons');
    expect(buildWrapperClass(true, 5, 4, 4, responsiveValues)).toBe('nlecarousel-slider no-buttons');
  });
});

describe('APCarousel', () => {
  it('renders without props', () => {
    render(
      <APCarousel>
        {numberArray.map(n => <div key={n}><span>{n}</span></div>)}
      </APCarousel>
    );
    
    numberArray.forEach(n => expect(screen.getByText(n)).toBeVisible());
  });

  it('sets the wrapper class appropriately on slide change using next button', async () => {
    const {container} = render(
      <APCarousel>
        {numberArray.map(n => <div key={n}><span>{n}</span></div>)}
      </APCarousel>
    );
    const carouselWrapper = screen.getByTestId('nlecarousel-wrapper');
    const nextButton = screen.getByTestId('nlecarousel-next');

    expect(carouselWrapper.firstChild).toHaveClass('nlecarousel-slider first-slide-active');
    expect(container.querySelector('.slick-slide')).toHaveClass('slick-active slick-current');
    
    userEvent.click(nextButton);
    
    await waitFor(() => expect(carouselWrapper.firstChild.className).toBe('nlecarousel-slider'));
    await waitFor(() => expect(carouselWrapper.firstChild).not.toHaveClass('first-slide-active'));
    await waitFor(() => expect(container.querySelector('.slick-slide')).not.toHaveClass('slick-current'));
    await waitFor(() => expect(container.querySelectorAll('.slick-slide')[1]).toHaveClass('slick-current'));
    
    userEvent.click(nextButton);
    

    await waitFor(() => expect(container.querySelectorAll('.slick-slide')[1]).not.toHaveClass('slick-current'));
    await waitFor(() => expect(container.querySelectorAll('.slick-slide')[2]).toHaveClass('slick-current'));
  });

  it('sets the wrapper class appropriately on slide change using the previous button', async () => {
    const {container} = render(
      <APCarousel>
        {numberArray.map(n => <div key={n}><span>{n}</span></div>)}
      </APCarousel>
    );
    const carouselWrapper = screen.getByTestId('nlecarousel-wrapper');
    const nextButton = screen.getByTestId('nlecarousel-next');

    expect(carouselWrapper.firstChild).toHaveClass('nlecarousel-slider first-slide-active');
    expect(container.querySelector('.slick-slide')).toHaveClass('slick-active slick-current');
    
    userEvent.click(nextButton);
    
    await waitFor(() => expect(carouselWrapper.firstChild.className).toBe('nlecarousel-slider'));
    await waitFor(() => expect(carouselWrapper.firstChild).not.toHaveClass('first-slide-active'));

    userEvent.click(screen.getByTestId('nlecarousel-prev'));
    
    await waitFor(() => expect(carouselWrapper.firstChild).toHaveClass('nlecarousel-slider first-slide-active'));
    expect(container.querySelector('.slick-slide')).toHaveClass('slick-active slick-current');
  });

  it('removes scroll buttons if all slides are showing based on slidesToShow and slidesToScroll', async () => {
    render(
      <APCarousel slidesToShow={6}>
        {numberArray.map(n => <div key={n}><span>{n}</span></div>)}
      </APCarousel>
    );

    expect(screen.getByTestId('nlecarousel-wrapper').firstChild).toHaveClass('nlecarousel-slider no-buttons');  
  });
}); 
