import PCICompliance from '../responses/PCICompliance';
import Config from '../lib/Config';
import { authHeaders, csvHeaders } from '../lib/Headers';
import { rollbarLog as log } from '../../../../../lib/utils';
import server from '../../../../../lib/server';

const PCICompliancesService = {
  csv: async params => {
    const errorMessage = 'PCI Compliance Service Error fetching CSV file';
    try {
      const baseUrl = Config.pciCompliancesUrl;
      const queryString = params.length ? `?${params.join('&')}` : undefined;
      const url = queryString ? `${baseUrl}${queryString}` : baseUrl;
      const headers = await csvHeaders();
      const resp = await server.get({
        url,
        headers
      });
      const blob = await resp.blob({ type: 'text/csv' });
      const file = window.URL.createObjectURL(blob);
      const filename = resp.headers.get('content-disposition').split('filename=')[1].replace(/"/g, '');
      const a = document.createElement('a');
      a.download = filename;
      a.href = file;
      a.rel = 'noopener';
      a.target = '_blank';
      a.click();
      setTimeout(() => URL.revokeObjectURL(file), 1000);
    } catch ({ message = '' }) {
      log(errorMessage, message);
      throw new Error(errorMessage);
    }
  },
  get: async (params = []) => {
    const errorMessage = 'PCI Compliance Service Error fetching records';
    try {
      const baseUrl = Config.pciCompliancesUrl;
      const queryString = params.length ? `?${params.join('&')}` : undefined;
      const url = queryString ? `${baseUrl}${queryString}` : baseUrl;
      const headers = await authHeaders();

      const resp = await server.get({
        url,
        headers
      });

      const json = await resp.json();
      const data = json.data.map(({ id, attributes }) => PCICompliance({ id, ...attributes }));
      const totalCount = json.meta.page.total;

      return { data, totalCount };
    } catch ({ message = '' }) {
      log(errorMessage, message);
      throw new Error(errorMessage);
    }
  },
  patch: async (id, status, next_due_date) => {
    const errorMessage = `Error in updating PCI Compliance for merchant ${id}`;

    try {
      const url = Config.pciCompliancesUrl;
      const headers = await authHeaders();
      await server.patch({
        url: `${url}/${id}`,
        headers,
        body: {
          data: {
            id,
            type: 'pci_compliance',
            attributes: {
              status,
              next_due_date
            }
          }
        }
      });
    } catch({message = ''}) {
      log(errorMessage, message);
      throw new Error(errorMessage);
    }
  },
  bulkUpdate: async (filters, status, next_due_date) => {
    const errorMessage = `Error updating some PCI Compliances`;
    try {
      const url = Config.bulkOperation;
      const headers = await authHeaders();
      headers['Content-Type'] += `;ext="https://jsonapi.org/ext/atomic"`;
      const queryString = filters.length ? `?${filters.join('&')}` : undefined;
      await server.post({
        url,
        headers,
        body: {
          'atomic:operations': [{
            op: 'update',
            href: `/pci-compliances/${queryString}`,
            data: {
              type: 'pci_compliance',
              attributes: {
                status,
                next_due_date
              }
            }
          }]
        }
      });
    } catch({message = ''}) {
      log(errorMessage, message);
      throw new Error(errorMessage);
    }
  }
};

export default PCICompliancesService;
