import React from 'react'; 
import { string } from 'prop-types';

const Contact = ({
  className='ap-icon',
  fill='#005fb8',
  height='20',
  width='20',
  title='ContactIcon'
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
    viewBox="0 0 20 20" 
    fill="none"
  >
    <title>{title}</title>
    <path 
      d="M14.375 1.875C14.7188 1.875 15 2.15625 15 2.5V17.5C15 17.8438 14.7188 18.125 14.375 18.125H3.125C2.78125 18.125 2.5 17.8438 2.5 17.5V2.5C2.5 2.15625 2.78125 1.875 3.125 1.875H14.375ZM3.125 0C1.74609 0 0.625 1.12109 0.625 2.5V17.5C0.625 18.8789 1.74609 20 3.125 20H14.375C15.7539 20 16.875 18.8789 16.875 17.5V2.5C16.875 1.12109 15.7539 0 14.375 0H3.125ZM8.75 10C9.41304 10 10.0489 9.73661 10.5178 9.26777C10.9866 8.79893 11.25 8.16304 11.25 7.5C11.25 6.83696 10.9866 6.20107 10.5178 5.73223C10.0489 5.26339 9.41304 5 8.75 5C8.08696 5 7.45107 5.26339 6.98223 5.73223C6.51339 6.20107 6.25 6.83696 6.25 7.5C6.25 8.16304 6.51339 8.79893 6.98223 9.26777C7.45107 9.73661 8.08696 10 8.75 10ZM7.5 11.25C5.77344 11.25 4.375 12.6484 4.375 14.375C4.375 14.7188 4.65625 15 5 15H12.5C12.8438 15 13.125 14.7188 13.125 14.375C13.125 12.6484 11.7266 11.25 10 11.25H7.5ZM19.375 3.125C19.375 2.78125 19.0938 2.5 18.75 2.5C18.4062 2.5 18.125 2.78125 18.125 3.125V5.625C18.125 5.96875 18.4062 6.25 18.75 6.25C19.0938 6.25 19.375 5.96875 19.375 5.625V3.125ZM18.75 7.5C18.4062 7.5 18.125 7.78125 18.125 8.125V10.625C18.125 10.9688 18.4062 11.25 18.75 11.25C19.0938 11.25 19.375 10.9688 19.375 10.625V8.125C19.375 7.78125 19.0938 7.5 18.75 7.5ZM19.375 13.125C19.375 12.7812 19.0938 12.5 18.75 12.5C18.4062 12.5 18.125 12.7812 18.125 13.125V15.625C18.125 15.9688 18.4062 16.25 18.75 16.25C19.0938 16.25 19.375 15.9688 19.375 15.625V13.125Z" 
      fill={fill}
    />
  </svg>
);

Contact.propTypes = {
  className: string,
  fill: string,
  height: string,
  width: string,
  title: string
};

export default Contact;

