import React from 'react';
import { APAlert, APButton } from 'affinipay-ui-library';
import './style.scss';

const SelfServiceBanner = () => {

  const handleClick = (e) => {
    e.preventDefault();
    window.location.assign('/self_service');
  };
  
  return (
    <div className='self-service-banner-container'>
      <div className='self-service-banner admin'>
        <APAlert alertType='error'>
          <div className="banner-header">
            <p>Please verify your business information</p>
          </div>
          <div className='banner-body'>
            <strong>Verify Beneficial Owners to your business</strong>
            <br/>
            Please take a moment to update the list of beneficial owners to your business
          </div>
          <div className='banner-action'>
            <APButton onClick={handleClick} className='ap-negative-button'>Verify Now</APButton>
          </div>
        </APAlert>
      </div>
    </div>
  );
};

export default SelfServiceBanner;