import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const PaymentPagesBillboard = ({
  height=88,
  width=104,
  title='Payment Pages'
}) => (
  <svg width={width} height={height} viewBox="0 0 104 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M103.532 8.86855C103.532 8.86855 102.585 7.12491 101.015 4.71289C101.015 4.71289 96.1268 8.31639 96.1564 8.11297C96.186 7.90955 103.532 8.86855 103.532 8.86855Z" fill="#231F20"/>
    <path d="M103.534 9.07259C100.927 8.75292 96.7208 8.22983 96.2468 8.28795C96.1283 8.34607 96.0987 8.31701 96.0394 8.28795C95.9506 8.22983 95.9209 8.14265 95.9506 8.05547C95.9506 7.99735 95.9802 7.93923 96.0987 7.91017C96.3949 7.79392 99.1794 5.78875 100.868 4.53914C100.927 4.51008 100.986 4.48102 101.016 4.51008C101.075 4.51008 101.135 4.56821 101.164 4.59727C102.705 6.98023 103.682 8.72386 103.682 8.75292C103.741 8.86916 103.682 8.98541 103.564 9.01447C103.593 9.04353 103.564 9.04353 103.534 9.07259ZM96.8985 7.91017C97.8168 7.96829 99.5942 8.14265 103.149 8.60762C102.793 7.99735 102.023 6.66056 100.957 5.00411C98.7351 6.6315 97.5502 7.47426 96.8985 7.91017Z" fill="#231F20"/>
    <path d="M38.57 81.404L36.1113 77.4227L39.5179 74.4004L38.57 81.404Z" fill="#231F20"/>
    <path d="M38.5711 81.6065C38.5118 81.6065 38.4229 81.5775 38.3933 81.5194L35.9346 77.509C35.8754 77.4218 35.905 77.3056 35.9643 77.2475L39.3413 74.2542C39.4301 74.1671 39.5486 74.1961 39.6375 74.2542C39.6671 74.3124 39.6967 74.3705 39.6967 74.4286L38.7488 81.4322C38.7488 81.5194 38.6599 81.5775 38.5711 81.6065ZM36.4086 77.4509L38.4526 80.7929L39.2524 74.9226L36.4086 77.4509Z" fill="#231F20"/>
    <path d="M40.1698 82.2464L79.5681 87.7679C81.2269 87.9713 82.8266 86.9833 83.3005 85.3849L103.681 10.5832C104.096 9.04295 103.089 7.6771 101.43 7.5318L61.2612 4.33514C59.632 4.24796 58.1508 5.29414 57.6769 6.83435L38.1259 79.166C37.7408 80.4446 38.4517 81.7814 39.7551 82.1592C39.8736 82.2173 40.0217 82.2464 40.1698 82.2464Z" fill="#231F20"/>
    <path d="M79.9827 88.0007C79.8346 88.0007 79.6569 88.0006 79.5088 87.9716L40.1401 82.4501C39.37 82.3629 38.6886 81.927 38.2443 81.3167C37.8 80.6774 37.6815 79.8637 37.9184 79.1372L57.4694 6.80557C57.973 5.17819 59.543 4.07389 61.2612 4.16107L101.43 7.32866C102.259 7.35772 103.029 7.76457 103.533 8.43296C103.977 9.07229 104.125 9.88599 103.888 10.6416L83.4782 85.4724C83.0042 86.9545 81.612 88.0007 79.9827 88.0007ZM40.1994 82.0433L79.5976 87.5647C81.1676 87.7682 82.6488 86.8092 83.0931 85.3271L103.474 10.4963C103.651 9.85693 103.562 9.18854 103.177 8.63639C102.763 8.08424 102.111 7.73551 101.4 7.70645L61.2019 4.53885C59.6615 4.45167 58.2693 5.43973 57.8545 6.89276L38.3035 79.2244C38.1258 79.8637 38.2147 80.5321 38.5701 81.0843C38.9552 81.6364 39.5477 81.9851 40.1994 82.0433Z" fill="#231F20"/>
    <path d="M37.5936 78.1194L76.9918 83.6409C78.6507 83.8444 80.2503 82.8563 80.7243 81.258L101.105 6.42714C101.519 4.88693 100.512 3.52109 98.8534 3.37579L58.6553 0.208192C57.0261 0.12101 55.5449 1.16719 55.071 2.7074L35.52 75.039C35.1349 76.3177 35.8754 77.6545 37.1492 78.0323C37.3269 78.0904 37.4454 78.1194 37.5936 78.1194Z" fill="white"/>
    <path d="M77.4355 83.8735C77.2874 83.8735 77.1097 83.8735 76.9615 83.8444L37.5633 78.3229C36.7931 78.2357 36.1118 77.7998 35.6675 77.1895C35.2527 76.5502 35.1046 75.7365 35.3416 74.9809L54.8926 2.64932C55.3962 1.02194 56.9366 -0.0823639 58.6843 0.0048176L98.8528 3.17241C99.6822 3.20147 100.452 3.60832 100.956 4.27671C101.4 4.91605 101.548 5.72974 101.311 6.48531L80.931 81.3161C80.457 82.8273 79.0351 83.8735 77.4355 83.8735ZM58.4177 0.411665C56.9662 0.440726 55.6924 1.39972 55.2777 2.76557L35.7267 75.0972C35.549 75.7365 35.6675 76.4049 36.0229 76.9571C36.3784 77.4801 36.9709 77.8579 37.6226 77.9161L77.0208 83.4376C78.5908 83.6119 80.0423 82.682 80.4867 81.1999L100.867 6.36907C101.045 5.72974 100.956 5.06135 100.571 4.5092C100.156 3.95705 99.5044 3.60832 98.7935 3.57926L58.6547 0.411665C58.5658 0.411665 58.5066 0.411665 58.4177 0.411665Z" fill="#231F20"/>
    <path d="M97.2236 11.3681L56.0776 7.88086L40.3184 66.2344L80.8423 71.5234L97.2236 11.3681Z" fill="#1A73E8"/>
    <path d="M79.1834 63.4734C79.0946 63.4734 79.0353 63.4153 79.0057 63.3571L70.7113 46.1533C70.6521 46.0371 70.7113 45.9208 70.8002 45.8918C70.8891 45.8627 71.0372 45.8918 71.0668 45.9789L79.3612 63.1828C79.4204 63.299 79.3612 63.4152 79.2723 63.4443C79.2427 63.4734 79.2131 63.4734 79.1834 63.4734Z" fill="white"/>
    <path d="M53.886 77.6826L61.588 78.7287C62.5951 78.845 63.5431 78.2347 63.8393 77.2757L64.6095 74.4568C64.8465 73.6431 64.3725 72.8294 63.5727 72.5679C63.4838 72.5388 63.3949 72.5098 63.2765 72.5098L55.5745 71.4927C54.5674 71.3764 53.6491 71.9867 53.3528 72.9457L52.5826 75.7355C52.3457 76.5201 52.8196 77.3629 53.6194 77.5954C53.7083 77.6535 53.7972 77.6826 53.886 77.6826Z" fill="white"/>
    <path d="M61.8249 78.9919C61.736 78.9919 61.6175 78.9919 61.5287 78.9629L53.8267 77.9167C52.8788 77.8005 52.1679 76.9868 52.2864 76.0278C52.2864 75.9406 52.316 75.8243 52.3456 75.7372L53.1158 72.9474C53.4416 71.9012 54.4488 71.2328 55.5449 71.349L63.2468 72.3661C63.78 72.4243 64.2243 72.6858 64.5205 73.1217C64.8168 73.5286 64.876 74.0517 64.7575 74.5457L63.9873 77.3645C63.6911 78.2945 62.8321 78.9629 61.8249 78.9919ZM53.9156 77.4808L61.6175 78.527C62.5358 78.6432 63.3653 78.0911 63.6319 77.2192L64.4021 74.4004C64.5205 74.0226 64.4613 73.6157 64.2243 73.2961C63.9873 72.9764 63.6319 72.773 63.2468 72.7149L55.5449 71.6977C54.6562 71.6106 53.7971 72.1337 53.5601 73.0055L52.7899 75.7953C52.5826 76.4927 52.9677 77.2192 53.6786 77.4227C53.7379 77.4808 53.8267 77.4808 53.9156 77.4808Z" fill="#D1D3D4"/>
    <path d="M85.0492 6.34046L73.2889 5.38146C73.1705 5.38146 73.0816 5.26522 73.1112 5.17804C73.1112 5.0618 73.2297 4.97462 73.3482 5.00368L85.1084 5.96267C85.2269 5.96267 85.3158 6.04986 85.3158 6.1661C85.2565 6.25328 85.1677 6.34046 85.0492 6.34046Z" fill="#D1D3D4"/>
    <path d="M71.6597 5.26505L70.0897 5.14881C69.9712 5.14881 69.8824 5.03257 69.912 4.91632C69.912 4.80008 70.0305 4.7129 70.149 4.74196L71.6894 4.8582C71.8078 4.8582 71.8967 4.94538 71.8967 5.06163C71.8671 5.17787 71.7782 5.26505 71.6597 5.26505Z" fill="#D1D3D4"/>
    <path d="M77.139 20.4047C77.0797 20.4047 76.9908 20.3756 76.9612 20.3175L71.3922 11.7447C71.3329 11.6575 71.3625 11.5122 71.4514 11.4541C71.5403 11.3959 71.6884 11.425 71.7476 11.5122C71.7476 11.5122 71.7476 11.5122 71.7476 11.5412L77.3167 20.085C77.3759 20.1722 77.3759 20.2885 77.2574 20.3756C77.2574 20.3756 77.2575 20.3756 77.2278 20.3756C77.1982 20.4047 77.1686 20.4047 77.139 20.4047Z" fill="white"/>
    <path d="M60.3129 17.9354C60.2241 17.9354 60.1648 17.9063 60.1352 17.8191L57.2914 12.4429C57.2322 12.3558 57.2914 12.2105 57.3803 12.1814C57.4692 12.1233 57.6173 12.1523 57.6469 12.2686L60.4907 17.6448C60.5499 17.732 60.4907 17.8773 60.4018 17.9063C60.3722 17.9063 60.3426 17.9063 60.3129 17.9354Z" fill="white"/>
    <path d="M83.2113 43.5671C83.2113 43.5671 88.2472 27.235 83.8926 23.6025L62.4458 21.0742C62.4458 21.0742 58.7725 24.823 58.2393 27.7291C57.7061 30.6351 53.2627 36.7378 54.9216 40.3995L83.2113 43.5671Z" fill="#231F20"/>
    <path d="M83.2113 43.7708L54.9216 40.6032C54.8623 40.6032 54.7735 40.5451 54.7439 40.4869C53.5886 37.9296 55.2771 34.268 56.6397 31.3038C57.2914 29.8508 57.8839 28.6012 58.0616 27.6713C58.5948 24.7652 62.1792 21.0745 62.3273 20.9292C62.3569 20.8711 62.4458 20.8711 62.505 20.8711L83.9519 23.3994C83.9815 23.3994 84.0407 23.4284 84.0704 23.4575C88.5138 27.1191 83.6853 42.9571 83.4779 43.6255C83.389 43.7127 83.3002 43.7708 83.2113 43.7708ZM55.0697 40.2254L83.0632 43.3639C83.5668 41.6494 87.714 27.2063 83.8038 23.8062L62.5346 21.307C62.0311 21.8301 58.9207 25.2011 58.4467 27.7584C58.269 28.7174 57.6765 29.9961 56.9952 31.4782C55.6918 34.3261 54.0922 37.8424 55.0697 40.2254Z" fill="#231F20"/>
    <path d="M85.2244 44.062H85.1947L49.3809 39.1217C49.2624 39.0926 49.2031 39.0054 49.2031 38.8892C49.2327 38.773 49.3216 38.7148 49.4401 38.7148L85.254 43.6551C85.3725 43.6842 85.4317 43.7714 85.4317 43.8876C85.4021 43.9748 85.3132 44.0329 85.2244 44.062Z" fill="#231F20"/>
    <path d="M83.8619 23.6027C83.8619 23.6027 81.6994 23.8933 80.3664 28.3396C78.3224 35.1397 75.6564 49.4375 73.9382 54.8428C71.4796 62.6891 64.8144 63.7934 64.8144 63.7934L36.7617 59.8121C36.7617 59.8121 48.1665 55.918 50.0919 49.3213C52.1359 42.3758 54.9501 29.2405 55.6906 26.3054C57.0237 21.1035 62.4446 21.1035 62.4446 21.1035L83.8619 23.6027Z" fill="white"/>
    <path d="M64.8148 63.9681H64.7852L36.7324 59.9868C36.6436 59.9868 36.5547 59.8996 36.5547 59.8124C36.5547 59.7252 36.6139 59.6381 36.7028 59.609C36.8213 59.5799 48.0483 55.6858 49.9145 49.2635C51.3364 44.4394 53.1138 36.564 54.3283 31.3331C54.8615 28.9792 55.2763 27.1484 55.5132 26.2475C56.8759 20.9585 62.3857 20.9004 62.445 20.9004L83.8918 23.4287C84.0103 23.4287 84.0992 23.5449 84.0695 23.6611C84.0695 23.7483 83.9807 23.8355 83.8918 23.8355C83.8622 23.8355 81.8182 24.2133 80.5444 28.4271C79.5076 31.8562 78.3227 37.2033 77.1675 42.4052C76.0418 47.4908 74.9754 52.2567 74.1163 54.9303C72.8129 59.0278 70.3838 61.2945 68.5472 62.4569C67.4216 63.1544 66.1774 63.6775 64.8148 63.9681ZM37.5915 59.6962L64.8148 63.5612C65.2887 63.474 71.4503 62.1082 73.7608 54.7559C74.5903 52.1114 75.6567 47.3164 76.7824 42.2599C77.9376 37.0871 79.1226 31.7109 80.1594 28.2527C81.048 25.3467 82.2626 24.1552 83.0624 23.6902L62.4153 21.2491C62.2376 21.2491 57.1721 21.3072 55.8983 26.3056C55.6614 27.2065 55.2466 29.0373 54.7134 31.3912C53.5285 36.6221 51.7215 44.4975 50.2996 49.3506C49.2925 52.8379 45.6785 55.5405 42.8051 57.197C41.1462 58.156 39.3985 58.9987 37.5915 59.6962Z" fill="#231F20"/>
    <path d="M70.3837 55.3074H70.3541L66.5624 54.8715C66.4439 54.8715 66.355 54.7552 66.3846 54.639C66.4143 54.5227 66.5031 54.4356 66.6216 54.4646L70.4133 54.9005C70.5318 54.9005 70.6207 55.0168 70.5911 55.133C70.5614 55.2202 70.4726 55.3074 70.3837 55.3074Z" fill="#D1D3D4"/>
    <path d="M63.5118 54.5527L52.1367 53.274C52.0182 53.245 51.959 53.1578 51.959 53.0416C51.9886 52.9253 52.0775 52.8672 52.1663 52.8672L63.5415 54.1458C63.66 54.1749 63.7192 54.2621 63.7192 54.3783C63.6896 54.4655 63.6303 54.5527 63.5118 54.5527Z" fill="#D1D3D4"/>
    <path d="M66.9781 59.55L63.453 59.1723C63.3345 59.1723 63.2456 59.056 63.2753 58.9398C63.2753 58.8235 63.3938 58.7363 63.5123 58.7654L67.0077 59.1432C67.1262 59.1432 67.2151 59.2594 67.2151 59.3466C67.2151 59.4338 67.0966 59.5791 66.9781 59.55Z" fill="#D1D3D4"/>
    <path d="M59.6315 58.7365L48.7304 57.516C48.6119 57.516 48.523 57.3998 48.5526 57.2835C48.5526 57.1673 48.6711 57.0801 48.7896 57.1091L59.6611 58.3297C59.7796 58.3587 59.8389 58.4459 59.8389 58.5622C59.8093 58.6494 59.7204 58.7365 59.6315 58.7365Z" fill="#D1D3D4"/>
    <path d="M64.8148 63.7638C61.4971 60.7415 59.8086 56.3534 60.3122 51.9362L30.8672 46.7344C30.8672 46.7344 33.3851 56.4115 36.7621 59.7825L64.8148 63.7638Z" fill="white"/>
    <path d="M64.8149 63.9676H64.7853L36.7326 59.9863C36.6733 59.9863 36.6437 59.9572 36.6141 59.9281C33.2667 56.5571 30.7784 47.1996 30.6599 46.7928C30.6303 46.6766 30.6895 46.5603 30.808 46.5312C30.8376 46.5312 30.8673 46.5312 30.8969 46.5312L60.3419 51.7331C60.4604 51.7621 60.5196 51.8493 60.5196 51.9656C60.0456 56.3246 61.6749 60.6256 64.963 63.6188C65.0519 63.677 65.0519 63.8223 64.9926 63.9094C64.9334 63.9385 64.8741 63.9676 64.8149 63.9676ZM36.851 59.5794L64.1928 63.4735C61.2009 60.4512 59.6902 56.2956 60.0753 52.1109L31.1339 46.9962C31.6078 48.6527 33.948 56.5862 36.851 59.5794Z" fill="#231F20"/>
    <path d="M76.4861 29.9662L58.2089 27.932C58.0904 27.932 58.0015 27.8158 58.0311 27.6995C58.0311 27.5833 58.1496 27.4961 58.2681 27.5252L76.5157 29.5594C76.6342 29.5594 76.7231 29.6466 76.7231 29.7628C76.6935 29.8791 76.6046 29.9662 76.4861 29.9662Z" fill="#D1D3D4"/>
    <path d="M75.7155 34.5581L72.2793 34.1803C72.1608 34.1512 72.1016 34.064 72.1016 33.9478C72.1312 33.8316 72.2201 33.7734 72.3089 33.7734L75.7452 34.1512C75.8637 34.1512 75.9525 34.2675 75.9525 34.3546C75.9525 34.4418 75.834 34.5581 75.7155 34.5581Z" fill="#D1D3D4"/>
    <path d="M68.4587 33.7443L57.4686 32.5238C57.3501 32.5238 57.2613 32.4076 57.2909 32.2913C57.2909 32.1751 57.4094 32.0879 57.5279 32.117L68.5179 33.3375C68.6364 33.3375 68.7253 33.4537 68.6957 33.57C68.6364 33.6862 68.5475 33.7443 68.4587 33.7443Z" fill="#D1D3D4"/>
    <path d="M74.5914 38.8604L68.9335 38.2211C68.815 38.2211 68.7261 38.1048 68.7557 37.9886C68.7557 37.8723 68.8742 37.7852 68.9927 37.8142L74.621 38.4536C74.7395 38.4826 74.7988 38.5698 74.7988 38.686C74.7692 38.7732 74.6803 38.8604 74.5914 38.8604Z" fill="#D1D3D4"/>
    <path d="M63.5108 37.6095L56.3125 36.8248C56.194 36.7958 56.1348 36.7086 56.1348 36.5923C56.1644 36.4761 56.2533 36.418 56.3421 36.418L63.5108 37.2317C63.6293 37.2317 63.7182 37.3479 63.6886 37.4641C63.6886 37.5513 63.5997 37.6095 63.5108 37.6095Z" fill="#D1D3D4"/>
    <path d="M73.6122 42.9278L68.9022 42.4047C68.7837 42.4047 68.6949 42.2884 68.7245 42.1722C68.7245 42.0559 68.843 41.9688 68.9615 41.9978L73.6715 42.5209C73.79 42.5209 73.8788 42.6371 73.8492 42.7534C73.8196 42.8406 73.7307 42.9278 73.6122 42.9278Z" fill="#D1D3D4"/>
    <path d="M63.8965 41.8246L55.3651 40.8656C55.2466 40.8656 55.1578 40.7494 55.1874 40.6331C55.1874 40.5169 55.3059 40.4297 55.4244 40.4588L63.9261 41.4178C64.0446 41.4468 64.1038 41.534 64.1038 41.6502C64.0742 41.7665 63.9853 41.8246 63.8965 41.8246Z" fill="#D1D3D4"/>
    <path d="M72.636 47.4321L68.4296 46.9672C68.3111 46.9672 68.2222 46.8509 68.2518 46.7347C68.2815 46.6184 68.3703 46.5313 68.4888 46.5603L72.6656 47.0253C72.7841 47.0544 72.8434 47.1415 72.8434 47.2578C72.8137 47.345 72.7249 47.4031 72.636 47.4321Z" fill="#D1D3D4"/>
    <path d="M65.8529 46.6482L54.3593 45.3695C54.2408 45.3695 54.1519 45.2533 54.1815 45.137C54.1815 45.0208 54.3 44.9336 54.4185 44.9627L65.8825 46.2413C66.001 46.2704 66.0602 46.3576 66.0602 46.4738C66.0306 46.5901 65.9417 46.6482 65.8529 46.6482Z" fill="#D1D3D4"/>
    <path d="M71.5687 51.0645H71.5391L66.5625 50.5123C66.444 50.4833 66.3848 50.3961 66.3848 50.2798C66.4144 50.1636 66.5033 50.1055 66.5921 50.1055L71.5687 50.6576C71.6872 50.6867 71.7465 50.7739 71.7465 50.8901C71.7465 50.9773 71.6576 51.0645 71.5687 51.0645Z" fill="#D1D3D4"/>
    <path d="M64.3402 50.2522L53.2909 49.0316C53.1724 49.0316 53.0835 48.9154 53.1132 48.7991C53.1132 48.6829 53.2317 48.5957 53.3501 48.6248L64.3698 49.8453C64.4883 49.8453 64.5772 49.9616 64.5475 50.0778C64.5475 50.194 64.4587 50.2522 64.3402 50.2522Z" fill="#D1D3D4"/>
    <path d="M16.5894 43.8875L7.08051 23.8067C6.2807 22.1503 7.02126 20.1741 8.70976 19.3895L40.1099 5.06267C41.7983 4.27803 43.8127 5.00455 44.6125 6.66099L54.1214 26.7418C54.9212 28.3983 54.1807 30.3744 52.4922 31.159L21.0921 45.4859C19.3739 46.2705 17.3892 45.544 16.5894 43.8875Z" fill="white"/>
    <path d="M19.6708 46.008C18.2785 46.008 17.0048 45.2233 16.4123 43.9737L6.90342 23.8929C6.07398 22.1202 6.84417 20.0279 8.65116 19.2142L40.0513 4.88733C41.8582 4.07364 43.9911 4.82921 44.8205 6.6019L54.3294 26.6827C55.1588 28.4554 54.3887 30.5478 52.5817 31.3615L21.1816 45.6883C20.7076 45.8917 20.1744 46.008 19.6708 46.008ZM41.5324 4.97451C41.0584 4.97451 40.6141 5.06169 40.1994 5.26512L8.79927 19.592C7.19964 20.3185 6.51832 22.1783 7.25889 23.7185L16.7678 43.7994C17.5084 45.3686 19.4042 46.037 20.9742 45.3105L52.3743 30.9837C53.9739 30.2572 54.6553 28.3973 53.9147 26.8571L44.4058 6.77626C43.9022 5.67196 42.7765 4.97451 41.5324 4.97451Z" fill="#231F20"/>
    <path d="M36.9402 19.7665L34.5408 14.7099C33.9779 13.5184 34.5111 12.0945 35.7257 11.5133L39.3693 9.85681C40.5838 9.30466 42.0353 9.82775 42.6278 11.0192L45.0272 16.0758C45.59 17.2672 45.0568 18.6912 43.8423 19.2724L40.1987 20.9289C38.9545 21.481 37.503 20.9579 36.9402 19.7665Z" fill="#1A73E8"/>
    <path d="M11.3174 24.2707C10.962 23.5442 11.2878 22.6433 12.058 22.3236L19.1082 19.0979C19.8488 18.7492 20.7671 19.0689 21.0929 19.8244C21.4484 20.551 21.1226 21.4518 20.3524 21.7715L13.3022 24.9972C12.5616 25.3169 11.6433 24.9972 11.3174 24.2707Z" fill="#D1D3D4"/>
    <path d="M43.1026 29.0956L42.4213 27.6716C42.2436 27.2938 42.4213 26.8579 42.7768 26.6835L48.0792 24.2715C48.4643 24.0972 48.9087 24.2715 49.0864 24.6203L49.7677 26.0442C49.9455 26.422 49.7677 26.8579 49.4123 27.0323L44.1098 29.4443C43.7247 29.6187 43.2507 29.4734 43.1026 29.0956Z" fill="#D1D3D4"/>
    <path d="M35.0147 32.785L34.3334 31.3611C34.1557 30.9833 34.3334 30.5474 34.6889 30.373L39.9913 27.961C40.3764 27.7866 40.8208 27.961 40.9985 28.3097L41.6798 29.7337C41.8576 30.1115 41.6798 30.5474 41.3244 30.7217L36.0219 33.1338C35.6368 33.3081 35.1925 33.1628 35.0147 32.785Z" fill="#D1D3D4"/>
    <path d="M26.9288 36.4764L26.2475 35.0525C26.0697 34.6747 26.2475 34.2388 26.6029 34.0644L31.9054 31.6524C32.2905 31.478 32.7348 31.6524 32.9126 32.0011L33.5939 33.4251C33.7716 33.8029 33.5939 34.2388 33.2384 34.4131L27.936 36.8252C27.5509 36.9995 27.1065 36.8542 26.9288 36.4764Z" fill="#D1D3D4"/>
    <path d="M18.8409 40.1659L18.1596 38.7419C17.9818 38.3641 18.1596 37.9282 18.515 37.7539L23.8175 35.3418C24.2026 35.1675 24.6469 35.3418 24.8247 35.6906L25.506 37.1145C25.6837 37.4923 25.506 37.9282 25.1505 38.1026L19.8481 40.5146C19.463 40.689 19.0186 40.5437 18.8409 40.1659Z" fill="#D1D3D4"/>
    <path d="M13.9823 49.4661L12.2346 51.4131C11.1386 52.779 7.61346 51.82 4.38458 49.2627C1.1557 46.7053 -0.562414 43.5668 0.533627 42.201L2.28137 40.2539L13.9823 49.4661Z" fill="white"/>
    <path d="M10.6062 52.2546C8.94735 52.2546 6.54791 51.2375 4.26696 49.4648C2.81545 48.3605 1.60091 46.9947 0.652987 45.4254C-0.117204 44.0305 -0.20607 42.839 0.386384 42.1125L2.13412 40.1364C2.19337 40.0492 2.34148 40.0201 2.43035 40.1073L14.1313 49.2905C14.161 49.3195 14.1906 49.3776 14.2202 49.4358C14.2202 49.4939 14.2202 49.552 14.161 49.5811L12.4132 51.5281C12.0281 52.0221 11.406 52.2546 10.6062 52.2546ZM2.31186 40.5432L0.712234 42.345C0.23827 42.9262 0.356761 43.9724 1.03808 45.1929C1.92677 46.7041 3.11168 48.0408 4.53357 49.1161C7.61433 51.5281 11.0802 52.5162 12.0874 51.2956L13.687 49.4939L2.31186 40.5432Z" fill="#231F20"/>
    <path d="M13.9938 49.4629C15.0983 48.11 13.3747 44.9556 10.1441 42.4174C6.91354 39.8791 3.39927 38.9182 2.29478 40.2711C1.19029 41.624 2.91383 44.7784 6.14442 47.3166C9.37501 49.8549 12.8893 50.8158 13.9938 49.4629Z" fill="white"/>
    <path d="M12.3543 50.2789C10.6954 50.2789 8.29596 49.2618 6.01501 47.4891C4.5635 46.3848 3.34896 45.0189 2.40104 43.4497C1.63084 42.0257 1.54198 40.8633 2.13443 40.1368C2.72688 39.4102 3.91179 39.2359 5.4818 39.6718C7.22954 40.2239 8.85879 41.0957 10.2807 42.2291C13.6577 44.8736 15.3462 48.0993 14.1613 49.5814C13.7762 50.0755 13.1245 50.2789 12.3543 50.2789ZM3.94142 39.8752C3.2601 39.8752 2.75651 40.0496 2.46028 40.4274C1.98632 41.0086 2.10481 42.0547 2.78613 43.3044C3.67481 44.8155 4.85972 46.1523 6.28161 47.1985C9.36238 49.6105 12.8282 50.6276 13.8354 49.378C14.8426 48.1284 13.1245 45.0189 10.0141 42.6069C8.65143 41.4735 7.08143 40.6308 5.36331 40.1077C4.88934 39.9333 4.41538 39.8752 3.94142 39.8752Z" fill="#231F20"/>
    <path d="M5.42157 41.3867L6.19176 42.0842L6.28062 42.1132C6.5176 42.1714 6.75459 42.2004 6.99157 42.2585C7.64327 42.4329 8.26535 42.7235 8.82818 43.1013C9.18365 43.3338 9.47988 43.5953 9.77611 43.915C9.95384 44.0893 10.0723 44.2928 10.102 44.5253C10.102 44.6996 10.0131 44.8449 9.86497 44.9321C9.74648 45.0193 9.59837 45.0774 9.47988 45.1646L10.9018 46.5014C11.1388 46.327 11.0499 46.0364 10.6944 45.6877L11.5831 45.5424C11.8497 45.7749 12.0571 46.0655 12.2348 46.3561C12.3829 46.5886 12.3533 46.8792 12.1755 47.1116C12.0867 47.1988 11.9682 47.286 11.8497 47.3732L12.3829 47.8672L11.5535 48.1288L11.0203 47.6347C10.7537 47.6347 10.5167 47.6347 10.2501 47.6347C9.74648 47.6057 9.2429 47.4604 8.76893 47.2569C8.14685 46.9954 7.58402 46.5886 7.11006 46.0945C6.6361 45.5714 6.51761 45.1936 6.81383 44.903C7.02119 44.7287 7.22855 44.6124 7.49516 44.5253C7.52478 44.5253 7.5544 44.4962 7.58402 44.4962L6.13251 43.1594H6.07326C6.04364 43.2466 6.01402 43.3338 6.01402 43.421C6.07327 43.5953 6.16213 43.7697 6.31025 43.8859L4.9476 43.9731C4.82911 43.8569 4.74024 43.7406 4.65137 43.6534C4.2959 43.2466 4.17741 42.8979 4.26628 42.6363C4.41439 42.3748 4.681 42.2004 4.97722 42.1714C5.03647 42.1714 5.03647 42.1423 4.97722 42.1132L4.20703 41.3867H5.42157ZM8.68007 45.4843C8.56157 45.5714 8.38384 45.6586 8.41346 45.862C8.44308 46.0945 8.88742 46.4432 9.33176 46.5885C9.53912 46.6467 9.74648 46.7048 9.98346 46.6757L8.68007 45.4843ZM8.35421 44.1184C8.4727 44.0603 8.5912 44.0022 8.5912 43.8859C8.62082 43.6825 8.17648 43.3047 7.73214 43.1303C7.52478 43.0722 7.34704 43.0141 7.13968 42.956L8.35421 44.1184Z" fill="#D1D3D4"/>
    <path d="M27.4621 70.0977L28.4692 72.4806C29.2098 74.0499 26.7807 76.7235 23.0187 78.438C19.2566 80.1526 15.613 80.2688 14.8724 78.6996L13.8652 76.3166L27.4621 70.0977Z" fill="white"/>
    <path d="M17.6264 80.0372C16.1749 80.0372 15.1085 79.6303 14.7234 78.8167L13.7162 76.4046C13.6866 76.3174 13.7162 76.2012 13.8051 76.1431L27.4019 69.9241C27.4611 69.8951 27.5204 69.8951 27.5796 69.9241C27.6389 69.9532 27.6685 69.9823 27.6981 70.0404L28.7053 72.4233C29.5051 74.1088 27.076 76.8405 23.1658 78.6423C21.1219 79.5722 19.1668 80.0372 17.6264 80.0372ZM14.1605 76.4337L15.1085 78.6423C15.7898 80.0953 19.4038 79.921 22.9881 78.2936C26.5725 76.6662 29.0015 74.0507 28.3202 72.5977L27.3723 70.3891L14.1605 76.4337Z" fill="#231F20"/>
    <path d="M22.0181 76.0516C25.7702 74.3376 28.2073 71.6744 27.4615 70.1031C26.7157 68.5318 23.0694 68.6475 19.3172 70.3614C15.5651 72.0754 13.128 74.7386 13.8738 76.3099C14.6196 77.8812 18.2659 77.7656 22.0181 76.0516Z" fill="white"/>
    <path d="M16.5903 77.6241C15.1091 77.6241 14.0723 77.1881 13.6872 76.4035C12.8874 74.6889 15.3165 71.9573 19.2267 70.1846C23.1369 68.4119 26.8397 68.3247 27.6396 70.0393C28.4394 71.7538 26.0103 74.4855 22.1001 76.2582C20.4412 77.0428 18.6639 77.5078 16.8273 77.6241H16.5903ZM24.7661 69.1965C23.285 69.1965 21.3299 69.6615 19.4044 70.5333C15.8201 72.1607 13.391 74.7761 14.0723 76.2291C14.3982 76.8975 15.4054 77.2753 16.8273 77.2172C18.6046 77.101 20.3524 76.636 21.952 75.8804C25.5363 74.253 27.9654 71.6376 27.2841 70.1846C26.9582 69.5452 26.0399 69.1965 24.7661 69.1965Z" fill="#231F20"/>
    <path d="M16.2933 74.0515L17.2709 73.7028L17.3301 73.6447C17.4782 73.4413 17.6263 73.2669 17.7744 73.0635C18.2188 72.5695 18.752 72.1335 19.3444 71.8429C19.6999 71.6395 20.1146 71.4942 20.4997 71.378C20.7367 71.2908 20.9737 71.2908 21.2107 71.378C21.3588 71.4361 21.4773 71.5814 21.4773 71.7558C21.5069 71.9011 21.5069 72.0464 21.5069 72.1917L23.3435 71.5233C23.2843 71.2327 22.988 71.2036 22.5141 71.3489L22.7807 70.5062C23.1065 70.3609 23.462 70.3027 23.8175 70.3027C24.0841 70.3027 24.3507 70.448 24.4692 70.7096C24.5284 70.8258 24.558 70.9711 24.5877 71.1164L25.269 70.8839L25.1505 71.7267L24.4692 71.9882C24.3507 72.2207 24.2618 72.4242 24.1137 72.6566C23.8471 73.0925 23.4916 73.4703 23.1065 73.79C22.5733 74.2259 21.9809 74.5456 21.3292 74.7199C20.6478 74.9234 20.2331 74.8362 20.1146 74.4584C20.0554 74.2259 20.0258 73.9644 20.085 73.7028C20.085 73.6738 20.085 73.6447 20.085 73.5866L18.2188 74.284L18.1892 74.3131C18.2484 74.3712 18.3373 74.4293 18.3965 74.4874C18.5743 74.5165 18.7816 74.5165 18.9593 74.4293L18.4261 75.6499C18.278 75.6789 18.1299 75.737 17.9818 75.7661C17.4486 75.9114 17.0931 75.8533 16.8858 75.6499C16.708 75.4174 16.6784 75.0977 16.7969 74.8362C16.7969 74.8071 16.7969 74.7781 16.7376 74.8071L15.7305 75.1558L16.2933 74.0515ZM21.5069 73.0054C21.5365 73.1507 21.5365 73.325 21.7439 73.4122C21.9512 73.4994 22.5141 73.2378 22.8103 72.9472C22.9584 72.8019 23.1065 72.6276 23.1658 72.4241L21.5069 73.0054ZM20.1146 72.6857C20.1146 72.5695 20.1146 72.4242 20.0258 72.366C19.848 72.2498 19.3148 72.4823 18.9593 72.8019C18.8112 72.9472 18.6927 73.0925 18.5446 73.2378L20.1146 72.6857Z" fill="#D1D3D4"/>
    <path d="M17.537 55.1318C16.0559 57.5438 17.7148 62.891 17.7148 62.891L19.9069 64.4602C23.195 66.3782 27.431 65.3611 29.4158 62.1354C31.3709 58.9097 30.3341 54.754 27.0459 52.807L24.6169 51.6445C24.6169 51.6445 19.0182 52.7198 17.537 55.1318Z" fill="white"/>
    <path d="M23.4624 65.6229C22.159 65.6229 20.9148 65.2742 19.7892 64.6349L17.5971 63.0656C17.5675 63.0366 17.5378 63.0075 17.5082 62.9494C17.449 62.7169 15.8494 57.486 17.3601 55.0158C18.8709 52.5457 24.3214 51.4705 24.5584 51.4414C24.6177 51.4414 24.6473 51.4414 24.6769 51.4705L27.106 52.6329C30.483 54.609 31.579 58.91 29.5647 62.2229C28.5871 63.8212 27.0171 64.9545 25.1805 65.4195C24.6177 65.5648 24.0548 65.6229 23.4624 65.6229ZM17.8933 62.775L20.0262 64.2861C23.1958 66.146 27.343 65.1289 29.2388 62.0194C31.1347 58.91 30.0979 54.8415 26.9283 52.9816L24.5881 51.8483C23.966 51.9936 19.0486 53.0688 17.7156 55.2193C16.3826 57.3988 17.7156 62.1647 17.8933 62.775Z" fill="#231F20"/>
    <path d="M21.9513 64.5476C25.7795 64.5476 28.883 61.503 28.883 57.7474C28.883 53.9918 25.7795 50.9473 21.9513 50.9473C18.123 50.9473 15.0195 53.9918 15.0195 57.7474C15.0195 61.503 18.123 64.5476 21.9513 64.5476Z" fill="white"/>
    <path d="M21.9515 64.7517C18.0116 64.7517 14.8124 61.6132 14.842 57.7482C14.8716 53.8831 18.0413 50.7446 21.9811 50.7736C23.2845 50.7736 24.5286 51.1224 25.6543 51.7617C29.0313 53.7378 30.1273 58.0388 28.0834 61.3517C26.78 63.4731 24.4398 64.7517 21.9515 64.7517ZM21.9811 51.1514C18.2782 51.1514 15.2567 54.0865 15.2567 57.7191C15.2567 61.3517 18.2486 64.3158 21.9515 64.3158C25.6543 64.3158 28.6758 61.3807 28.6758 57.7482C28.6758 55.4233 27.4317 53.2728 25.4173 52.0814C24.3805 51.5002 23.1956 51.1514 21.9811 51.1514Z" fill="#231F20"/>
    <path d="M18.0719 54.6387L18.931 55.1617C18.9606 55.1908 19.0199 55.1908 19.0495 55.1617C19.3753 54.784 19.8197 54.5224 20.264 54.3481C20.7676 54.1737 21.3008 54.1737 21.8044 54.3771C21.9229 54.4352 22.071 54.4933 22.2191 54.5515L21.4193 55.8592C21.212 55.743 20.975 55.6848 20.738 55.743C20.5603 55.8011 20.3825 55.8301 20.2048 55.9173V55.9754L21.8637 56.9635L21.9821 56.8473C22.2784 56.5276 22.6042 56.266 22.9597 56.0045C23.4929 55.6267 24.1742 55.5686 24.7667 55.8592C25.3295 56.0917 25.7739 56.4985 26.0108 57.0507C26.2182 57.4866 26.2478 57.9806 26.159 58.4456C26.0997 58.7362 26.0108 59.0268 25.922 59.3174L26.5737 59.6952L25.8331 60.8867L25.1814 60.4798C24.974 60.6832 24.7667 60.8867 24.5593 61.061C24.1446 61.4098 23.6114 61.6132 23.0782 61.6422C22.6042 61.6422 22.1303 61.526 21.7452 61.2935L22.545 60.0149C23.0189 60.2764 23.6114 60.1892 23.9965 59.8114L22.2191 58.7652C21.9821 58.9687 21.7748 59.1721 21.5674 59.3755C21.3008 59.608 20.975 59.8114 20.6491 59.8986C20.2937 59.9858 19.9086 59.9567 19.5531 59.8405C19.1384 59.6952 18.7533 59.4336 18.457 59.0849C18.0423 58.5909 17.8646 57.9225 18.0127 57.2832C18.0719 57.0216 18.1608 56.7891 18.2497 56.5276C18.2497 56.4985 18.2793 56.4695 18.2793 56.4404L17.3906 55.9173L18.0719 54.6387ZM24.7371 58.5618C24.8259 58.3584 24.8556 58.0969 24.8259 57.8644C24.7371 57.4575 24.3816 57.1669 23.9669 57.196C23.6706 57.2832 23.4337 57.4285 23.2263 57.6319L24.7371 58.5618ZM19.3753 57.0507C19.3457 57.2541 19.3457 57.4575 19.3457 57.6609C19.3753 58.0678 19.7604 58.3875 20.1752 58.3584C20.2048 58.3584 20.264 58.3584 20.2937 58.3293C20.501 58.2422 20.7084 58.0969 20.8565 57.9225L19.3753 57.0507Z" fill="#D1D3D4"/>
  </svg>
);

PaymentPagesBillboard.propTypes = {
  height: oneOfType([ number, string ]),
  width: oneOfType([ number, string ]),
  title: string
};


export default PaymentPagesBillboard;
