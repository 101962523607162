import React, { useRef, useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import Slider from 'react-slick';
import CarouselArrowRight from './icons/CarouselArrowRight';
import CarouselArrowLeft from './icons/CarouselArrowLeft';
import './style.scss';

export const responsiveFilter = (value, index, allValues) => {
  const nextValue = allValues[index + 1];
  const screenWidth = document.body.getBoundingClientRect().width;
  
  if (!nextValue) return value.breakpoint > screenWidth;

  return value.breakpoint > screenWidth && nextValue.breakpoint < screenWidth;
};

export const hasResponsiveValues = responsiveValues => responsiveValues.length > 0;

export const getDifference = (amountOfChildren, slidesToShow) =>  amountOfChildren - slidesToShow;

const determineWrapperClass = (builtClass, activeSlide, amountOfChildren, slideCount) => {
  if (getDifference(amountOfChildren, slideCount) === 0) {
    return `${builtClass} no-buttons`;
  }
  if (activeSlide === 0) {
    return `${builtClass} first-slide-active`;
  }
  
  if (activeSlide >= getDifference(amountOfChildren, slideCount)) {
    return `${builtClass} last-slide-active`;
  }
  return builtClass;
};

export const buildWrapperClass = (infiniteScrollEnabled, activeSlide, amountOfChildren, slidesToShow, responsiveValues) => {
  const builtClass = 'nlecarousel-slider';

  if (!infiniteScrollEnabled) {
    const responsiveValue = responsiveValues.find(responsiveFilter);

    if (hasResponsiveValues(responsiveValues)) {
      const responsiveSlidesToShow = responsiveValue?.settings?.slidesToShow;

      if (responsiveSlidesToShow) {
        return determineWrapperClass(builtClass, activeSlide, amountOfChildren, responsiveSlidesToShow);
      }
    }

    return determineWrapperClass(builtClass, activeSlide, amountOfChildren, slidesToShow);
  }
  
  if (getDifference(amountOfChildren, slidesToShow) === 0) {
    return `${builtClass} no-buttons`;
  }

  return builtClass;
};

const sliderButtonGradient = (direction, shading) => {
  return `linear-gradient(to ${direction}, ${shading}, ${shading} 50%, transparent)`;
};


const PrevButton = ({ sliderShading, handlePrevClick }) => (
  <div 
    className="nlecarousel-prev-wrapper"
    style={{background: sliderButtonGradient('right', sliderShading)}}
  >
    <APButton
      className="ap-icon-button nlecarousel-prev"
      type="button"
      dataset={{'data-testid': 'nlecarousel-prev'}}
      onClick={handlePrevClick}
    >
      <CarouselArrowLeft />
    </APButton>
  </div>
);

const NextButton = ({ sliderShading, handleNextClick }) => (
  <div
    className="nlecarousel-next-wrapper"
    style={{background: sliderButtonGradient('left', sliderShading)}}
  >
    <APButton
      className="ap-icon-button nlecarousel-next"
      type="button"
      dataset={{'data-testid': 'nlecarousel-next'}}
      onClick={handleNextClick}
    >
      <CarouselArrowRight />
    </APButton>
  </div>
);

const APCarousel = ({
  adaptiveHeight = false,
  autoplay = false,
  centerMode = false,
  fade = false,
  infinite = false,
  showDots = false,
  sliderClassName='nlecarousel-slides',
  slidesToScroll=1,
  slidesToShow=3,
  sliderShading = '#fff',
  speed = 300,
  variableWidth = false,
  responsiveValues = [],
  children
}) => {
  const sliderRef = useRef(null);
  const [wrapperClass, setWrapperClass] = useState(buildWrapperClass(infinite, 0, children.length, slidesToShow, responsiveValues));
    
  const afterChange = (current) => {
    const wrapperClass = buildWrapperClass(infinite, current, children.length, slidesToShow, responsiveValues);
    setWrapperClass(wrapperClass);
  };

  const handleNextClick = () => {
    if (sliderRef?.current) sliderRef.current.slickNext();
  };
  
  const handlePrevClick = () => {
    if (sliderRef?.current) sliderRef.current.slickPrev();
  };

  return (children.length > 0 && (
    <div 
      className="nlecarousel-wrapper"
      id="nlecarousel-wrapper"
      data-testid="nlecarousel-wrapper"
    >
      <div className={wrapperClass}>
        <Slider   
          ref={sliderRef} 
          adaptiveHeight={adaptiveHeight}
          autoplay={autoplay}
          arrows={true}
          centerMode={centerMode}
          className={sliderClassName}
          dots={showDots}
          fade={fade}
          infinite={infinite}
          responsive={responsiveValues}
          speed={speed}
          slidesToScroll={slidesToScroll}
          slidesToShow={slidesToShow}
          variableWidth={variableWidth}
          afterChange={afterChange}
          prevArrow={<PrevButton sliderShading={sliderShading} handlePrevClick={handlePrevClick} />} 
          nextArrow={<NextButton sliderShading={sliderShading} handleNextClick={handleNextClick} />} 
        >
          {children}
        </Slider>
      </div>
    </div>
  ));
};

PrevButton.propTypes = {
  sliderShading: string, 
  handlePrevClick: func.isRequired
};

NextButton.propTypes = {
  sliderShading: string, 
  handleNextClick: func.isRequired
};

APCarousel.propTypes = {
  adaptiveHeight: bool,
  autoplay: bool,
  centerMode: bool,
  dots: bool,
  fade: bool,
  infinite: bool,
  initialSlide: number,
  showDots: bool,
  sliderClassName: string,
  slidesToScroll: number,
  slidesToShow: number,
  sliderShading: string,
  speed: number,
  variableWidth: bool,
  responsiveValues: arrayOf(shape({
    breakpoint: number,
    settings: object
  })),
  children: arrayOf(node).isRequired
};

export default APCarousel;
