import React from 'react';
import { arrayOf, string } from 'prop-types';

const keyify = highlight => highlight.toLowerCase().replace(' ', '-');

const FeaturePoints = ({
  title,
  description,
  highlights
}) => (
  <div className="feature-point">
    <h3>{title}</h3>
    <p>{description}</p>
    {highlights.map(highlight => (
      <div key={keyify(highlight)} className="feature-highlight">{highlight}</div>
    ))}
  </div>
);

FeaturePoints.propTypes = {
  title: string,
  description: string,
  highlights: arrayOf(string)
};

export default FeaturePoints;
