import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import NewLayPayExperienceBanner from '.';

const testProps = {
  bannerType: 'payments', //payments || invoicing
  hasVtEntitlement: false,
  isAdminOrOwner: true,
  merchantId: '260QXRZLHiOK6l6GGcD1Om',
  supportPhone: '1-800-555-2310',
  mixpanelValues: {
    token: '23e90q09hasdv0923e'
  },
  userEmail: 'Foo',
  userName: 'Bar'
};

describe('NewLawPayExperienceBanner', () => {
  afterEach(() => {
    jest.restoreAllMocks();
  });

  it('renders', () => {
    render(<NewLayPayExperienceBanner {...testProps} />);
    expect(
      screen.getByTestId(
        'nle-banner-description'
      ).textContent).toBe(
      'The LawPay you love with added invoicing, time entries, expense tracking functionality. Same Payments, More Features to revolutionize your practice.'
    );
    expect(screen.getByTestId('nle-learn-more-button')).toBeInTheDocument();
    expect(screen.getByTestId('nle-banner-header-enable-button')).toBeInTheDocument();
  });

  it('renders with accordion open when all props and banner values are passed',  () => {
    render(<NewLayPayExperienceBanner {...testProps} />);
    expect(screen.getByTestId('apcard-title-container-payment-page').textContent).toBe('PaymentPages');
    expect(screen.getByTestId('apcard-title-container-accepting-payments').textContent).toBe('PaymentsOverview');
    expect(screen.getByTestId('apcard-title-container-saved-payment-methods').textContent).toBe('Saved PaymentMethods');
    expect(screen.getByTestId('apcard-title-container-admin-settings').textContent).toBe('AdminSettings');
    expect(screen.getByTestId('apcard-title-container-lawpay-new').textContent).toBe('What\'s NewIn LawPay');
  });

  it('renders cards based on bannerType',  () => {
    const {container, rerender} = render(<NewLayPayExperienceBanner {...testProps} />);
    expect(container.querySelectorAll('.slick-slide').length).toBe(5);
    rerender(<NewLayPayExperienceBanner {...testProps} bannerType="invoicing" />);
    expect(container.querySelectorAll('.slick-slide').length).toBe(7);
  });

  it('Enable Now modal opens when Enable Now header button is clicked', async () => {
    render(<NewLayPayExperienceBanner {...testProps} />);

    userEvent.click(screen.getByTestId('nle-banner-header-enable-button'));

    await waitFor(() => expect(screen.getByTestId('confirm-enable-now-button')).toBeInTheDocument());
    const cancelButton = screen.getByTestId('admin-cancel-enable-now-button');
    expect(cancelButton).toBeInTheDocument();
    userEvent.click(cancelButton);
    await waitFor(() => expect(screen.queryByTestId('confirm-enable-now-button')).not.toBeInTheDocument());
    await waitFor(() => expect(screen.queryByTestId('admin-cancel-enable-now-button')).not.toBeInTheDocument());
  });

  it('opens a new browser tab when "View" button on "What\'s New In LawPay" card is clicked', () => {
    const Open = jest.fn();
    window.open = Open;

    render(<NewLayPayExperienceBanner {...testProps} />);
    userEvent.click(screen.getByTestId('lawpay-new-card-button'));

    expect(Open).toHaveBeenCalledTimes(1);
    expect(Open).toHaveBeenCalledWith('https://affinipay-1.wistia.com/medias/v4osko6a7z', '_blank');
  });

  it('previous arrow does not show up when active card is first in collection', async () => {
    const {container} = render(<NewLayPayExperienceBanner {...testProps} bannerType="invoicing" />);
    expect(container.querySelector('.nlecarousel-slider')).toHaveClass('first-slide-active');
    userEvent.click(screen.getByTestId('nlecarousel-next'));
    userEvent.click(screen.getByTestId('nlecarousel-next'));
    await waitFor(() => expect(container.querySelector('.nlecarousel-slider')).not.toHaveClass('first-slide-active'));
  });
});
