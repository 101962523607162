import React from 'react';
import {  
  AcceptingPaymentsFeatureView,
  AdminSettingsFeatureView,
  InvoicingFeatureView,
  PaymentPageFeatureView,
  QuickBillFeatureView,
  SavedPaymentsFeatureView,
  TimersFeatureView,
  TimeExpenseFeatureView
} from './featureViews';

const quickBillInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Easily access Quick Bills by clicking the <span className="nle-instruction-bold">Quick Bills</span> button from the quick action menu on your dashboard.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Also you can select the <span className="nle-instruction-bold">Billing</span> Tab on the left navigation, then click the Quick Bills link on top of the page. Here you will can view all your past Quick Bill requests. 
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Click the contact name to view and manage the <span className="nle-instruction-bold">Contact</span> associated with Quick Bill.
      </span> 
    </>
  )
];

const paymentPagesInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        To quickly access your payment pages, click the quick action button on the homepage that reads <span className="nle-instruction-bold">Payment Pages</span>.    
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        From here, you can <span className="nle-instruction-bold">accept payment, send the payment page via email</span>, or <span className="nle-instruction-bold">edit its settings</span>.    
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        You can also copy the page <span className="nle-instruction-bold">QR code</span> or <span className="nle-instruction-bold">URL</span>. Simply click the X to return to the dashboard.
      </span> 
    </>
  )
];

const paymentsOverviewInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Select <span className="nle-instruction-bold">Payments</span> from the left navigation to view all your completed Transactions and to access Scheduled Payments.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Click on a transaction to jump to the <span className="nle-instruction-bold">Transaction Details</span> page. You can review all the details of the transaction.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        On the <span className="nle-instruction-bold">Transaction Details</span> page, you can initiate a <span className="nle-instruction-bold">Refund, Reconcile, Recharge</span>, or <span className="nle-instruction-bold">Resend Receipt.</span>
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Select the <span className="nle-instruction-bold">Scheduled Payments</span> Tab on the top of the page to access Scheduled Payments. This is where you’ll create and review scheduled payments for your clients.
      </span> 
    </>
  )
];

const savedPaymentsInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Select <span className="nle-instruction-bold">Contacts</span> from the left navigation to view all of your clients. Click on a contact to view their <span className="nle-instruction-bold">Contact Details</span> page.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        <span className="nle-instruction-bold">Contact Details</span>: The Contact Details Page provides valuable and important contact record information like cases, payment and billing activity.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        <span className="nle-instruction-bold">Saved Payment Methods</span>: Find and manage your client's stored payment methods here.
      </span> 
    </>
  )
];

const adminSettingsInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        To quickly access your settings, click <span className="nle-instruction-bold">Settings</span> at the bottom of the navigation menu.    
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        View and manage all of your user and firm settings including <span className="nle-instruction-bold">Client Billing, Payment Pages, Notifications</span>, and <span className="nle-instruction-bold">My Profile</span>.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        To access your Statements, click your avatar icon in the toolbar header and choose <span className="nle-instruction-bold">Statements</span> from the dropdown.
      </span> 
    </>
  )
];

const invoiceInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Jump right into Invoicing by clicking the <span className="nle-instruction-bold">Create Invoice</span> quick action button on your dashboard.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        You can also select <span className="nle-instruction-bold">Billing</span> from the left Navigation. Then click the Invoices Tab at the top of the page. Here you can view all of your existing invoices.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Once you select <span className="nle-instruction-bold">Create Invoice</span>, fill out the Contact. Any Time Entries and Expenses associated with the case are automatically added.
      </span> 
    </>
  )
];

const timerInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        <span className="nle-instruction-bold">Start Timer</span>: Find the Timer Icon in the header toolbar. Click on Start Timer to begin time tracking.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        <span className="nle-instruction-bold">Timer Details</span>: You can easily select the Case and add an optional Description for the time entry.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Next to the Timer Icon, you can view the running time. Pause and resume the timer whenever needed.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        When you are done tracking time for a certain task, pause the timer and click the Save Icon to add your time entry to the case.    
      </span> 
    </>
  )
];

const timeExpenseInstructions = [
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        To quickly access creating a new time or expense entry. Click on the <span className="nle-instruction-bold">Quick Actions (+)</span> button in the top right hand corner.
      </span> 
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        To access <span className="nle-instruction-bold">Time Entries:</span> Click <span className="nle-instruction-bold">Billing</span> in the left navigation. This is where you can view and manage all of your time entries.
      </span>
    </>
  ),
  ({ bulletNumber }) => (
    <>
      <div className="nle-instruction-bullet">{bulletNumber}</div> 
      <span className="nle-instruction">
        Click the <span className="nle-instruction-bold">Expenses Tab</span> at the top of the page to view, manage, and add expense entries.
      </span> 
    </>
  )
];

export const newFeaturesModalBodies = {
  paymentPages: {
    name: 'paymentPages',
    descriptionTitle: 'Payment Pages',
    description: 'Everything you love about payment pages is still available and easily accessible.',
    instructions: paymentPagesInstructions,
    FeatureView: PaymentPageFeatureView
  },
  acceptingPayments: {
    name: 'acceptingPayments',
    descriptionTitle: 'Payments Overview',
    description: 'The Payments Tab is where you will find the Transactions Table & Scheduled Payments. Also access the Share Payment Page and Collect Payment buttons.',
    instructions: paymentsOverviewInstructions,
    FeatureView: AcceptingPaymentsFeatureView
  },
  savedPayments: {
    name: 'savedPayments',
    descriptionTitle: 'Saved Payment Methods',
    description: 'Now you\'ll be able to access your clients\' stored payment methods on their Contact Details Page.',
    instructions: savedPaymentsInstructions,
    FeatureView: SavedPaymentsFeatureView
  },
  quickBills: {
    name: 'quickBills',
    descriptionTitle: 'Quick Bills',
    description: 'Everything you love about Quick Bills is still available and has just moved to a new location.',
    instructions: quickBillInstructions,
    FeatureView: QuickBillFeatureView
  },
  invoicing: {
    name: 'invoicing',
    descriptionTitle: 'Easy Invoicing ',
    description: 'LawPay has all of the billing, invoicing, time and expense tracking, and payments features you need.',
    instructions: invoiceInstructions,
    FeatureView: InvoicingFeatureView
  },
  adminSettings: {
    name: 'adminSettings',
    descriptionTitle: 'Admin Settings',
    description: 'You can now easily access your settings from the left navigation.',
    instructions: adminSettingsInstructions,
    FeatureView: AdminSettingsFeatureView
  },
  viewTimer: {
    name: 'viewTimer',
    descriptionTitle: 'Timers',
    description: 'Use up to ten built-in timers to track time worked on behalf of your clients. Time entries can be easily pulled into invoices.',
    instructions: timerInstructions,
    FeatureView: TimersFeatureView
  },
  timeExpense: {
    name: 'timeExpense',
    descriptionTitle: 'Time & Expense Entries',
    description: 'We added time and expense tracking tools, so generating invoices is easier than ever.',
    instructions: timeExpenseInstructions,
    FeatureView: TimeExpenseFeatureView
  }
};
