import React from 'react';
import { number, oneOfType, string } from 'prop-types';

const AnimatedCircleCheck = ({
  className='ap-accordion-check',
  width=26,
  height=26,
  title='Accordion Selected'
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132 132"
    className={className}
  >
    <title>{title}</title>
    <circle className="path circle" fill="#0069CC" stroke="#0069CC" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
    <polyline className="path check" fill="none" stroke="#fff" strokeWidth="15" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
  </svg>
);

AnimatedCircleCheck.propTypes = {
  className: string,
  width: oneOfType([ number, string ]),
  height: oneOfType([ number, string ]),
  title: string
};

export default AnimatedCircleCheck;